import React from 'react'

export const ExtraContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>

      {(string === 'goGreenTitle' && lang === 'ita') && (
        <h3>VERSO UNA PURPOSE DRIVEN COMMUNITY</h3>
      )}

      {(string === 'goGreenSust' && lang === 'ita') && (
        <p>In OpenZone, la sostenibilità è un percorso
        intrapreso per affrontare le sfide della
        contemporaneità: dal benessere delle persone
        alla tutela ambientale, dalla cura degli spazi
        alla mobilità, con una particolare attenzione
        alla riduzione degli sprechi. Azioni concrete
        promosse anche attraverso il piano
        #GoGreen.</p>
      )}

      {(string === 'goGreenCol1' && lang === 'ita') && (
        <>
          <p>Il piano di sviluppo e i tanti progetti avviati
          hanno portato a riflettere in modo più
          strutturato sull’impatto che OpenZone può
          e vuole generare.</p>
        </>
      )}

      {(string === 'goGreenCol2' && lang === 'ita') && (
        <>
          <p>In primis, creare valore
          nel settore della salute, come dice la stessa
          mission, ma non solo, è fondamentale
          dialogare in modo continuativo affinché
          intuizioni, idee e soluzioni si trasformino in
          opportunità concrete per la community per
          disegnare nuovi modi, aperti e condivisi, per
          vivere il campus.</p>
          <p>Tra queste, è in essere il progetto mobilità
          che ha il fine di mappare le abitudini della
          community nel percorrere il tragitto casa-
          OpenZone. Le informazioni raccolte ci
          permetteranno di progettare un sistema di
          mobilità sostenibile, che nel tempo possa
          rispondere al meglio alle esigenze di tutti gli
          Zoner.</p>
          <p><strong>Il campus è pronto per una nuova sfida e per
          un impegno ancora più consapevole, che vi
          racconteremo nei prossimi mesi!</strong></p>
        </>
      )}

      {(string === 'goGreenTitle' && lang === 'eng') && (
        <h3>TOWARDS A PURPOSE DRIVEN COMMUNITY</h3>
      )}

      {(string === 'goGreenSust' && lang === 'eng') && (
        <>
          <p>At OpenZone, sustainability is a path taken to deal with modern-day challenges,
          from the well-being of people to environmental protection, from the design of spaces
          to mobility, with particular attention to waste reduction: concrete actions, fostered
          through the #GoGreen plan and elsewhere</p>
        </>
      )}

      {(string === 'goGreenCol1' && lang === 'eng') && (
        <>
          <p>The development plan and the many projects launched have led us to reflect
          in a more systematic way on the impact that OpenZone can and wants to generate.</p>
        </>
      )}

      {(string === 'goGreenCol2' && lang === 'eng') && (
        <>
          <p>First of all create value in the health sector,
          as is set forth in its mission statement, but it
          doesn't end there. Ongoing dialogue is essential if
          insights, ideas and solutions are to be transformed
          into tangible opportunities for the community to design
          new, open and shared ways to experience the campus.</p>
          <p>One of these is the currently ongoing mobility project,
          whose purpose is to map the community’s habits as they commute
          between home and OpenZone. The information we gather will allow
          us to design a sustainable mobility system that, over time, will
          be able to best meet the needs of all Zoners.</p>
          <p><strong>The campus is ready for a new challenge and an
          even more responsible commitment, which we’ll tell you more
          about in coming months!</strong></p>
        </>
      )}

      {(string === 'zcubeTitle') && (
        <h2>Zcube</h2>
      )}

      {(string === 'zcubeCol1' && lang === 'ita') && (
         <p>Nato nel 2003, Zcube – Zambon Research Venture esplora il mondo dell’innovazione e della ricerca nel campo delle scienze della vita a livello globale, supportando la crescita e l’accelerazione di start-up innovative operanti nelle aree di interesse del gruppo Zambon.</p>
      )}

      {(string === 'zcubeCol1' && lang === 'eng') && (
         <p>Founded in 2003, Zcube – the Zambon Research Venture – explores the world of research and innovation in Life Sciences globally, supporting the growth and acceleration of innovative start-ups operating in the Zambon Group’s areas of interest.</p>
      )}

      {(string === 'corpTitle') && (
        <h2>Corporate Venture</h2>
      )}

      {(string === 'corpCol1' && lang === 'ita') && (
         <>
         <p>I fondi, residenti negli Stati Uniti, in Spagna, in Lussemburgo e in Italia, investono prevalentemente in imprese operanti nell’ambito della ricerca e sviluppo di tecnologie altamente innovative nel settore medicale e farmaceutico.</p>
         <p>Nel 2023 è stata operata l’adesione ad un nuovo fondo: Italian Founders Fund.</p>
         </>
      )}

      {(string === 'corpCol1' && lang === 'eng') && (
         <>
         <p>These funds, based in the United States, Spain, Luxembourg and Italy, invest primarily in companies engaged in research and development of cutting-edge technologies in the medical and pharmaceutical industry.</p>
         <p>In 2023, Zcube invested in a new fund: Italian Founders Fund.</p>
         </>
      )}

      {(string === 'openTitle') && (
        <h2>OPEN ACCELERATOR</h2>
      )}

      {(string === 'openCol1' && lang === 'ita') && (
         <>
         <p>Hyivy Health è una startup femtech che sta mettendo a punto un dispositivo smart per la riabilitazione del pavimento pelvico ottenendo anche un primo riscontro positivo alla pre-submission fatta all’FDA. L’investimento di Zcube è stato portato a termine grazie alla sinergia con HTH-Health Technology Holding.</p>
         </>
      )}

      {(string === 'openCol1' && lang === 'eng') && (
         <>
         <p>Hyivy Health is a FemTech startup that is developing a smart device for pelvic floor rehabilitation, which has also obtained an initial positive response to its pre-submission made to the FDA. Zcube's investment was made thanks to its synergy with HTH-Health Technology Holding.</p>
         </>
      )}

      {(string === 'callTitle') && (
        <h2>Open Accelerator 2024: Call for women’s health innovations</h2>
      )}

      {(string === 'callCol1' && lang === 'ita') && (
         <>
         <p>L’iniziativa chiama a raccolta startup e idee imprenditoriali che mirano a sviluppare servizi e prodotti che rispondano alle principali esigenze mediche e migliorino il benessere e la salute della donna in diverse aree terapeutiche. </p>
         <p>La Call for application è stata lanciata venerdì 8 marzo 2024. Al termine della fase di selezione saranno individuate fino a 5 startup che potranno ricevere un investimento di 100.000 euro ciascuna da parte di Zcube e accederanno a un programma di formazione e mentoring erogato da professionisti del settore.</p>
         </>
      )}

      {(string === 'callCol1' && lang === 'eng') && (
         <>
         <p>The initiative has put out a call for start-up and business ideas that aim to develop services and products that address key medical needs and improve women's well-being and health in a range of therapeutic areas.</p>
         <p>The Call for Applications was launched on 8 March 2024. At the end of the selection phase, up to five start-ups will be identified to receive an investment of €100,000 each from Zcube and access to a training and mentoring programme run by industry professionals. </p>
         </>
      )}

      {(string === 'vitaTitle') && (
        <h2>Vita</h2>
      )}

      {(string === 'vitaCol1' && lang === 'ita') && (
         <p>Anche nel 2023 Zcube ha aderito in qualità di unico Innovation Partner a VITA Accelerator programma di accelerazione startup lanciato da CDP Venture Capital insieme a Healthware Group e Accelerace che mira a supportare una nuova generazione di startup della salute digitale. Il programma ha l'obiettivo di accelerare le start-up italiane e internazionali che vogliano fare business in Italia per rafforzare l'ecosistema italiano della salute digitale, nonché promuoverlo a livello internazionale.</p>
      )}

      {(string === 'vitaCol1' && lang === 'eng') && (
         <p>Again in 2023, Zcube joined the VITA Accelerator programme as an Innovation Partner. VITA is a start-up acceleration programme launched by CDP Venture Capital together with Healthware Group and Accelerace aimed at supporting a new generation of digital health start-ups. The programme objective is to accelerate Italian and international start-ups that want to do business in Italy to strengthen the Italian digital health ecosystem, and to promote it on an international level.</p>
      )}

      {(string === 'itaTitle' && lang === 'ita') && (
        <h2>Il 2023 di Italiassistenza</h2>
      )}

      {(string === 'itaCol1' && lang === 'ita') && (
         <p>Nel 2023 ItaliAssistenza ha mantenuto il focus sulle attività di assistenza domiciliare e di supporto all’aderenza terapeutica, focalizzandosi, da un lato, sul consolidamento sul territorio nazionale del network PrivatAssistenza e, dall’altro, sullo sviluppo di progettualità innovative che favoriscano l’integrazione dei servizi del network territoriale con quelli della divisione Human Assist Care, in un’ottica di implementazione di un’offerta sempre più completa di servizi domiciliari che possano supportare a 360° i bisogni di assistenza del Paziente, fornendo i più alti standard di qualità.</p>
      )}

      {(string === 'itaTitle' && lang === 'eng') && (
        <h2>ItaliAssistenza in 2023</h2>
      )}

      {(string === 'itaCol1' && lang === 'eng') && (
         <p>In 2023, ItaliAssistenza maintained its focus on home care activities and support for therapeutic adherence, focusing on one hand on the consolidation of the PrivatAssistenza network throughout the country and, on the other, on the development of innovative projects favouring the integration of local network services with those of the Human Assist Care division, with a view to implementing an increasingly complete range of home care services that can provide 360° support to meet patient care needs while reaching the highest quality standards.</p>
      )}


      {(string === 'quotezacCol1' && lang === 'ita') && (
        <>
          <p className="quote">Il 2021 è stato per Zach un anno di positiva conferma, una tappa fondamentale del nostro
          cammino di rinnovamento e di crescente domanda per i nostri servizi. Zach ambisce a diventare
          un punto di riferimento nella produziondi di API e nello sviluppo di processi chimici innovativi,
          lavorando in sinergia con Zambon per offire ai clienti un’offerta completa, rispondendo a pieno
          allo spirito dello One Stop Shop. Dal mio punto di vista, la grande trasformazione che stiamo
          vivendo e i tanti investimenti che stiamo portando avanti ci permetteranno di raggiungere
          importanti traguardi anche nel 2022.</p>
          <p className="quoteAuthor mt-5">Lucio Lavacchielli</p>
          <p className="quoteRole">CEO di Zach – Zambon Chemicals</p>
        </>
      )}
      {(string === 'quotezacCol1' && lang === 'eng') && (
        <>
          <p className="quote">2021 was a year of positive affirmation for Zach, an essential step in our journey of renewal,
          with growing demand for our services. Zach aspires to become a point of reference for the
          production of APIs and the development of innovative chemical processes, working in synergy with Zambon
          to offer customers a full complement of services, embodying the spirit of the One Stop Shop. From my point of view,
          the great transformation we are experiencing and our numerous ongoing investments will allow us to
          achieve important goals in 2022 as well</p>
          <p className="quoteAuthor mt-5">Lucio Lavacchielli</p>
          <p className="quoteRole">CEO of Zach – Zambon Chemicals</p>
        </>
      )}
      {(string === 'thewayweareTitle') && (
        <>
          <h2>The way we are</h2>
        </>
      )}
      {(string === 'thewayweareCol1' && lang === 'ita') && (
        <>
          <p>Il nostro modo di essere, ancorato a <strong>saldi valori
          etici</strong> dal 1906, sottolinea l’unicità di Zambon.</p>
          <p>Perseguiamo con convinzione l’impegno di migliorare la vita dei pazienti,
          integrando <strong>terapia e cura</strong> con un approccio <strong>multidisciplinare</strong>,
          sperimentando <strong>soluzioni nuove</strong> per malattie gravi e per
          affrontare le sfide della salute.</p>
          <p>Da 118 anni crediamo sia importante condividere
          e coltivare <strong>valori comuni</strong>, prenderci cura delle persone valorizzando
          la <strong>dimensione umana</strong>,
          quello <strong>Human Touch</strong> che è  il nostro tratto distintivo ed esprime bene il nostro stile di fare le cose. </p>
          <p>Perché quello che ci muove è il significato che le nostre azioni avranno in <strong>futuro</strong> e
          i loro effetti nel lungo termine.</p>
        </>
      )}
      {(string === 'thewayweareCol1' && lang === 'eng') && (
        <>
          <p>The way we are, anchored to <strong>strong ethical values</strong> since 1906,
          underlines the uniqueness of Zambon.</p>
          <p>We confidently renew our commitment to improving patients’ lives,
          combining <strong>‘cure and care’</strong> with a <strong>multidisciplinary</strong> approach,
          experimenting with <strong>new solutions</strong> for severe diseases
          and to tackle the health challenges ahead of us.</p>

          <p>For 117 years we believe it is important to share and cultivate <strong>common values</strong>,
          to take care of people by enhancing the <strong>human dimension</strong>,
          the <strong>Human Touch</strong> that is our distinctive mark and that embodies the way we are. </p>

          <p>Because what truly motivates us is the meaning our actions will have in the <strong>future</strong> and their effects in the long run.</p>
        </>
      )}
      {(string === 'elenaTitle' && lang === 'ita') && (
        <>
          <h2>Messaggio del presidente</h2>
        </>
      )}
      {(string === 'elenaCol1' && lang === 'ita') && (
        <>
            <p className="big">Cari tutti,</p>
            <p>Il 2023 ha confermato ancora una volta come l’imprevedibilità sia una costante del nostro tempo e come la capacità di reagire sia una delle doti essenziali capace di fare la differenza, nel breve termine come nel lungo, lasciandosi ispirare e guidare – come facciamo in Zambon da 118 anni – dai valori e dai principi più profondi.</p>
            <p>Questo nuovo Value Report racconta con passione il lavoro, le energie e le connessioni indispensabili per raggiungere un futuro chiaro e definito. Quel <em>Sustainable Future</em> che dà il nome a questo report, al nostro piano strategico e, non ultimo, al nostro impegno quotidiano. Quello per realizzare <strong>un futuro responsabile, sostenibile, ma soprattutto migliore</strong>, da cui le prossime generazioni possano coglierne il meglio per continuare a far sì che <strong>la salute di tutte e tutti resti sempre un traguardo di civiltà</strong>.</p>
            <p>Nonostante le continue sfide davanti a noi, Zambon si conferma un’impresa solida che continua a crescere in tutti i suoi business. La nostra visione imprenditoriale fatta di cura e prendersi cura dimostra di essere una combinazione in grado di porci sul mercato come un player della salute a 360 gradi.</p>
            <p>Il merito di tutto ciò va alle nostre 2.900 persone in tutto il mondo che ogni giorno, con la loro fame di futuro e senso di responsabilità, trasformano in realtà la nostra mission <strong>“Innovating Cure & Care to make patients’ lives better”</strong>, permettendoci di onorare l’impegno che abbiamo nei confronti dei pazienti.</p>
            <p>Il cammino del nostro business farmaceutico continua a essere costellato da importanti traguardi che confermano il buon stato di salute dei nostri prodotti di punta che hanno fatto registrare crescite percentuali a doppia cifra a livello globale.</p>
            <p>Inoltre, continuano i nostri sforzi in Ricerca e Sviluppo. In particolare, proseguono gli studi BOSTON per curare la Sindrome da Bronchiolite Obliterante, una patologia infiammatoria che colpisce chi si sottopone a trapianto di polmone causandone il rigetto cronico, con cui assicurare una cura che dia una speranza di vita.</p>
            <p>ZBiotech si conferma centro di innovazione dall’alto valore grazie alle sue attività di scouting del mercato, fondamentali per garantire la nostra pipeline a medio e lungo termine, che nel 2023 ha lanciato un’innovativa soluzione a favore dei pazienti affetti da SLA.</p>
        </>
      )}
      {(string === 'elenaCol2' && lang === 'ita') && (
        <>
            <p>Zach, Zambon Chemical per lo sviluppo di principi attivi per Zambon e per clienti terzi, cresce e investe nel proprio futuro con fiducia e ambizione per cogliere le sfide che il mercato presenterà, continuando a essere un partner affidabile ed efficiente.</p>
            <p>OpenZone, il Campus Scientifico alle porte di Milano, nel 2023 ha aperto le porte delle nuove torri, ottenendo anche la certificazione B Corp. Si conferma così come punto di riferimento e un esempio nel mondo della salute e delle scienze della vita in Lombardia, in Italia e in Europa di come sviluppare opportunità per la ricerca creando un ecosistema favorevole.</p>
            <p>Zcube-Zambon Research Venture prosegue nella selezione di progetti nel campo delle Life Science nei quali investire e nella collaborazione con importanti fondi di Venture Capital nazionali e internazionali, arrivando a lanciare a inizio 2024 la nuova call internazionale di Open Accelerator, quest’anno dedicata alle migliori soluzioni in grado di migliorare la salute delle donne.</p>
            <p>Grazie alla professionalità e alla dimensione umana, che contraddistingue il nostro modo di fare impresa, continuiamo a prenderci cura dei pazienti anche grazie al lavoro di ItaliAssistenza, che nel 2023 ha celebrato i 30 anni di PrivatAssistenza, la prima rete nazionale di assistenza domiciliare. Inoltre, Careapt nell’ultimo anno ha ampliato la sua gamma di piattaforme di telemedicina e teleassistenza con l’avvio del programma AttivaCare, dedicata ai caregiver degli anziani non autosufficienti.</p>
            <p>A coronamento del nostro modo di fare impresa, infine, Fondazione Zoé - Zambon Open Education, ha continuato le sue attività di divulgazione sui temi della salute. Le sue linee guida nel libro “Home Care Design For Parkinson’s Disease” sono state inserite nell’ADI Design Index 2023, una prestigiosa selezione che comprende progetti che si sono distinti per innovazione e qualità del design.</p>
            <p>Grazie allo spirito di apertura e al significato di essere impresa potremo tutti insieme realizzare quel <em>Sustainable Future</em> a cui ci sentiamo chiamati come missione.</p>
            <p>Auguro a tutti voi una buona lettura, con la consapevolezza che il futuro è sempre tutto da scrivere. Spero possiate cogliere in questo Value Report il nostro senso di fare impresa attraverso i tanti progetti pieni di valori, resi possibili da persone di valore. Molto dipende da noi, ed è questa la sfida.</p>

            <p className="name">Elena Zambon</p>
            <p className="role">Presidente Zambon SpA</p>
        </>
      )}
      {(string === 'elenaTitle' && lang === 'eng') && (
        <>
          <h2>Dear all,</h2>
        </>
      )}
      {(string === 'elenaCol1' && lang === 'eng') && (
        <>
         <p className="big">Dear all,</p>
         <p>The year 2023 confirmed once again how unpredictability is a constant of our time, and how reaction capacity is one of the essential skills capable of making a difference, in the short term as well as in the long term, by letting ourselves be inspired and guided - as we have been doing at Zambon for 118 years - by the deepest values and principles.</p>
         <p>This new Value Report passionately recounts the work, energy and connections needed to achieve a clear and defined future. That Sustainable Future that gives its name to this report, to our strategic plan and, not least, to our daily commitment. The one in which we achieve a responsible, sustainable, but above all better future, which the next generations can make the best of in order to continue to ensure that the health of all will always remain a goal of civilisation.</p>
         <p>Despite the constant challenges ahead, Zambon remains a solid company that continues to grow in all its businesses. The company’s entrepreneurial vision of cure and care proves to be a combination capable of positioning it in the market as an all-round health player.</p>
         <p>The credit for this goes to our 2,900 people around the world who, every day, with their hunger for the future and sense of responsibility, turn our mission “Innovating Cure & Care to make patients’ lives better” into reality, allowing us to honour our commitment to patients.</p>
         <p>The path of our pharmaceutical business continues marked by important milestones that confirm the good health of our flagship products, which have recorded double-digit percentage growth globally.</p>
         <p>In addition, our efforts in Research and Development continue. In particular, BOSTON studies are continuing for the treatment of Bronchiolitis Obliterans Syndrome, an inflammatory condition that affects lung transplant recipients, causing chronic rejection, with which to ensure a cure that provides life expectancy.</p>
         <p>ZBiotech is confirmed as a high-value innovation centre thanks to its market scouting activities, which are key to securing our medium- and long-term pipeline, and launched an innovative solution for ALS patients in 2023.</p>
        </>
      )}
      {(string === 'elenaCol2' && lang === 'eng') && (
        <>
           <p>Zach, Zambon Chemical for the development of active ingredients for Zambon and third-party customers, is growing and investing in its future with confidence and ambition to meet the challenges that the market will present, while continuing to be a reliable and efficient partner.</p>
           <p>OpenZone, the Science Campus on the outskirts of Milan, opened the doors of its new towers in 2023, also achieving the B Corp certification. It is thus confirmed as a benchmark and an example in the world of health and life sciences in Lombardy, Italy and Europe of how to develop research opportunities by creating a favourable ecosystem.</p>
           <p>Zcube-Zambon Research Venture is continuing to select life science projects in which to invest and to collaborate with major national and international venture capital funds, going so far as to launch the new international Open Accelerator call at the beginning of 2024, this year dedicated to the best solutions for improving women's health.</p>
           <p>Thanks to the professionalism and the human touch that characterize our business approach, we continue to take care of patients also thanks to the work of ItaliAssistenza, which in 2023 celebrated 30 years of PrivatAssistenza, the first national home care network. In addition, in the last year Careapt has expanded its range of telemedicine and remote care platforms with the launch of the AttivaCare programme, dedicated to caregivers of the dependent elderly.</p>
           <p>Lastly, as the crowning achievement of our way of doing business, Fondazione Zoé - Zambon Open Education, continued its outreach on health topics. Its “Home Care Design for Parkinson's Disease” guidelines have been included in the ADI Design Index 2023, a prestigious selection that includes editorial projects that stand out due to their innovation and design quality.</p>
           <p>With a spirit of openness and the meaning of being a company, we can all together achieve that Sustainable Future to which we feel called as a mission.</p>
           <p>I wish you all happy reading, with the knowledge that the future is always yet to be written. I hope you can get an idea in this Value Report of our way of doing business, through many value-driven projects made possible by valuable people. Much depends on us, and this is the challenge.</p>
           <p className="name">Elena Zambon</p>
           <p className="role">President, Zambon SpA</p>
        </>
      )}

      {(string === 'lucioTitle') && (
        <>
          <h2> </h2>
        </>
      )}
      {(string === 'lucioCol1' && lang === 'ita') && (
        <>
          <p>Nel 2023, Zach ha portato avanti un entusiasmante percorso concentrato sul "contract-manufacturing" e sul "captive". Nel mondo del contract-manufacturing, abbiamo compiuto grandi progressi, completando attività di pre-validazione, analisi e di sviluppo processo per un trattamento innovativo dell'angioedema ereditario. Inoltre, abbiamo raggiunto un importante traguardo con la convalida industriale di un sistema di rilascio molecolare di proteine terapeutiche, aprendo così nuove strade nel campo della terapia molecolare. Riguardo il captive, abbiamo effettuato il trasferimento tecnologico e attività di sviluppo processo di un principio 	attivo commerciale, completando la fabbricazione di un lotto dimostrativo che segna l'inizio di 	un'entusiasmante nuova fase. Il nostro sguardo è rivolto al domani con fiducia e ambizione, pronti a cogliere ogni opportunità che ci si presenterà.</p>
          <p className="name">Lucio Lavacchielli</p>
          <p className="role">CEO di Zach – Zambon Chemicals</p>
        </>
      )}

      {(string === 'lucioCol1' && lang === 'eng') && (
        <>
          <p>In 2023, Zach moved forward along an exciting path focusing on “contract-manufacturing” and “captive”. In contract-manufacturing, we made great progress, completing pre-validation, analysis and process development activities for an innovative treatment of hereditary angioedema. In addition, we reached an important milestone with the industrial validation of a molecular delivery system for therapeutic proteins, thus opening up new avenues in the field of molecular therapy. As far as captive activities are concerned, we carried out 	technology transfer and process development activities on a commercial active ingredient, completing the manufacture of a demonstration batch, marking the start of an exciting new 	phase. We look to the future with confidence and ambition, ready to take advantage of every opportunity that comes our way.</p>
          <p className="name">Lucio Lavacchielli</p>
          <p className="role">CEO of Zach – Zambon Chemicals</p>
        </>
      )}

      {(string === 'torriTitle' && lang === 'ita') && (
        <>
          <h2>Le nuove torri</h2>
        </>
      )}
      {(string === 'torriCol1' && lang === 'ita') && (
        <>
          <p>Con otto piani di 45 metri per la prima e cinque piani di 33 metri per la seconda, oltre a quattro piani sottostanti per parcheggi e locali tecnici, le torri offrono spazio per laboratori di ricerca all'avanguardia, uffici e aree per iniziative imprenditoriali nel campo della salute. Progettate con un occhio attento alla sostenibilità e alla tecnologia, le torri adottano la metodologia BIM per ottimizzare la pianificazione, la costruzione e la gestione, garantendo un ciclo di vita dell’immobile efficiente e sostenibile.</p>
        </>
      )}

      {(string === 'torriTitle' && lang === 'eng') && (
        <>
          <h2>The New Towers</h2>
        </>
      )}
      {(string === 'torriCol1' && lang === 'eng') && (
        <>
          <p>With eight 45-metre floors for the first and five 33-metre floors for the second, as well as four floors below ground for parking and technical rooms, the towers provide space for state-of-the-art research laboratories, offices and areas for business initiatives in the world of health. Designed with an eye to sustainability and technology, the towers adopt BIM methodology for planning, construction and management optimisation, ensuring an efficient and sustainable building lifecycle.</p>
        </>
      )}

      {(string === 'hacTitle') && (
        <h2>HUMAN ASSIST CARE</h2>
      )}
      {(string === 'hacCol1' && lang === 'ita') && (
        <>
        <p>La società opera tramite le sue sedi di Bresso e Reggio Emilia, che coordinano, attraverso una Centrale Operativa attiva H24 7/7, una rete capillare di professionisti sanitari operante su tutto il territorio nazionale.</p>
        <p>L'azienda offre un sistema di servizi integrati domiciliari e di supporto e monitoraggio da remoto, servendosi di una piattaforma tecnologica personalizzata, portali web, app native e device, per rispondere alle esigenze del Paziente, garantendo una gestione centralizzata ed un unico modello di accesso ed organizzazione della presa in carico.</p>
        <p>I servizi di Human Assist Care si contraddistinguono per lo <strong>Human Touch</strong>, l’approccio e le attenzioni degli operatori verso il Paziente durante tutti i contatti, sia da remoto che di persona, personalizzati sulla base di patologia, burden, bisogni specifici e contesto familiare del singolo Paziente e del suo Caregiver, con l’obiettivo di massimizzare l’efficacia del sostegno all’aderenza ed al piano di cura.</p>
        </>
      )}

      {(string === 'paTitle') && (
        <h2>PRIVATE ASSISTENZA</h2>
      )}
      {(string === 'paCol1' && lang === 'ita') && (
        <>
         <h3>La Convention Nazionale di PrivatAssistenza</h3>

         <p>Nel dicembre del 2023, all’interno del campus OpenZone, si è svolta la Convention Nazionale di PrivatAssistenza, la Prima Rete Nazionale di Assistenza Domiciliare. L'evento ha celebrato trent’anni dalla fondazione del network, che ha come obiettivo il mettere in primo piano le persone, offrendo un'opportunità per discutere e promuovere la cultura del prendersi cura. </p>


         <h3>LO SPITEX PRIVATASSITENZA SA</h3>
         <p>Privatassistenza SA è uno <em>spitex</em> che offre un servizio di cure a domicilio di qualità nel territorio ticinese. La mission che anima il servizio di Privatassistenza SA è di assicurare alla persona vulnerabile o in condizioni di fragilità, la permanenza al proprio domicilio in salute, garantendo prestazioni sanitarie di cura e di assistenza personalizzate, volte a favorire il massimo grado di autonomia possibile</p>
        </>
      )}

      {(string === 'paCol1' && lang === 'eng') && (
        <>
         <h3>The PrivatAssistenza National Convention</h3>

         <p>In December 2023, the National Convention of PrivatAssistenza, the First National Home Care Network, took place on the OpenZone campus. The event celebrated 30 years since the founding of the network, which aims to put people first, providing an opportunity to discuss and promote the culture of caring.</p>


         <h3>PRIVATASSITENZA SA SPITEX</h3>
         <p>Privatassistenza SA is a <em>spitex</em> home care service provider offering quality home care services in the Ticino region. The mission that inspires the Privatassistenza SA service is to ensure that people in vulnerable or frail conditions can remain in their own home in good health, by guaranteeing personalised care and assistance services aimed at encouraging the highest possible degree of autonomy.</p>
        </>
      )}

      {(string === 'hacCol1' && lang === 'eng') && (
        <>
        <p>The company operates through its offices in Bresso and Reggio Emilia, which coordinate a widespread network of healthcare professionals operating throughout the country via an Operations Centre active 24/7.</p>
        <p>The company offers a system of integrated home and support services with remote monitoring, using a customised technological platform, web portals, native apps and devices to meet patient needs, while also guaranteeing centralised management and a single patient management access and organisation model.</p>
        <p>Human Assist Care services are characterised by its <strong>Human Touch</strong>, the approach and attention of operators with respect to patients during every contact, both remotely and in person, personalised on the basis of the disease, burden, specific needs and family context of individual patients and caregivers, with the aim of maximising the effectiveness of adherence support and the treatment plan. </p>
        </>
      )}

      {(string === 'peopleTitle') && (
        <h2>People Care</h2>
      )}
      {(string === 'peopleCol1' && lang === 'ita') && (
        <>
          <h3>TRAINING & DEVELOPMENT</h3>
          <p>
            Nell’anno, grazie al tool “SF My Learning”, sono stati gestiti più di 2.800 programmi formativi, per un
            totale di 240.000 ore dedicate a una popolazione di più di 3.200 utenti della Corporate, di tutti i Paesi
            e anche dei collaboratori esterni.</p>
          <p>È stata rinforzata la comunicazione sul Job & Project Posting, per favorire la crescita interna e lo
            sviluppo di nuove competenze. In totale sono stati pubblicati 322 Job Posting e 5 Project Posting.
            Il 14% delle vacancy è stato coperto internamente.</p>
        </>
      )}
      {(string === 'peopleCol2' && lang === 'ita') && (
        <>
          <h3>Feedback Training </h3>
          <p>I Manager e i Collaboratori di Zambon sono stati coinvolti nel percorso di formazione sul Feedback con l’obiettivo supportare tutta la popolazione nell'utilizzo del feedback continuo come strumento di sviluppo, rendendolo parte integrante del nostro modo di lavorare.
          </p>
          <h3>Agile Transformation</h3>
          <p>Nel corso del 2023, Zambon ha intrapreso un percorso di formazione mirato a supportare il processo di <em>Agile Transformation</em>; il top management e tutti i leadership team a livello di Country sono stati coinvolti nel percorso formativo, con l’obiettivo di diffondere e accrescere la cultura Agile nell’organizzazione. Per il 2024 è pianificato un cascading del percorso formativo a tutta la popolazione a livello globale.
          </p>
          <h3>PERFORMANCE MANGEMENT</h3>
          <p>Nell’ambito del processo di Performance Management, a integrazione dei quattro <em>behaviors, Accountability, Innovation, Focus e Ambition</em>, a partire dal 2023 Zambon ha inserito un nuovo KPI: la Collaboration, con lo scopo di rinforzare sempre più l’aiuto reciproco e l’ascolto attivo.
          </p>
          <p>Consolidamento dell’“Ask for feedback” nel Sistema di valutazione della performance attraverso il quale sono stati ricevuti 3.600 feedback a livello mondiale – il doppio rispetto all’anno scorso – utilizzati per favorire la collaborazione e la crescita.</p>
        </>
      )}

      {(string === 'peopleCol3' && lang === 'ita') && (
        <>
          <h3>ZGEN</h3>
          <p>Nel corso del 2023 tutti e 8 gli ZGEN hanno completato le 3 rotation previste dal programma inclusa l’esperienza di international assignment in una delle nostre affiliate.
          </p>
          <h3>Young Energies</h3>
          <p>Programma internazionale annuale di borse di studio dedicato agli studenti più meritevoli tra i figli dei nostri colleghi nonché ai figli affetti da disabilità o ai figli dei colleghi prematuramente scomparsi. Nel 2023, ha erogato 18 borse di merito e 8 borse di supporto a livello globale
          </p>
          <h3>IPA (Industrial People Academy)</h3>
          <p>Nel 2023 è nata la prima Academy dedicata ai collaboratori dei plant.<br />
          Si chiama IPA (Industrial People Academy) e ha l’obiettivo, partendo dalla mappatura delle competenze chiave richieste per ciascun ruolo nei plant, di favorire lo sviluppo delle persone dell’area industriale.
          Fortemente ancorata al pillar <em>Growing People to drive Growth</em> del nostro piano strategico, IPA è un percorso di apprendimento continuo che prevede l’offerta di corsi di formazione sia tecnica che trasversale sulla Leadership e l’Accountability progettati ed erogati sia con trainer esterni che interni.
          Nel 2024 IPA coinvolgerà i plant di Vicenza e Cadempino attraverso la programmazione di aule cross per favorire la collaborazione tra le persone degli stabilimenti e la condivisione di buone pratiche.
          L'obiettivo è di estenderla in futuro anche ai plant di Cina e Brasile.</p>
        </>
      )}





      {(string === 'peopleCol1' && lang === 'eng') && (
        <>
          <h3>TRAINING & DEVELOPMENT</h3>
          <p>During the year, thanks to the “SF My Learning” tool, over 2,800 training programmes were managed, for a total of 240,000 hours devoted to a population of over 3,200 users from the corporate offices, in every country, and also for external collaborators.</p>
          <p>Communication on Job & Project Posting was reinforced to foster internal growth and the development of new skills. A total of 322 Job Postings and 5 Project Postings were published. 14% of the vacancies were filled internally.</p>
        </>
      )}
      {(string === 'peopleCol2' && lang === 'eng') && (
        <>
          <h3>Feedback Training </h3>
          <p>Zambon managers and employees were involved in a Feedback training journey with the aim of supporting the entire population in using continuous feedback as a tool for development, making it an integral part of the way we work.</p>

          <h3>Agile Transformation</h3>
          <p>During 2023, Zambon embarked upon a training course aimed at supporting the Agile Transformation process; the top management and all leadership teams at country level were involved in the training course, with a view to spreading and reinforcing the Agile culture within the organisation. A cascading of the training course to the entire global population is planned for 2024.</p>

          <h3>PERFORMANCE MANGEMENT</h3>
          <p>As part of the Performance Management process, to complement the four <em>behaviours, Accountability, Innovation, Focus and Ambition</em>, in 2023 Zambon introduced a new KPI: Collaboration, in order to increasingly reinforce mutual aid and active listening.</p>
          <p>Consolidation of “Ask for Feedback” feature in the Performance Appraisal System through which 3,600 items of feedback were received worldwide - twice as many as last year - in order to foster collaboration and growth.</p>
        </>
      )}

      {(string === 'peopleCol3' && lang === 'eng') && (
        <>
          <h3>ZGEN</h3>
          <p>During 2023, all 8 ZGENs completed the 3 rotations established for the programme, including an international assignment in one of our affiliates.</p>

          <h3>Young Energies</h3>
          <p>Annual international scholarship programme for the most outstanding students among our colleagues’ children as well as for the children of colleagues suffering from disabilities or who have died before their time. In 2023, 18 scholarships for academic achievement and 8 grants for financial support were awarded.</p>

          <h3>IPA (Industrial People Academy)</h3>
          <p>In 2023, the first Academy dedicated to plant employees was born.<br />
          It is called IPA (Industrial People Academy) and aims, starting from the mapping of the key competencies required for each role in the plants, to foster the development of people in the industrial area.<br />
          Strongly anchored to the <em>Growing People to drive Growth</em> pillar of our strategic plan, IPA is a continuous learning pathway that provides both technical and transversal training courses on Leadership and Accountability designed and delivered with both external and internal trainers.</p>
          <p>In 2024, IPA will involve the Vicenza and Cadempino plants by planning cross-classrooms to foster collaboration between people at the plants and the sharing of best practices.<br />
          The goal will be to extend it to the plants in China and Brazil in the future.</p>
        </>
      )}




      {(string === 'ilariaTitle' && lang === 'ita') && (
        <h2>Messaggio del CEO</h2>
      )}
      {(string === 'ilariaCol1' && lang === 'ita') && (
        <>
          <p>Il 2022 è stato un anno di ottimi risultati finanziari per Zambon Pharma, ai quali si sono associati numerosi cambiamenti finalizzati a creare le migliori condizioni per garantire la crescita del nostro business e delle nostre persone.
          I ricavi sono stati pari a 765 milioni e l’EBITDA ha raggiunto i 153 milioni di euro: si tratta di risultati incoraggianti, che garantiscono solide fondamenta al Piano Strategico 2022-2027.
          Il nostro disegno di crescita conferma l’impegno nell’ambito delle malattie respiratorie severe a bassa prevalenza nonché la determinazione a coltivare il potenziale di crescita dei Brands storici.
          Stiamo facendo passi importanti per trasformare l’azienda, i suoi processi ed il modo di lavorare facendo leva sulla cultura dei dati e sull’innovazione tecnologica: efficacia e misurabilità dell’impatto degli investimenti ci consentiranno di creare più valore per i mercati in cui operiamo.
          Quanto sopra avviene, e continuerà ad avvenire, investendo nella risorsa più importante: le persone.</p>
          <p>Lo sviluppo delle competenze individuali e collettive è il cuore del nostro Piano Strategico: esso si rafforza con la messa in atto di comportamenti collaborativi e con l’applicazione della metodologia “agile”.
Ringrazio i colleghi, tutti indistintamente, che mi hanno accolta con fiducia ed hanno realizzato, con il loro impegno e la loro dedizione, i meravigliosi risultati del 2022. </p>
        </>
      )}
      {(string === 'ilariaCol2' && lang === 'ita') && (
        <>

          <h2>We stand for creating value for the patients we serve and for a sustainable future. Together.</h2>
          <p className="quoteAuthor">Ilaria Villa </p>
          <p className="quoteRole">CEO di Zambon Pharma</p>
        </>
      )}
      {(string === 'robertoTitle' && lang === 'eng') && (
        <h2>Message from the CEO</h2>
      )}
      {(string === 'robertoCol1' && lang === 'eng') && (
        <>
          <p>2021 was a crucial year for the development of Zambon Pharma.It was a year in which we implemented a
          long list of concrete measures, which are helping us to evolve into the company we have planned to be.
          Once again, we proved our enormous flexibility, and I am <strong>pleased with the way that our company has succeeded</strong>,
          with determination, creativity and pragmatism, <strong>in working in a new way, one based on innovation, inclusion,
          agility and ongoing professional training</strong>. Today we are stronger and more confident than ever that we can achieve
          all of the goals we have set for ourselves.</p>

          <p>Zambon Pharma’s year closed with an EBITDA of nearly 109 million euros and revenues in the amount of 638 million euros.
          These are considerable results, considering the ongoing impact of the pandemic and of large investments,
          and they prove, yet again, just how robust our company is.</p>
          <p>Over the course of the year, <strong>the important scientific outcomes</strong> achieved <strong>reaffirmed
          the guiding role Zambon's research plays when it comes to some of the most severe respiratory diseases</strong>.</p>
        </>
      )}
      {(string === 'robertoCol2' && lang === 'eng') && (
        <>
          <p>At the same time, we maintained our focus on our core products and on
          strengthening the portfolio of services and products we offer for the benefit of
          patients worldwide, and we will always continue to do so. </p>
          <p><strong>Our ongoing investment in our
          employees</strong> is allowing us to improve our company, which is evolving positively as an organisation as well.
          Today more than ever, allow me to thank all those who work at Zambon, who together,
          through the commitment they show every day, are the real engine of this special community!</p>
          <p><strong>I thank each and every one of you</strong></p>
          <p className="quoteAuthor">Roberto Tascione </p>
          <p className="quoteRole">CEO Zambon SpA</p>
        </>
      )}
      {(string === 'benvivereCol1' && lang === 'ita') && (
        <>
          <p>Nel corso del 2023, le attività del palinsesto Benvivere avevano come obiettivo aumentare il
            coinvolgimento dell'intero campus di OpenZone, con un'attenzione particolare alla salute,
            alla prevenzione e agli stili di vita sani.</p>
          <p>Durante l'anno, Benvivere ha potenziato e ampliato la scelta di convenzioni attive sul territorio
            e i servizi presenti nei nostri spazi. Tutte le convenzioni e i servizi sono stati estesi all'intero
            campus con l’idea di favorire una maggiore sinergia tra le aziende.</p>
        </>
      )}

      {(string === 'benvivereCol2' && lang === 'ita') && (
        <>
          <p className="mt-5">
            Nel 2023 si è deciso di investire del tempo per stimolare la community di professionisti e
            professioniste che fa parte del Gruppo attraverso dei momenti in cui abbiamo ampliato i nostri orizzonti.
            Questo impegno ha preso il nome di <em>Feelosophy</em>, un ciclo di 10 incontri guidati da Giovanni Ventimiglia,
            Professore dell’Università di Lucerna, in cui si è esplorato il significato delle emozioni partendo
            dalla madre di tutte le scienze, la filosofia, e aiutandosi con il lavoro dei grandi filosofi del passato
            senza perdere di vista il presente e il modo in cui si interagisce con gli altri e il mondo.
            Questi incontri hanno visto il coinvolgimento anche delle persone degli stabilimenti di Vicenza e Cadempino.</p>
          </>
      )}

      {(string === 'benvivereActIntro' && lang === 'ita') && (
         <p className="mb-3">Tra le numerose attività ed eventi dedicati al Gruppo e al campus nel 2023, si sono inclusi:</p>
      )}

      {(string === 'benvivereAct1' && lang === 'ita') && (
        <>
        Visite specialistiche: raddoppiando il numero di giornate dedicate alla prevenzione
        fino a coprire un totale di 300 visite effettuate
        </>
      )}
      {(string === 'benvivereAct2' && lang === 'ita') && (
        <>
          Summer Camp (per sei settimane) esteso a tutto il campus OpenZone
        </>
      )}
      {(string === 'benvivereAct3' && lang === 'ita') && (
        <>
          Tre giornate di donazione del sangue in azienda in partnership con l’AVIS di Milano
        </>
      )}
      {(string === 'benvivereAct4' && lang === 'ita') && (
        <>
          3 webinar globali sulla salute della donna e dell’uomo
        </>
      )}
      {(string === 'benvivereAct5' && lang === 'ita') && (
        <>
          Tre tornei sportivi che hanno visto il coinvolgimento delle sedi di Cadempino, Vicenza e Bresso
        </>
      )}

      {(string === 'benvivereCol1' && lang === 'eng') && (
        <>
          <p>During 2023, Benvivere programme activities aimed to increase the engagement of the entire OpenZone campus, with a specific focus on health, prevention and healthy lifestyles.</p>
          <p>During the year, Benvivere strengthened and expanded the selection of active agreements with local businesses and the services available in our spaces. All agreements and services have been extended to the entire campus with a view to fostering greater synergy between the companies.</p>
        </>
      )}

      {(string === 'benvivereCol2' && lang === 'eng') && (
        <>
          <p className="mt-5">In 2023, the company decided to invest some time to stimulate the Group’s community of professionals during special occasions for broadening their horizons. This endeavour took the name of <em>Feelosophy</em>, a cycle of 10 meetings led by Giovanni Ventimiglia, Professor at the University of Lucerne, in which the meaning of emotions was explored, starting from the mother of all sciences, philosophy, and drawing on the work of the great philosophers of the past without losing sight of the present and how we interact with others and the world. These meetings also involved people from the Vicenza and Cadempino plants.</p>
          </>
      )}
      {(string === 'benvivereActIntro' && lang === 'ita') && (
         <p className="mb-3">The numerous activities and events dedicated to the Group and the campus in 2023 included:</p>
      )}

      {(string === 'benvivereAct1' && lang === 'eng') && (
        <>
        Specialist visits: the number of days devoted to prevention was doubled to reach a total of 300 visits carried out
        </>
      )}
      {(string === 'benvivereAct2' && lang === 'eng') && (
        <>
          Summer Camp (six weeks) extended to the entire OpenZone campus
        </>
      )}
      {(string === 'benvivereAct3' && lang === 'eng') && (
        <>
          Three days of blood donations at the company in partnership with the AVIS blood donation centre in Milan
        </>
      )}
      {(string === 'benvivereAct4' && lang === 'eng') && (
        <>
          3 global webinars on women's and men's health
        </>
      )}
      {(string === 'benvivereAct5' && lang === 'eng') && (
        <>
          Three sports tournaments involving the Cadempino, Vicenza and Bresso sites
        </>
      )}



      {(string === 'careaptTitle') && (
        <>
          <h2>Careapt</h2>
        </>
      )}
      {(string === 'careaptCol1' && lang === 'ita') && (
         <>
            <p>Careapt è la start-up del gruppo Zambon dedicata allo sviluppo di soluzioni Hi-Tech / Hi-Touch per trasformare la cura delle malattie croniche in un’esperienza di relazione e attenzione alla persona.</p>
            <p>La prima soluzione sviluppata da Careapt e lanciata nel 2020 è ParkinsonCare, un servizio di teleassistenza infermieristica specialistica dedicato alle persone con Malattia di Parkinson, ai loro caregiver e al loro team di cura.</p>
         </>
      )}
      {(string === 'careaptCol2' && lang === 'ita') && (
        <>
           <p>Nel 2022 Careapt ha lanciato DemedyaCare, la prima soluzione di teleassistenza e teleriabilitazione occupazionale in Italia che affianca i caregiver delle persone con demenza nella loro vita quotidiana.</p>

           <p>Nel 2023 è stata sviluppata una terza soluzione, AttivaCare, dedicata ai caregiver degli anziani non autosufficienti, specificamente studiata per il mercato Welfare Aziendale. Sono state inoltre attivate televisite neurologiche, televisite geriatriche e specifici percorsi di sostegno psicologico dedicati ai caregiver.</p>
        </>
      )}


      {(string === 'careaptCol1' && lang === 'eng') && (
         <>
            <p>Careapt is the Zambon group's start-up dedicated to the development of Hi-Tech/Hi-Touch solutions for transforming chronic disease treatment into a person-centred experience of human relations and care.</p>
            <p>The first service developed by Careapt and launched in 2020 was ParkinsonCare, a remote nursing care platform for people with Parkinson's Disease, their caregivers and their healthcare team.</p>
         </>
      )}
      {(string === 'careaptCol2' && lang === 'eng') && (
        <>
           <p>In 2022, Careapt launched DemedyaCare, the first remote care and remote occupational rehabilitation solution in Italy, supporting caregivers of people with dementia in their daily lives.</p>

           <p>In 2023 a third solution, AttivaCare, was developed, platform dedicated to the caregivers of dependent elderly people, specifically designed for the Corporate Welfare market. Neurological remote appointments, geriatric remote appointments and specific psychological support paths dedicated to caregivers have also been activated.</p>
        </>
      )}

      {(string === 'investimentiTitle' && lang === 'ita') && (
        <h2>Investimenti 2023</h2>
      )}
      {(string === 'investimentiCol1' && lang === 'ita') && (
         <>
           <p>Durante il 2023, Zach ha destinato circa 4,9 milioni di euro per investimenti nello stabilimento di 	Avrillè, in Francia. Questo finanziamento è stato principalmente indirizzato all'acquisto e 		all'installazione di un nuovo filtro essiccatore nel recentemente istituito reparto COF2Y, nonché 	all'ampliamento del reparto di finissaggio per accogliere una nuovissima unità di spray drying. </p>
           <p>Successivamente sono state acquistate numerose attrezzature per l’impianto pilota, i laboratori 	R&D e CQ. Gli investimenti hanno riguardato anche l’ingegneria di dettaglio del nuovo edificio che ospiterà i nuovi laboratori CQ e uffici.</p>
         </>
      )}
      {(string === 'investimentiCol2' && lang === 'ita') && (
        <>
           <p>Altrettanto importanti sono stati gli investimenti in IT, volti sia a adeguare l’infrastruttura 	informatica ai più alti standard internazionali che ad aumentarne la sicurezza e prevenire gli 	attacchi informatici.</p>
           <p>Ulteriori investimenti ordinari sono stati effettuati per accrescere l'efficienza degli impianti e 	mantenerli costantemente conformi agli standard di sicurezza e alle GMP (<em>Good Manufacturing Practices</em>).</p>
         </>
      )}


      {(string === 'investimentiTitle' && lang === 'eng') && (
        <h2>Investments 2023</h2>
      )}
      {(string === 'investimentiCol1' && lang === 'eng') && (
         <>
           <p>During 2023, Zach earmarked around €4.9 million for investments in the plant in Avrillè, France. This funding was mainly directed towards the purchase and installation of a new filter dryer in the newly established COF2Y department, as well as the expansion of the finishing department to accommodate a brand new spray drying unit.</p>
           <p>Subsequently, numerous pieces of equipment were purchased for the pilot plant and the R&D and QC laboratories. The investments also covered the detailed engineering of the new building that will house the new QC laboratories and offices.</p>
         </>
      )}
      {(string === 'investimentiCol2' && lang === 'eng') && (
        <>
           <p>Just as significant investments were also made in IT, aimed both at adapting the infrastructure 	to the highest international standards and increasing its security and preventing 	cyber attacks.</p>
           <p>Further ordinary investments were made to increase the efficiency of plants and keep them constantly compliant with safety standards and GMP (Good Manufacturing Practices).</p>
         </>
      )}


      {(string === 'goodscienceTitle') && (
        <h2>Good Science</h2>
      )}
      {(string === 'goodscienceCol1' && lang === 'ita') && (
        <>
            <p>In continuità con gli anni precedenti, i due i programmi late-stage su cui ci siamo maggiormente concentrati sono il programma “NCFB (PROMIS)” e il programma “BOS (BOSTON)”, entrambi definiti da studi di Fase III globali e multicentrici a scopo registrativo. </p>
            <p>In ambito Neurologico, è stato completato lo studio osservazionale Europeo “SUCCESS” e sono stati supportati due studi indipendenti IITs (Investigational Initiated Trials). </p>
            <h3>Medical Affairs Central Nervous System (CNS)</h3>
            <p>Nel 2023 abbiamo:</p>
            <ul>
               <li>organizzato un booth e un simposio dedicato al congresso internazionale della European Academy of Neurology (EAN);</li>
               <li>potenziato il progetto “Patients Doctor Communication”, volto a migliorare e facilitare la comunicazione tra medici, pazienti e caregivers;</li>
               <li>ottenuto l’autorizzazione per l’immissione sul mercato di Xadago®️ in Slovenia, Croazia e Grecia, sono in corso le procedure di registrazione in Repubblica Ceca, Slovacchia, Lituania, Serbia, Turchia, Sud Africa, Egitto, e Cina.</li>
            </ul>
        </>
      )}
      {(string === 'goodscienceCol2' && lang === 'ita') && (
        <>
           <h3>Medical Affairs Established and Respiratory Medicine</h3>
           <p>Nel 2023 è proseguita l’attività di diffusione dei dati scientifici riguardanti Fluimucil®️ (N-acetilcisteina o NAC) nell’ambito del congresso internazionale dalla European Respiratory Society (ERS), con uno spazio dedicato ai contenuti medici, un’area dedicata al brand e la presentazione di un abstract scientifico.</p>
           <p>Inoltre, numerosi studi e pubblicazioni indipendenti hanno contributo a rafforzare il ruolo della NAC nel contrastare lo stress ossidativo e gli altri meccanismi molecolari alla base della progressione delle malattie respiratorie croniche così come delle infezioni acute.</p>
           <p>In aggiunta, sono stati pubblicati i dati clinici degli studi di fase I e fase III condotti con NAC endovena in Cina.</p>
           <p>Negli Stati Uniti è iniziato l’arruolamento dei primi pazienti del trial ATTACK, guidato dalla Johns-Hopkins University e volto a valutare l’efficacia e la sicurezza a lungo termine di alti dosaggi di N-acetilcisteina orale in pazienti con retinite pigmentosa. Inoltre, sempre negli Stati Uniti, si è quasi concluso l’arruolamento di PRECISIONS, uno studio clinico che vede coinvolti pazienti con fibrosi polmonare idiopatica (IPF), una grave malattia respiratoria cronica, caratterizzati da un particolare polimorfismo genetico (TOLLIP-TT, presente in circa il 25% dei malati di IPF).</p>
           <p>Anche Monuril<sup>®️</sup> (fosfomicina trometamolo) e Spidifen®️ (ibuprofene l-arginina) sono stati protagonisti di diverse attività scientifiche. Nel secondo trimestre del 2023 è stato organizzato un Advisory Board su fosfomicina trometamolo e nel terzo trimestre un Advisory Board su ibuprofene arginina.</p>
           <p>Infine, nel mese di novembre è stata pubblicata una review sull’utilizzo di fosfomicina trometamolo nel trattamento delle cistiti non complicate in gravidanza, mettendo in evidenza dati da studi clinici e linee guida internazionali e confermando ulteriormente la sicurezza d’uso in questo contesto.</p>
        </>
      )}

      {(string === 'goodscienceCol1' && lang === 'eng') && (
        <>
            <p>In continuity with prior years, the two late-stage programmes on which we focused most were the “NCFB (PROMIS)” programme and the “BOS (BOSTON)” programme, both defined by global, multi-centre phase III studies for regulatory purposes.</p>
            <p>In the Neurological field, the “SUCCESS” European observational study was completed and two independent IITs (Investigational Initiated Trials) were supported.</p>
            <h3>Medical Affairs Central Nervous System (CNS)</h3>
            <p>In 2023 we:</p>
            <ul>
               <li>organised a dedicated booth and a symposium at the international congress of the European Academy of Neurology (EAN);</li>
               <li>strengthened the “Patients Doctor Communication” project aimed at improving and facilitating communication between doctors, patients and caregivers;</li>
               <li>obtained market authorisation for Xadago® in Slovenia, Croatia and Greece; registration procedures are underway in the Czech Republic, Slovakia, Lithuania, Serbia, Turkey, South Africa, Egypt and China.</li>
            </ul>
        </>
      )}
      {(string === 'goodscienceCol2' && lang === 'eng') && (
        <>
           <h3>Medical Affairs Established and Respiratory Medicine</h3>
           <p>In 2023, the dissemination of scientific data concerning Fluimucil® (N-acetylcysteine or NAC) continued at the international congress of the European Respiratory Society (ERS), with a space dedicated to medical content, a brand area and the presentation of a scientific abstract.</p>

           <p>Furthermore, several independent studies and publications contributed to strengthening the role of NAC in combating oxidative stress and other molecular mechanisms underlying the progression of chronic respiratory diseases as well as acute infections.</p>

           <p>In addition, clinical data have been published from phase I and phase III studies conducted in China with intravenous NAC.</p>
           <p>In the United States, patient enrolment began for the ATTACK trial, led by Johns Hopkins University and aimed at assessing the long-term effectiveness and safety of high doses of oral N-acetylcysteine in patients with retinitis pigmentosa. Furthermore, also in the United States, enrolment has nearly concluded for PRECISIONS, a clinical trial involving patients with idiopathic pulmonary fibrosis (IPF), a severe chronic respiratory disease, characterised by a particular genetic polymorphism (TOLLIP-TT, present in about 25% of IPF patients).</p>
           <p>Monuril<sup>®</sup> (fosfomycin trometamol) and Spidifen® (ibuprofen l-arginine) have also been featured in several scientific activities. An Advisory Board on fosfomycin trometamol was organised in Q2 2023 and an Advisory Board on ibuprofen arginine in Q3.</p>
           <p>Lastly, a review on the use of fosfomycin trometamol in the treatment of uncomplicated cystitis in pregnancy was published in November, highlighting data from clinical trials and international guidelines and further confirming its safety of use in this context.</p>
        </>
      )}

      {(string === 'iboTitle') && (
        <h2>Industrial Business Operations</h2>
      )}
      {(string === 'iboCol1' && lang === 'ita') && (
        <>
            <p>Il 2023 è stato un anno significativo per gli stabilimenti che hanno affrontato e superato una doppia sfida: soddisfare le crescenti richieste dei mercati (che hanno superato del 17% i volumi rispetto al Budget) e, gestire le difficoltà legate alle forniture.</p>
            <p>Centrale, nel primo semestre, lo sviluppo di una serie di progetti di efficientamento per migliorare le performance produttive. Focus del secondo semestre, invece, l’avvio del progetto Supply Chain Integration per definire un nuovo processo volto a migliorare il sincronismo tra plant e mercati.</p>
            <p>Nello stabilimento di <strong>Vicenza</strong> (Italia) sono iniziate le attività per il confezionamento secondario del prodotto a base di “Riluzolo” e sono continuate le attività dello Zambon Production System, progetto che prevede l’efficientamento degli impianti produttivi. Per quanto riguarda il progetto 3B, sono in fase di avanzamento l’installazione di tutte le utility e degli impianti per la produzione del farmaco per la cura della BOS.</p>
            <p>A <strong>Cadempino</strong> (Svizzera) è proseguito il progetto triennale di sostenibilità sviluppato in collaborazione con la Scuola universitaria della Svizzera italiana (SUPSI). Il completamento e l’attivazione dell’impianto fotovoltaico ridurranno ulteriormente le emissioni di CO2 e renderanno lo stabilimento in parte autonomo nella generazione di energia elettrica. Inoltre, nel corso del 2023, in linea con il piano strategico, sono state installate nuove attrezzature nel reparto sterili ed è iniziata l’installazione della nuova linea di confezionamento sachet.</p>
        </>
      )}
      {(string === 'iboCol2' && lang === 'ita') && (
        <>
           <p>Completati ulteriori investimenti nello stabilimento di <strong>Haikou</strong> (Cina) per incrementare la capacità produttiva e per sostituire parte degli impianti destinati alle produzioni di Fluimucil<sup>®️</sup> e Spidifen<sup>®️</sup> granulari e compresse. Terminati anche i lavori per la reingegnerizzazione di un’intera area produttiva dello stabilimento oltre alla completa ristrutturazione degli uffici del sito.</p>
           <p>A <strong>Barueri</strong> (Brasile), le attività principali hanno riguardato il trasferimento tecnologico di prodotti dallo stabilimento di Sanofi a quello di Unither che si concluderà nel corso del 2025.</p>
           <p>Complessivamente il numero di confezioni prodotte è risultato pari a 200 milioni, in aumento del 15% rispetto al precedente esercizio.</p>
        </>
      )}

      {(string === 'iboCol1' && lang === 'eng') && (
        <>
            <p>The year 2023 was a significant one for the facilities, which faced and overcame a dual challenge: meeting growing market demand (with volumes 17% higher than budgeted) and managing supply bottlenecks.</p>
            <p>In the first half of the year, the development of a series of efficiency projects to improve production performance played a central role. The focus of the second half of the year was the launch of the Supply Chain Integration project to define a new process to improve synchrony between the plants and the markets.</p>
            <p>At the <strong>Vicenza</strong> (Italy) facility, activities began for the secondary packaging of Riluzole-based product, and the activities of the Zambon Production System, a project to improve production facility efficiency, continued. As far as the 3B project is concerned, the installation of all utilities and systems to produce the drug for the treatment of BOS is well underway.</p>
            <p>In  <strong>Cadempino</strong> (Switzerland), the three-year sustainability project developed in cooperation with the University of Southern Switzerland (SUPSI) continued. The completion and activation of the photovoltaic system will further reduce CO2 emissions and make the plant partly self-sufficient in power generation. Furthermore, during 2023, in line with the strategic plan, new equipment was installed in the sterile department and the installation of the new sachet packaging line began.</p>
        </>
      )}
      {(string === 'iboCol2' && lang === 'eng') && (
        <>
           <p>Further investments were completed at the <strong>Haikou</strong> (China) facility to boost production capacity and replace part of the equipment used for the production of Fluimucil<sup>®</sup> and Spidifen<sup>®</sup> granules and tablets. Work was also completed at the facility on the re-engineering of an entire production area as well as the complete renovation of the on-site offices.</p>
           <p>In <strong>Barueri</strong> (Brazil), the main activities involved the technology transfer of products from the Sanofi facility to the Unither facility, which will be completed during 2025.</p>
           <p>The number of packages produced overall was 200 million, up 15% compared with the previous financial year.</p>
        </>
      )}

      {(string === 'pspTitle') && (
        <h2>Patient Support Program</h2>
      )}
      {(string === 'pspCol1' && lang === 'ita') && (
        <>
          <p>ItaliAssistenza è leader di mercato in Italia nel supporto alla gestione
          domiciliare delle terapie croniche, attraverso i Patient Support Program (PSP),
          programmi di supporto alla terapia farmacologica realizzati in collaborazione
          con le maggiori società farmaceutiche multinazionali.
          </p>

          <p>I PSP mirano a garantire l’aderenza e l’efficacia della terapia farmacologica e prevedono
          l'organizzazione di un sistema strutturato e coordinato di servizi domiciliari, telefonici e
          tecnologici, in grado di supportare il paziente cronico durante il suo percorso terapeutico.
          </p>
        </>
      )}
      {(string === 'pspCol1' && lang === 'eng') && (
        <>
          <p>ItaliAssistenza is Italy's market-leading provider of support for the home management of therapies for
          chronic diseases, thanks to its pharmacological therapy support programs known as Patient Support
          Programs (PSPs), conducted in collaboration with the leading multinational pharmaceutical companies.</p>

          <p>PSPs are designed to ensure adherence to and effectiveness of drug therapies; they involve the organisation
          of a structured and coordinated system of home, telephone and technological services to support chronic
          patients on their therapeutic pathways.</p>
        </>
      )}

      {(string === 'at1Title' && lang === 'ita') && (
        <h2>Dolore</h2>
      )}
      {(string === 'at1Col1' && lang === 'ita') && (
        <>
          <p>Il dolore è un “sistema d’allarme” di danni tessutali e/o processi infiammatori nel nostro organismo. Per il trattamento del dolore acuto, da lieve a moderato, Zambon vanta una formulazione a base di Ibuprofene e sale d’arginina. Tale formulazione è stata tra le prime a permettere un assorbimento rapido a livello gastrico offrendo un’azione rapida.</p>
          <p>All'interno della gamma di prodotti commercializzati da Zambon, si segnala: </p>
          <h3>SPIDIDOL<sup>®️</sup> / SPIDIFEN<sup>®️</sup></h3>
          <p>Spidifen/ Spididol<sup>®️</sup> è un medicinale a base d’<em>ibuprofene</em> e sale di arginina indicato per il trattamento di dolori di varia origine e natura. Appartiene alla categoria dei farmaci antinfiammatori non steroidei (FANS), medicinali in grado di combattere il dolore e l’infiammazione offrendo un rapido assorbimento. Inoltre, è coadiuvante nel trattamento degli stati febbrili e influenzali.</p>
          <p>La promozione della conoscenza del brand è aumentata grazie alle interazioni con il consumatore, il farmacista e i medici. Ai fini di venire incontro alle esigenze del consumatore nel 2023 è stato effettuato il lancio di un nuovo formato a base di ibuprofene, SpididolPocket che ha permesso di soddisfare i bisogni dei pazienti di età superiore ai 6 anni, offrendo vantaggi come l'assunzione senza acqua e la praticità d'uso. Questo nuovo prodotto è stato introdotto sul mercato italiano e seguirà quello belga. Oltre a questo lancio, si continua a lavorare in collaborazione con R&D e altri partner esterni per migliorare la customer experience e soddisfare le diverse esigenze dei nostri pazienti.</p>

        </>
      )}
      {(string === 'at1Col2' && lang === 'ita') && (
         <>
            <p>L’area terapeutica del Dolore è cresciuta del 13,7% rispetto al 2022, sfiorando gli 84 milioni di euro di fatturato. Spedifen<sup>®️</sup>, brand principale dell’area terapeutica (90%), ha trainato la crescita, superando i 76 milioni di euro contro i 67 milioni di euro del 2022 (+13%). Tutti i Paesi hanno migliorato la propria performance (in particolare Cina +38%, Italia +26%, Brasile +19% e Spagna +4%).</p>
            <p>Considerando questa crescita e il potenziale di soddisfare le esigenze di un maggior numero di pazienti, Zambon sta avviando un progetto per rafforzare ulteriormente il brand, i suoi valori e il suo portfolio. I nuovi progetti non riguardano solo la Line extention, ma anche l'espansione della sua interazione con i consumatori, che si estende dai tradizionali canali di comunicazione a quelli più recenti digitali. L’obiettivo è diffondere il valore e l’impatto positivo che Spidifen genera sulla qualità di vita dei nostri pazienti.</p>
         </>
      )}


      {(string === 'at1Title' && lang === 'eng') && (
        <h2>Pain</h2>
      )}
      {(string === 'at1Col1' && lang === 'eng') && (
        <>
          <p>Pain is our body’s “alarm system” of tissue damage and/or inflammatory processes. For the treatment of mild-to-moderate acute pain, Zambon offers a formulation containing ibuprofen and salt of l-arginine. This formulation was one of the first to allow for rapid absorption in the stomach, offering fast action.</p>
          <p>The highlight of Zambon’s product range is:</p>
          <h3>SPIDIDOL<sup>®️</sup> / SPIDIFEN<sup>®️</sup></h3>
          <p>Spidifen/Spididol<sup>®️</sup> is a medicine with <em>Ibuprofen</em> and Arginine used to treatment of pain of various origins and natures. It belongs to the category of non-steroidal anti-inflammatory drugs (NSAIDs), the drugs that can counter pain and inflammation, offering rapid absorption. In addition, it is also used in the treatment of fever and flu symptoms.</p>
          <p>Brand awareness was increased through interactions with consumers, pharmacists and doctors. In 2023 a new ibuprofen-based format, SpididolPocket, was launched to meet the needs of patients over 6 years of age, offering advantages such as ingestion without water and convenience of use. This new product has been introduced to the Italian market and the Belgian market will follow. In addition to this launch, we continue to work in collaboration with R&D and other external partners to improve the customer experience and meet the needs of our patients.</p>
        </>
      )}
      {(string === 'at1Col2' && lang === 'eng') && (
        <>
          <p>The Pain therapeutic area grew by 13.7% compared with 2022, reaching €84 million in turnover. Spedifen<sup>®️</sup>, the main brand in the therapeutic area (90%), drove growth, exceeding €76 million compared to €67 million in 2022 (+13%). All countries improved their performance (especially China +38%, Italy +26%, Brazil +19% and Spain +4%).</p>
          <p>Considering this growth and the potential to meet the needs of higher numbers of patients, Zambon is embarking upon a project to further strengthen the brand, its values and its portfolio. The new projects are not only about line extension, but also about expanding interaction with consumers from traditional communication channels to the most cutting-edge digital ones. The aim is to spread the value and positive impact that Spidifen generates on our patients’ quality of life.</p>
        </>
      )}

      {(string === 'at2Title' && lang === 'ita') && (
        <h2>Disordini Neurologici</h2>
      )}
      {(string === 'at2Col1' && lang === 'ita') && (
         <>
            <p>Il nostro impegno nell’area dei disordini neurologici è iniziato nel 2015 con un focus sulla Malattia di Parkinson, al quale dal 2019 – in seguito all’accordo con Aquestive – si è aggiunto il nostro impegno nella Sclerosi Laterale Amiotrofica (SLA) in Europa.</p>
            <h3>XADAGO<sup>®️</sup></h3>
            <p>Immesso sul mercato nel 2015, Xadago<sup>®️</sup> - <em>safinamide</em> - è oggi presente in 24 paesi anche grazie agli accordi con una serie di distributori locali.</p>
            <p>Nel 2023 il farmaco ha registrato un fatturato netto pari a 67,8 milioni di euro, con una crescita del +12% (pari a +7,2 milioni di euro) rispetto all’anno precedente, grazie alle buone performance conseguite in Spagna (+16%), Brasile (+49%), Colombia (+37%) e Germania (+4%). </p>


         </>
      )}
      {(string === 'at2Col2' && lang === 'ita') && (
        <>
           <p>Inoltre, nel 2022, è stato completato lo studio europeo osservazionale di Fase IV “SUCCESS” per la valutazione della qualità della vita nei pazienti trattati con Xadago<sup>®️</sup> in confronto agli altri farmaci anti-Parkinson presenti sul mercato ed è in corso l’analisi dei risultati.</p>
           <p>Infine, è continuato il supporto a due studi indipendenti IITs (Investigational Initiated Trials) che valutano l’efficacia di Xadago sul dolore e sulla “fatigue” nei pazienti Parkinsoniani. </p>
           <h3>Emylif<sup>®️</sup></h3>
           <p>Nel corso dell’anno, in alcuni mercati Europei, è stato lanciato Emylif<sup>®️</sup>, la prima formulazione orodispersibile di <em>riluzolo</em>.</p>
           <p>La Sclerosi Laterale Amiotrofica (SLA), conosciuta anche come malattia di Lou Gehrig, è una malattia neurodegenerativa progressiva dell’età adulta, determinata dalla perdita dei motoneuroni che conduce alla paralisi dei muscoli volontari fino a coinvolgere anche quelli respiratori.</p>
        </>
      )}

      {(string === 'at2Title' && lang === 'eng') && (
        <h2>THE CENTRAL NERVOUS SYSTEM</h2>
      )}
      {(string === 'at2Col1' && lang === 'eng') && (
         <>
            <p>Our commitment in neurological disorders began in 2015 with a focus on Parkinson's Disease, which since 2019 - following the agreement with Aquestive - has been joined by our commitment to Amyotrophic Lateral Sclerosis (ALS) in Europe.</p>
            <h3>XADAGO<sup>®️</sup></h3>
            <p>Introduced to the market in 2015, Xadago<sup>®️</sup> - <em>safinamide</em> - is now present in 24 countries thanks to agreements with several local distributors.</p>
            <p>In 2023, the drug recorded a net turnover of €67.8 million, with growth of +12% (equal to +€7.2 million) compared with the previous year thanks to good performance achieved in Spain (+16%), Brazil (+49%), Colombia (+37%) and Germany (+4%).</p>
         </>
      )}
      {(string === 'at2Col2' && lang === 'eng') && (
        <>
           <p>In addition, the “SUCCESS” European Phase IV observational study was completed in 2023, to evaluate quality of life in patients treated with Xadago<sup>®</sup> in comparison with other anti-Parkinson's drugs on the market and the results are currently being analysed.</p>
           <p>Finally, support continued for two independent IITs (Investigational Initiated Trials) evaluating the efficacy of Xadago on pain and fatigue in Parkinson’s patients.</p>
           <h3>Emylif<sup>®️</sup></h3>
           <p>During the year, Emylif<sup>®</sup> was launched in some European markets, the first orodispersible formulation of <em>riluzole</em>.</p>
           <p>Amyotrophic Lateral Sclerosis (ALS), also known as Lou Gehrig's disease, is a progressive adult neurodegenerative disease caused by the loss of motor neurons, leading to paralysis of the voluntary muscles and eventually involving the respiratory muscles.</p>
        </>
      )}

      {(string === 'at3Title' && lang === 'ita') && (
        <h2>MALATTIE DELL’APPARATO RESPIRATORIO </h2>
      )}
      {(string === 'at3Col1' && lang === 'ita') && (
         <>
            <p>Zambon ha sviluppato una consolidata competenza farmacologica e formulativa nell’area respiratoria, vantando oggi un sistema articolato di soluzioni terapeutiche specifiche per il trattamento delle principali patologie, con prodotti a base di <em>N-acetilcisteina</em> o <em>cloperastina</em>. Il Gruppo ha sviluppato anche una linea completa di dispositivi medici pensati per il benessere del respiro di adulti e bambini.</p>
            <p>All'interno della gamma di prodotti commercializzati da Zambon, si segnala: </p>
            <h3>FLUIMUCIL<sup>®️</sup></h3>
            <p>Fluimucil<sup>®️</sup> è un medicinale a base di <em>N-acetilcisteina</em>, molecola caratterizzata da molteplici azioni, tra cui l’attività mucolitica e quella antiossidante. Queste proprietà rendono il farmaco utile non solo nelle affezioni respiratorie acute caratterizzate dalla presenza di muco ma anche nel trattamento di patologie croniche, quali bronchite cronica, BPCO (Broncopneumopatia cronica ostruttiva), Fibrosi Cistica e bronchiettasie non correlate alla Fibrosi Cistica. Fluimucil<sup>®️</sup> è presente in un’ampia gamma di dosaggi e forme farmaceutiche, dalle fiale ad uso inalatorio alle forme orali, per soddisfare le esigenze di trattamento delle diverse e numerose tipologie di pazienti. </p>
            <p>In considerazione delle proprietà antiossidanti della <em>N-acetilcisteina</em>, in alcuni paesi, principalmente al di fuori dell’Europa, si è registrato un considerevole utilizzo del Fluimucil<sup>®️</sup> in pazienti affetti da COVID-19 e, pertanto, sono stati avviati alcuni studi clinici a livello globale per esaminarne scientificamente gli effetti terapeutici. </p>
        </>
      )}
      {(string === 'at3Col2' && lang === 'ita') && (
        <>
           <p>Il 2023 è stato caratterizzato dal prosieguo delle attività di thought leaderhsip a livello di divulgazione scientifica, con la sponsorizzazione di un evento accreditato a livello internazionale svoltosi Napoli in continuità con quanto fatto a Barcellona nel 2022, che ha visto la presenza dei massimi esperti di patologie respiratorie condividere interventi e raccomandazioni da applicare alla pratica clinica. </p>
           <p>Si è visto inoltre un forte rilancio della parte OTC del brand grazie ad una rinnovata campagna di comunicazione omnicanale con una forte componente digitale, andata in onda a livello globale durante la stagione invernale con notevole successo di vendite e comunicazione. </p>
           <p>Passando all’analisi dell’andamento economico del 2023, l’area terapeutica “Malattie dell’apparato Respiratorio” registra un fatturato pari a 447,8 milioni di euro, in aumento del 16,5% rispetto al 2022. Il ritorno alla normalità post-covid è stato caratterizzato da un forte incremento dei mercati di riferimento a causa di una stagione influenzale 2022/2023 molto rilevante per via della scarsa copertura immunitaria della popolazione dopo gli anni dei lock down che avevano portato ad una diminuzione delle difese immunitarie, meno esposte all’influenza stagionale nelle annate precedenti. </p>
           <p>Fluimucil<sup>®️</sup>, il brand principale del portfolio Zambon, ha guidato la crescita verso il 2022 (+22,4%) con tutti i principali mercati in segno positivo.</p>
        </>
      )}

      {(string === 'at3Title' && lang === 'eng') && (
        <h2>RESPIRATORY DISEASES</h2>
      )}
      {(string === 'at3Col1' && lang === 'eng') && (
         <>
            <p>Zambon has developed significant pharmaceutical and formulation expertise in the treatment of respiratory diseases with a comprehensive system of specific treatment solutions for the main pathologies with products containing APIs <em>N-Acetylcysteine</em> or <em>Cloperastine</em>. The Group has also developed a complete line of medical devices designed for the respiratory well-being of adults and children.</p>
            <p>The highlight of Zambon’s product range is:</p>
            <h3>FLUIMUCIL<sup>®️</sup></h3>
            <p>The API of Fluimucil<sup>®️</sup> is <em>N-Acetylcysteine</em>, a molecule with multiple actions, including phlegm reduction and antioxidants. These properties make it useful not only for acute respiratory illnesses with mucus, but also for chronic diseases, such as chronic bronchitis, chronic obstructive pulmonary disease (COPD), Cystic Fibrosis and Non-Cystic Fibrosis Bronchiectasis. Fluimucil<sup>®</sup> is available in a wide range of doses and types, from vials for inhalation to liquids for oral administration, to meet the treatment needs of several types of patients.</p>
            <p>Considering the antioxidant properties of <em>N-Acetylcysteine</em>, in several countries, primarily outside Europe, there has been considerable use of Fluimucil<sup>®</sup> for patients suffering from COVID-19, and therefore some clinical studies at global level have started, to scientifically review its therapeutic effects.</p>
        </>
      )}
      {(string === 'at3Col2' && lang === 'eng') && (
        <>
           <p>The year 2023 was characterised by the continuation of thought leadership activities in terms of scientific outreach, with the sponsorship of an internationally accredited event held in Naples in continuity with what was done in Barcelona in 2022, which saw the presence of leading experts in respiratory diseases who made speeches and shared recommendations to be applied in clinical practice.</p>
           <p>There was also a strong re-launch of the brand’s OTC segment, thanks to an updated omnichannel communication campaign with a strong digital component, which was broadcast worldwide during the winter season with considerable sales and communication success.</p>
           <p>Moving on to analyse economic performance in 2023, the “Respiratory diseases” therapeutic area recorded turnover of €447.8 million, up 16.5% compared with 2022. The post-COVID return to normal was characterised by a strong increase in the target markets due to a highly significant 2022/2023 influenza season because of the low immunity of the population after the lock-down years that had led to a decrease in immune defences, which were less exposed to seasonal influenza in previous years.</p>
           <p>Fluimucil<sup>®</sup>, the leading brand in the Zambon portfolio, drove growth compared with 2022 (+22.4%) with all the main markets showing positive results.</p>
        </>
      )}

      {(string === 'at4Title' && lang === 'ita') && (
        <h2>Malattie respiratorie gravi</h2>
      )}
      {(string === 'at4Col1' && lang === 'ita') && (
         <>
            <p>Nel 2023 Zambon ha rinnovato il proprio impegno nella ricerca di soluzioni capaci di rispondere ai bisogni dei pazienti, specie se affetti da patologie severe come le Malattie Respiratorie Gravi quali Fibrosi Cistica, Sindrome da Bronchiolite Obliterante (BOS) e Bronchiectasie non da Fibrosi Cistica (NCFB).</p>
            <h3>Fibrosi Cistica</h3>
            <p>Zambon conferma la presenza nell’area Fibrosi Cistica con il <em>colistimetato di sodio</em> (Promixin<sup>®️</sup>) per il trattamento delle infezioni polmonari croniche causate da <em>Pseudomonas aeruginosa</em>. </p>
            <h3>Sindrome da Bronchiolite Obliterante (BOS)</h3>
            <p>Nel 2023 si è concluso l’arruolamento dei pazienti degli studi di fase III BOSTON-1 e BOSTON-2, atti a valutare la ciclosporina liposomiale A per via inalatoria (LCsA-i) per il trattamento della BOS in seguito al trapianto di polmone. La BOS è la forma più comune di disfunzione cronica dell'allotrapianto polmonare (nota anche come rigetto cronico). L’analisi dei risultati, per entrambi gli studi è prevista per il 2024. </p>
            <p>Un ulteriore studio, BOSTON-3, è attivo e finalizzato al reclutamento dei pazienti che hanno completato gli studi BOSTON-1 e BOSTON-2 per continuare il trattamento con il farmaco attivo ai fini della valutazione della sicurezza e dell’efficacia a lungo termine di L-CsA-i. </p>
            <p>Nel 2023 il team di Global Medical Affairs ha condotto il primo Advisory Board Europeo sulla BOS, che ha coinvolto 5 esperti in trapianto polmonare (provenienti da Germania, Belgio, Danimarca, Spagna e Italia) che hanno dato il loro contributo nel comprendere al meglio le sfide e le necessità di questa area terapeutica. Inoltre Zambon è stata presente in 2 congressi internazionali (ISHLT a Denver ed ERS a Milano), ha pubblicato 4 abstracts su dati epidemiologici e ha tenuto un evento educazionale durante il congresso ATS tenutosi a Washington DC, dove un esperto ha illustrato le caratteristiche principali della patologia e le principali strategie diagnostiche e terapeutiche. </p>
         </>
      )}
      {(string === 'at4Col2' && lang === 'ita') && (
        <>
           <h3>Bronchiectasie non da fibrosi cistica (NCFB)</h3>
           <p>Sulla scia dell’anno precedente, anche nel 2023 si sono compiuti importanti avanzamenti nel programma di ricerca PROMIS, quali la presentazione dei risultati cumulativi degli studi PROMIS-I e PROMIS II durante il congresso internazionale World Bronchiectasis Conference a New York. </p>
           <p>Il programma globale NCFB (PROMIS) si riferisce allo sviluppo e registrazione dell’antibiotico <em>colistimetato sodico</em> (CMS) da utilizzarsi nel trattamento delle esacerbazioni polmonari in pazienti affetti da bronchiettasie non correlate a Fibrosi Cistica (NCFB) colonizzati da <em>Pseudomonas aeruginosa</em> e somministrato per via inalatoria attraverso il dispositivo per nebulizzazione I-neb<sup>®️</sup> (CMS-I-neb<sup>®️</sup>). </p>
           <p>Zambon ha confermato il proprio impegno nell’area terapeutica delle Bronchiectasie partecipando al World Bronchiectasis Conference e supportando il World Bronchiectasis Day, istituito il giorno 1° luglio. Nel corso dell’anno, l’azienda ha continuato a lavorare sul Programma PROMIS svolgendo ulteriori analisi di approfondimento atte a chiarire il percorso regolatorio con l’FDA, con l’obiettivo di rendere disponibile nel minor tempo possibile il sistema integrato farmaco/device che include <em>colistimetato di sodio</em>, per pazienti adulti affetti da NCFB e infezione cronica da <em>Pseudomonas aeruginosa</em>.</p>
        </>
      )}


      {(string === 'at4Title' && lang === 'eng') && (
        <h2>SEVERE RESPIRATORY DISEASES</h2>
      )}
      {(string === 'at4Col1' && lang === 'eng') && (
         <>
            <p>In 2023, Zambon renewed its commitment to seeking out solutions capable of meeting the needs of patients, especially those suffering from serious illnesses including Serious Respiratory Diseases such as cystic fibrosis, Bronchiolitis Obliterans Syndrome (BOS) and Non-Cystic Fibrosis Bronchiectasis (NCFB).</p>
            <h3>Fibrosi Cistica</h3>
            <p>Zambon maintains its presence in the Cystic Fibrosis field with <em>colistimethate sodium</em> (Promixin<sup>®</sup>) for the treatment of chronic lung infections caused by <em>Pseudomonas aeruginosa</em>.</p>


            <h3>Bronchiolitis Obliterans Syndrome (BOS)</h3>
            <p>In 2023, patient enrolment concluded for the BOSTON-1 and BOSTON-2 phase III studies to evaluate liposomal cyclosporine A for inhalation (L-CsA-i) for the treatment of BOS following lung transplantation. BOS is the most common form of chronic lung allograft dysfunction (also known as chronic rejection). The analysis of the results of both studies is planned for 2024.</p>

            <p>An additional study, BOSTON-3, is active and aimed at recruiting patients who completed the BOSTON-1 and BOSTON-2 studies to continue treatment with the active drug for the evaluation of the long-term safety and efficacy of L-CsA-i.</p>
            <p>In 2023, the Global Medical Affairs team organised the first European Advisory Board on BOS, which involved 5 lung transplant experts (from Germany, Belgium, Denmark, Spain and Italy) who gave their input to allow for a better understanding of the challenges and needs of this therapeutic area. In addition, Zambon was present at 2 international conventions (ISHLT in Denver and ERS in Milan), published 4 abstracts on epidemiological data and held an educational event during the ATS conference held in Washington DC, where an expert illustrated the main features of the disease and the main diagnostic and therapeutic strategies.</p>
         </>
      )}
      {(string === 'at4Col2' && lang === 'eng') && (
        <>
           <h3>Non-Cystic Fibrosis Bronchiectasis (NCFB)</h3>
           <p>Following on from last year, once again in 2023 there was significant progress in the PROMIS research programme, such as the presentation of the cumulative results of the PROMIS-I and PROMIS-II studies at the international World Bronchiectasis Conference in New York.</p>
           <p>The Global NCFB Programme (PROMIS) relates to the development and registration of the antibiotic <em>colistimethate sodium</em> (CMS) for use in the treatment of pulmonary exacerbations in patients with Non-Cystic Fibrosis Bronchiectasis (NCFB) colonised by <em>Pseudomonas aeruginosa</em> and administered by inhalation through the I-neb<sup>®</sup> nebuliser device (CMS-I-neb<sup>®</sup>).</p>
           <p>Zambon confirmed its involvement in the Bronchiectasis therapeutic area by taking part in the World Bronchiectasis Conference and supporting the first World Bronchiectasis Day, established on 1 July. The company continued working on the PROMIS Programme throughout the year, performing additional detailed analyses aimed at clarifying the regulatory path with the FDA, with the goal of making the integrated drug/device system (which includes <em>colistimethate sodium</em>) available in the shortest time possible to adult patients affected by NCFB and chronic <em>Pseudomonas aeruginosa</em> infection.</p>
        </>
      )}

      {(string === 'at5Title' && lang === 'ita') && (
        <h2>Sistema urologico</h2>
      )}
      {(string === 'at5Col1' && lang === 'ita') && (
         <>
            <p>Le infezioni delle vie urinarie (IVU) sono piuttosto frequenti e colpiscono maggiormente la popolazione femminile; si stima, infatti, che una donna su due soffra di infezioni del tratto urinario almeno una volta nel corso della propria vita, con un’incidenza annuale dell’11% che aumenta con l’avanzare dell’età.</p>
            <p>Da oltre tre decenni, Zambon è in prima linea contro le infezioni alle vie urinarie con Monuril<sup>®️</sup> (fosfomicina tromentamolo), raccomandato come trattamento di prima scelta delle infezioni urinarie acute non complicate in singola dose dall’Associazione Europea di Urologia (EAU) e per la profilassi antibiotica negli uomini sottoposti a biopsia prostatica transrettale in 2 dosi (prima e dopo l’intervento). </p>
         </>
      )}
      {(string === 'at5Col2' && lang === 'ita') && (
        <>
           <p>Nel 2023 Monuril<sup>®️</sup> è stato protagonista di diverse attività scientifiche con un Advisory Board e con la pubblicazione di una review sull’utilizzo di fosfomicina trometamolo nel trattamento delle cistiti non complicate in gravidanza, mettendo in evidenza dati da studi clinici e linee guida internazionali e confermando ulteriormente la sicurezza d’uso in questo contesto. </p>
           <p>Nel 2023, le vendite di Monuril<sup>®️</sup> sono state pari a 95,7 milioni di euro, in linea con il 2022 (+0,4%).  Complessivamente l’area terapeutica ha registrato un fatturato di 122,8 milioni di euro, in aumento verso il 2022 (+2%).</p>
        </>
      )}



      {(string === 'at5Title' && lang === 'eng') && (
        <h2>Urological system</h2>
      )}
      {(string === 'at5Col1' && lang === 'eng') && (
         <>
            <p>Urinary tract infections (UTIs) are relatively frequent occurrence that largely affect the female population. Indeed, it is estimated that one in two women suffers from a urinary tract infection at least once in her lifetime, with an annual incidence of 11%, which increases with age<sup>1</sup>. </p>
            <p>For over three decades, Zambon has been on the front line in the fight against UTIs with Monuril<sup>®</sup> (fosfomycin trometamol), recommended as a single-dose drug of first choice for acute uncomplicated UTIs by the European Association of Urology (EAU) and as a two-dose treatment (pre- and post-procedure) for antibiotic prophylaxis in men undergoing a transrectal prostate biopsy <sup>1</sup>.</p>
         </>
      )}
      {(string === 'at5Col2' && lang === 'eng') && (
        <>
           <p>In 2023 Monuril<sup>®</sup> was involved in several scientific activities with an Advisory Board and with the publication of a review on the use of fosfomycin trometamol in the treatment of uncomplicated cystitis in pregnancy, highlighting data from clinical trials and international guidelines and further confirming its safety of use in this context.</p>
           <p>In 2023, sales of Monuril<sup>®</sup> amounted to €95.7 million, in line with 2022 (+0.4%).  Overall, the therapeutic area recorded turnover of €122.8 million, an increase over 2022.</p>
           <p><small>(1) Bonkat, G. et al. EAU Guidelines on Urological Infections 2022 </small></p>
        </>
      )}

    </>
  )
}
