import React from 'react'

export const GroupContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
       {(string === 'ilgruppo' && lang === 'ita') && (
        <>Il <strong>gruppo</strong></>
       )}
      {(string === 'innovatingCureCare') && (
        <>INNOVATING<br /><span className="green">CURE AND CARE</span><br />TO MAKE PATIENTS’<br />LIVES BETTER</>
      )}
      {(string === 'letteraZambon' && lang === 'ita') && (
        <>Questo nuovo Value Report racconta con passione il lavoro, le energie e le connessioni indispensabili per raggiungere un futuro chiaro e definito. Quel Sustainable Future che dà il nome a questo report, al nostro piano strategico e, non ultimo, al nostro impegno quotidiano. Quello per realizzare <strong>un futuro responsabile, sostenibile, ma soprattutto migliore</strong>, da cui le prossime generazioni possano coglierne il meglio per continuare a far sì che <strong>la salute di tutte e tutti resti sempre un traguardo di civiltà</strong>. </>
      )}
      {(string === 'readPresMessage' && lang === 'ita') && (
        <>Leggi il messaggio<br />del Presidente</>
      )}
      {(string === 'groupin2023' && lang === 'ita') && (
        <>Il gruppo nel 2023</>
      )}


      {(string === 'worldCollaborators' && lang === 'ita') && (
        <>Persone nel mondo</>
      )}
      {(string === 'women' && lang === 'ita') && (
        <>Donne</>
      )}
      {(string === 'men' && lang === 'ita') && (
        <>Uomini</>
      )}
      {(string === 'twwa' && lang === 'ita') && (
        <>The way we are</>
      )}
      {(string === 'iniziative' && lang === 'ita') && (
        <>Le iniziative</>
      )}



      {(string === 'benvivere') && (
        <>Benvivere</>
      )}
      {(string === 'conBenvivere' && lang === 'ita') && (
        <>Benvivere è pensato per coinvolgere tutte le persone di Zambon
        ed è strutturato in cinque aree di interesse che ogni anno si arricchiscono di nuove iniziative ed eventi.</>
      )}

      {(string === 'peopleAndCulture') && (
        <>People & Culture</>
      )}
      {(string === 'peopleCare' && lang === 'ita') && (
        <>People Care è uno dei nostri valori fondanti. Da sempre
        ci prendiamo cura delle persone che, oggi come nel passato,
        contribuiscono con il loro impegno e le loro idee allo sviluppo di Zambon,
        accogliendo la ricchezza della diversità.</>
      )}

      {(string === 'museoZambon' && lang === 'ita') && (
        <>Museo Zambon</>
      )}







      {(string === 'ilgruppo' && lang === 'eng') && (
      <>The <strong>group</strong></>
      )}

     {(string === 'letteraZambon' && lang === 'eng') && (
      <>This new Value Report passionately recounts the work, energy and connections needed to achieve a clear and defined future. That Sustainable Future that gives its name to this report, to our strategic plan and, not least, to our daily commitment. The one in which we achieve <strong>a responsible, sustainable, but above all better future</strong>, which the next generations can make the best of in order to continue to ensure that <strong>the health of all will always remain a goal of civilisation</strong>.</>
     )}
     {(string === 'readPresMessage' && lang === 'eng') && (
      <>Read the message</>
     )}
     {(string === 'groupin2023' && lang === 'eng') && (
      <>The group in 2023</>
     )}


     {(string === 'worldCollaborators' && lang === 'eng') && (
      <>People</>
     )}
     {(string === 'women' && lang === 'eng') && (
      <>Women</>
     )}
     {(string === 'men' && lang === 'eng') && (
      <>Men</>
     )}
     {(string === 'twwa' && lang === 'eng') && (
      <>The way we are</>
     )}
     {(string === 'iniziative' && lang === 'eng') && (
      <>the initiatives</>
     )}



     {(string === 'conBenvivere' && lang === 'eng') && (
      <>Benvivere is designed to engage all of Zambon’s people and is structured into five areas of interest that are enhanced every year with new initiatives and events.</>
     )}

     {(string === 'peopleCare' && lang === 'eng') && (
      <>People Care is one of our foundational values. We have always taken care of
      the people who now, just like in the past, contribute through their commitment
      and their ideas to the development of Zambon, embracing the wealth that comes
      with diversity.</>
     )}

     {(string === 'museoZambon' && lang === 'eng') && (
      <>Zambon Museum</>
     )}

    </>
  )
}
