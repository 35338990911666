import React from 'react'

export const OpenzoneContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'creiamoValore' && lang === 'ita') && (
        <><span>Creiamo valore</span><br />
        in modo unico <br />
        nel settore della salute, <br />
        promuovendo <br />
        una <span>community dinamica</span><br />
        e favorendo l'accesso <br />
        a competenze e capitale</>
      )}

      {(string === 'openzoneNasce' && lang === 'ita') && (
        <>“OpenZone nasce per creare ponti, per favorire lo scambio di conoscenze e lo sviluppo di collaborazioni nel settore della salute, con la convinzione che solo una community forte e aperta possa dare slancio e favorire il progresso scientifico. Siamo fermamente determinati a coltivare non solo l'imprenditorialità, ma anche un senso di comunità e condivisione. Insieme, continueremo a sostenere e ispirare le aziende che hanno scelto di lavorare nel nostro Campus, trasformando le sfide in opportunità e avendo un impatto positivo per noi, per i nostri zoner e per le persone che rappresentano la nostra community al di fuori del campus.”</>
      )}

      {(string === 'andrea') && (
        <>Andrea D’Alessandro</>
      )}
      {(string === 'presidente' && lang === 'ita') && (
        <>Presidente Esecutivo di OpenZone</>
      )}

      {(string === 'alessio') && (
        <>Alessio Pinciroli</>
      )}
      {(string === 'generalManager' && lang === 'ita') && (
        <>General Manager di OpenZone</>
      )}

      {(string === 'leTorri' && lang === 'ita') && (
        <>Le nuove torri</>
      )}
      {(string === 'torriTesto' && lang === 'ita') && (
        <>Nel 2023 hanno preso vita le nuove torri aprendo le loro porte ai ricercatori e ai professionisti di due importanti zoner quali Axxam e DiaSorin.  Un passo rilevante e parte integrante dell’ambizioso piano di sviluppo avviato nel 2018, che ha consolidato il posizionamento del campus come punto di riferimento nel mondo della salute e delle scienze della vita.</>
      )}
      {(string === 'creditsFoto' && lang === 'ita') && (
        <>©credits ph. Rasmus Hjortshøj, courtesy of AMDL CIRCLE</>
      )}

      {(string === 'openCafe' && lang === 'ita') && (
        <>Il nuovo Open Cafe</>
      )}
      {(string === 'openCafeTesto' && lang === 'ita') && (
        <>OpenZone ha inaugurato nel 2023 il nuovo Open Cafe. Questo nuovo spazio, situato all'interno delle nostre nuove torri, rappresenta un ulteriore punto di incontro pensato per favorire lo scambio e la condivisione di nuove idee o semplicemente per godersi qualche minuto di tranquillità. Open Cafe sarà un luogo aperto a tutti, dove la nostra comunità potrà riunirsi e godersi momenti di convivialità.</>
      )}

      {(string === 'zoner') && (
        <>Zoner</>
      )}

      {(string === 'certificazioneBcorp' && lang === 'ita') && (
        <>Certificazione B corp</>
      )}
      {(string === 'certificazioneBcorpTesto' && lang === 'ita') && (
        <>OpenZone, come Società Benefit, non si limita alla semplice ricerca e relativa resa conto delle finalità di beneficio comune, ma si impegna anche a contribuire al benessere della comunità e alla conservazione dell'ambiente circostante, consapevole dell'importanza di adottare pratiche sostenibili per un futuro migliore. Per questo motivo, nel 2023, OpenZone ha ottenuto la certificazione ufficiale come B Corp, dopo aver superato un rigoroso assessment condotto da B Lab.</>
      )}
      {(string === 'leggiRelazione' && lang === 'ita') && (
        <>Leggi<br />la relazione d’impatto</>
      )}

      {(string === 'community' && lang === 'ita') && (
        <>Una community<br />che crea un impatto positivo</>
      )}
      {(string === 'communityTesto' && lang === 'ita') && (
        <>Anche nel 2023 OpenZone ha portato avanti la convinzione che solo una community forte e aperta possa dare slancio e favorire il progresso scientifico perché quando l’impresa è sana e gestita secondo principi etici, crea benessere sociale in ogni sua attività.</>
      )}

      {(string === 'progettoMyVoice' && lang === 'ita') && (
        <>Progetto #MyVoice</>
      )}
      {(string === 'progettoMyVoiceTesto' && lang === 'ita') && (
        <>All’interno di OpenZone e grazie alla collaborazione con Zambon e AISLA ONLUS è stato portato il progetto #MyVoice con l'obiettivo principale di offrire un nuovo mezzo di comunicazione alle persone affette da SLA (Sclerosi Laterale Amiotrofica). Per l’occasione, gli zoner hanno donato la propria voce a persone affette da SLA. 125 voci per un totale di 15.500 parole che hanno incrementato la “banca della voce" messa a disposizione dei pazienti.</>
      )}

      {(string === 'donazioneSangue' && lang === 'ita') && (
        <>Donazione di sangue in Openzone</>
      )}
      {(string === 'donazioneSangueTesto' && lang === 'ita') && (
        <>Grazie alla preziosa collaborazione tra OpenZone e AVIS Milano è stata organizzata una giornata di donazioni di sangue direttamente all’interno del campus. L'iniziativa è stata aperta a tutti - ex donatori e nuovi candidati - al fine di permettere a chiunque di compiere questo gesto di straordinaria condivisione e generosità.</>
      )}

      {(string === 'openIconText1' && lang === 'ita') && (
         <>
            <h5><strong>37.400</strong> <span>m<sup>2</sup></span></h5>
            <h6>dimensione<br />complessiva</h6>
         </>
      )}
      {(string === 'openIconText2' && lang === 'ita') && (
         <>
            <h5><strong>1.200</strong></h5>
            <h6>persone</h6>
         </>
      )}
      {(string === 'openIconText3' && lang === 'ita') && (
         <>
            <h5><strong>79</strong> <span>mio €</span></h5>
            <h6>investimenti<br />complessivi</h6>
         </>
      )}
      {(string === 'openIconText4' && lang === 'ita') && (
         <>
            <h5><strong>34</strong></h5>
            <h6>imprese</h6>
         </>
      )}
      {(string === 'openIconText5' && lang === 'ita') && (
         <>
            <h5><strong>20.400</strong> <span>m<sup>2</sup></span></h5>
            <h6>di uffici</h6>
         </>
      )}
      {(string === 'openIconText6' && lang === 'ita') && (
         <>
         <h5><strong>17.000</strong> <span>m<sup>2</sup></span></h5>
         <h6>di laboratori</h6>
         </>
      )}





      {(string === 'creiamoValore' && lang === 'eng') && (
        <><span>We uniquely create</span><br />
        value in Healthcare,<br />
        by <span>fostering community,</span><br />
        competencies and capital.</>
      )}

      {(string === 'openzoneNasce' && lang === 'eng') && (
        <>“OpenZone was established to build bridges, and to facilitate knowledge exchange and the development of partnerships in the health sector, with the firm belief that only a community that is strong and open can create momentum and foster scientific progress. We are steadfastly determined to cultivate not only entrepreneurship, but also a sense of community and sharing. Together, we will continue to support and inspire the companies that have chosen to work on our campus, transforming challenges into opportunities and making a positive impact for ourselves, for our zoners and for the people who represent our community outside the campus.”</>
      )}

      {(string === 'presidente' && lang === 'eng') && (
        <>Executive President of OpenZone</>
      )}

      {(string === 'generalManager' && lang === 'eng') && (
        <>General Manager of OpenZone</>
      )}

      {(string === 'leTorri' && lang === 'eng') && (
        <>The New Towers</>
      )}
      {(string === 'torriTesto' && lang === 'eng') && (
        <>In 2023, the new towers came to life, opening their doors to researchers and professionals from two important zoners such as Axxam and DiaSorin. An important step and an integral part of the ambitious development plan launched in 2018, which has consolidated the positioning of the campus as a benchmark in the world of health and life sciences.</>
      )}
      {(string === 'creditsFoto' && lang === 'eng') && (
        <>©credits ph. Rasmus Hjortshøj, courtesy of AMDL CIRCLE</>
      )}

      {(string === 'openCafe' && lang === 'eng') && (
        <>The new Open Cafe</>
      )}
      {(string === 'openCafeTesto' && lang === 'eng') && (
        <>OpenZone opened the new Open Cafe in 2023. This new space, located in our new towers, represents an additional meeting point designed to encourage the exchange and sharing of new ideas or simply to enjoy a few minutes of relaxation. Open Cafe will be a place open to all, where our community can meet and enjoy moments of togetherness.</>
      )}

      {(string === 'certificazioneBcorp' && lang === 'eng') && (
        <>B Corp Certification</>
      )}
      {(string === 'certificazioneBcorpTesto' && lang === 'eng') && (
        <>OpenZone does not merely perform research and report on common benefit purposes, but is also committed to contributing to the well-being of the community and the preservation of the environment, with an awareness of the importance of adopting sustainable practices for a better future. This is why OpenZone was officially certified as a B Corp in 2023, after passing a rigorous assessment conducted by B Lab.</>
      )}
      {(string === 'leggiRelazione' && lang === 'eng') && (
        <>Read the OpenZone Impact Report</>
      )}

      {(string === 'community' && lang === 'eng') && (
        <>A community<br />that creates a positive impact</>
      )}
      {(string === 'communityTesto' && lang === 'eng') && (
        <>Also in 2023, OpenZone moved forward with its conviction that only a strong and open community can provide momentum and foster scientific progress, because when the business is sound and managed ethically, it creates social well-being across all of its activities.</>
      )}

      {(string === 'progettoMyVoice' && lang === 'eng') && (
        <>#MyVoice Project</>
      )}
      {(string === 'progettoMyVoiceTesto' && lang === 'eng') && (
        <>Within OpenZone and thanks to the collaboration with Zambon and AISLA ONLUS, the #MyVoice project was brought to life with the main objective of offering a new means of communication to people suffering from ALS (Amyotrophic Lateral Sclerosis). On this occasion, the zoners gave their voices to people suffering from ALS. 125 voices for a total of 15,500 words, which increased the “voice bank” made available to patients.</>
      )}

      {(string === 'donazioneSangue' && lang === 'eng') && (
        <>Blood donation at OpenZone</>
      )}
      {(string === 'donazioneSangueTesto' && lang === 'eng') && (
        <>Thanks to the valuable collaboration between OpenZone and the AVIS blood donation centre in Milan, a blood donation day was organised right on the campus. The initiative was open to all - former donors and new candidates alike - in order to allow anyone to make this gesture of extraordinary sharing and generosity.</>
      )}

      {(string === 'openIconText1' && lang === 'eng') && (
         <>
            <h5><strong>37,400</strong> <span>m<sup>2</sup></span></h5>
            <h6>total surface</h6>
         </>
      )}
      {(string === 'openIconText2' && lang === 'eng') && (
         <>
            <h5><strong>1,200</strong></h5>
            <h6>people</h6>
         </>
      )}
      {(string === 'openIconText3' && lang === 'eng') && (
         <>
            <h5><strong>79</strong> <span>mio €</span></h5>
            <h6>total investments</h6>
         </>
      )}
      {(string === 'openIconText4' && lang === 'eng') && (
         <>
            <h5><strong>34</strong></h5>
            <h6>companies</h6>
         </>
      )}
      {(string === 'openIconText5' && lang === 'eng') && (
         <>
            <h5><strong>20,400</strong> <span>m<sup>2</sup></span></h5>
            <h6>of office space</h6>
         </>
      )}
      {(string === 'openIconText6' && lang === 'eng') && (
         <>
         <h5><strong>17,000</strong> <span>m<sup>2</sup></span></h5>
         <h6>of laboratories</h6>
         </>
      )}

    </>
  )
}
