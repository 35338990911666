import React from 'react';

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { ItaliassistenzaContent } from "./content/ItaliassistenzaContent"
import { LogosContent } from "./content/LogosContent"

import mySvg from '../assets/svg/bg.svg';

import datoItaliassistenza from '../assets/images/datoItaliassistenza.png';
import datoItaliassistenzaEN from '../assets/images/datoItaliassistenza-eng.png';

import care from '../assets/images/loghi-care.png';

const Group = (props) => {

   return (
      <div className="internalPage itaPage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
            <SvgContent stringToRet="tube" />

            <div className="pagerow pagerow_head">

               <div className="row">
                <div className="col-6">
                   <div className="plat">
                      <div className="ball"></div>
                      <SvgContent stringToRet="obj7" />
                      <SvgContent stringToRet="piattaforma" />
                   </div>
                </div>
                <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                    <div className="arrow">
                      <SvgContent stringToRet="arrow" />
                    </div>
                    <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="row title">
                     <div className="col-12 col-md-6 logo-wrapper">
                        <LogosContent stringToRet="italiassistenza" />
                     </div>
                     <div className="col-12 col-md-6 logo-wrapper">
                        <LogosContent stringToRet="careapt" />
                     </div>
                  </div>
                </div>
               </div>
            </div>


            <div className="pagerow">
               <SvgContent stringToRet="cuo" />
               <div className="row">
                  <div className="col-12">
                     <h2><ItaliassistenzaContent lang={props.lang} stringToRet="italiassistenza" /></h2>
                  </div>

                  <div className="col-12 col-md-6 pr-md-15">
                     {(props.lang === 'ita'? <img src={datoItaliassistenza} alt="" /> : <img src={datoItaliassistenzaEN} alt="" />)}
                  </div>
                  <div className="col-12 col-md-6 pl-md-15 mt-0-md mt-4 col-benvPeopleContent fdc">

                     <p className="bgrounded"><ItaliassistenzaContent lang={props.lang} stringToRet="hcc" /></p>

                     <button className="cta ctaEye mt-3" onClick={(e) => props.onToggleExtra(e,'italia')}>
                        <SvgContent stringToRet="eye" />
                        <div className="content">
                           <span><ItaliassistenzaContent lang={props.lang} stringToRet="il2023" /></span>
                           <span className="light"><ItaliassistenzaContent lang={props.lang} stringToRet="diItaliassistenza" /></span>
                           <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                        </div>
                     </button>

                  </div>
               </div>
            </div>

      <div className="pagerow">
       <SvgContent stringToRet="dnaw" />
        <div className="row">
           <div className="col-12 col-lg-6 pr-lg-15">
                 <div className="dis-flex">
                     <LogosContent stringToRet="hac" />
                  </div>
                  <div className="col-benvPeopleContent mt-3 pr-md-15">
                     <p className="bgrounded bgralone"><ItaliassistenzaContent lang={props.lang} stringToRet="hac" /></p>
                  </div>
                  <div className="mt-3">
                     <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'hac')}>
                       <SvgContent stringToRet="eye" />
                       <div className="content">
                        <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                        <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                       </div>
                     </button>

                     <div className="row mt-5">
                        <div className="col-12 col-sm-6 pr-sm-5">
                           <div className="icon_num3">
                              <SvgContent stringToRet="itaHand" />
                              <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock1" />
                           </div>
                        </div>
                        <div className="col-12 col-sm-6 pl-sm-5">
                           <div className="icon_num3">
                              <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock2" />
                           </div>
                        </div>
                        <div className="col-12 col-sm-6 pr-sm-5">
                           <div className="icon_num3">
                              <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock3" />
                           </div>
                        </div>
                        <div className="col-12 col-sm-6 pl-sm-5">
                           <div className="icon_num3">
                              <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock4" />
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
            <div className="col-12 col-lg-6 pl-lg-15 mt-0-lg mt-4 pl-md-15">
               <div className="dis-flex">
                   <LogosContent stringToRet="pa" />
                </div>
                <div className="col-benvPeopleContent mt-3">
                   <p className="bgrounded bgralone"><ItaliassistenzaContent lang={props.lang} stringToRet="pa" /></p>
                </div>
                <div className="mt-3">
                  <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'private')}>
                    <SvgContent stringToRet="eye" />
                    <div className="content">
                     <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                     <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                    </div>
                  </button>
                  <div className="row mt-5">
                     <div className="col-12 col-sm-6 pr-sm-5">
                        <div className="icon_num3">
                           <SvgContent stringToRet="itaHand" />
                           <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock5" />
                        </div>
                     </div>
                     <div className="col-12 col-sm-6 pl-sm-5">
                        <div className="icon_num3">
                           <SvgContent stringToRet="itaHouse" />
                           <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock6" />
                        </div>
                     </div>
                     <div className="col-12 col-sm-6 pr-sm-5">
                        <div className="icon_num3">
                           <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock7" />
                        </div>
                     </div>
                     <div className="col-12 col-sm-6 pl-sm-5">
                        <div className="icon_num3">
                           <ItaliassistenzaContent lang={props.lang} stringToRet="itaBlock8" />
                        </div>
                     </div>
                  </div>
                </div>
             </div>
         </div>
      </div>

      <div className="pagerow">
        <SvgContent stringToRet="lente" />

        <div className="row jcc">
          <div className="col-12">
            <h2 className="care"><ItaliassistenzaContent lang={props.lang} stringToRet="ca" /></h2>
          </div>

           <div className="col-12 col-sm-10 col-md-8 col-lg-6">
             <img className="areeTer" src={care} alt="area 1" />
           </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-sm-10 col-lg-8 col-benvPeopleContent">
            <p className="bgrounded bg2"><ItaliassistenzaContent lang={props.lang} stringToRet="caContent" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'careapt')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>

          </div>
        </div>
      </div>

      <div className="pagerow">
        <SvgContent stringToRet="pro" />

        <div className="row jcc">

          <div className="col-12 col-sm-10 col-lg-8">
            <h3 className="care"><ItaliassistenzaContent lang={props.lang} stringToRet="webinar" /></h3>
          </div>
       </div>
       <div className="row jcc">
          <div className="col-12 col-sm-10 col-lg-8 col-benvPeopleContent">
            <p className="bgrounded bg2"><ItaliassistenzaContent lang={props.lang} stringToRet="webinarContent" /></p>

            <a className="cta ctaEye" href="https://www.youtube.com/channel/UCuU5F1V4IedycRQeQhii1ig" target="_blank" rel="noreferrer">
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><ItaliassistenzaContent lang={props.lang} stringToRet="youtube" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </a>
          </div>
        </div>
      </div>


    </div>
  );
}
export default Group;
