import React from 'react'

export const ZcubeContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'modernHealthSolutions') && (
        <>MODERN <span>HEALTH</span><br />SOLUTIONS</>
      )}
      {(string === 'zcubeProsegue' && lang === 'ita') && (
        <>Zcube prosegue il proprio viaggio alla ricerca di soluzioni moderne che rendano migliore la vita dei pazienti, affiancando alle terapie tradizionali nuove soluzioni basate sull’utilizzo di tecnologie digitali.</>
      )}

      {(string === 'corporateVenture') && (
        <>Corporate Venture</>
      )}

      {(string === 'cvnel2023' && lang === 'ita') && (
        <>Nel 2023 è proseguita l’attività di gestione degli investimenti in fondi nazionali e internazionali.</>
      )}

      {(string === 'openAccelerator') && (
        <>Open Accelerator</>
      )}
      {(string === 'openAcceleratorProgramma' && lang === 'ita') && (
        <>Open Accelerator è il programma internazionale di accelerazione per start-up nelle Scienze della Vita nato nel 2016.
        <br /><br />
        Nel 2023 è stato deliberato un ulteriore investimento di 100.000 euro nella startup canadese Hyivy Health, che si era già aggiudicata il Distinction Award nell’edizione 2020 di Open Accelerator.</>
      )}

      {(string === 'iniziative' && lang === 'ita') && (
        <>Le iniziative</>
      )}

      {(string === 'callForWomen') && (
        <>Open Accelerator 2024:<br />Call for women’s health innovations</>
      )}
      {(string === 'callForWomenTesto' && lang === 'ita') && (
        <>Al termine del 2023 le attività sono state dedicate alla preparazione della call 2024 di Open Accelerator.  <br /><br />
        La quinta edizione di Open Accelerator, il programma di accelerazione internazionale per la crescita di startup ad alto potenziale innovativo nel campo delle scienze della vita, dal 2024 si concentrerà sul settore femtech e salute della donna.</>
      )}

      {(string === 'vita') && (
        <>Vita</>
      )}

      {(string === 'innovationPartner' && lang === 'ita') && (
        <>Nel 2023 Zcube ha aderito in qualità di unico Innovation Partner a VITA Accelerator.</>
      )}




      {(string === 'zcubeProsegue' && lang === 'eng') && (
        <>Zcube continues its journey to seek out modern solutions that improve patients’ lives, employing new solutions based on digital technologies alongside traditional therapies.</>
      )}

      {(string === 'cvnel2023' && lang === 'eng') && (
        <>In 2023, it continued managing investments in national and international funds.</>
      )}

      {(string === 'openAcceleratorProgramma' && lang === 'eng') && (
        <>Open Accelerator is the international accelerator program dedicated to start-ups in the Life Sciences sector, set up in 2016.
        <br /><br />
        In 2023, an additional investment of €100,000 was approved in the Canadian start-up Hyivy Health, which had won the Distinction Award in the 2020 edition of Open Accelerator.</>
      )}

      {(string === 'iniziative' && lang === 'eng') && (
        <></>
      )}

      {(string === 'callForWomenTesto' && lang === 'eng') && (
        <>At the end of 2023, activities were devoted to the preparation of the 2024 Open Accelerator call.<br /><br />
        The fifth edition of Open Accelerator, the international accelerator programme for the growth of start-ups with high innovation potential in the life sciences, will focus on the FemTech and women's health sector starting in 2024.</>
      )}

      {(string === 'innovationPartner' && lang === 'eng') && (
        <>In 2023, Zcube joined VITA Accelerator as the only Innovation Partner.</>
      )}






    </>
  )
}
