import React from 'react';
import useDetectScroll from "@smakss/react-scroll-direction";

import { SvgContent } from "./content/SvgContent";
import { HomeContent } from "./content/HomeContent"

import "../assets/scss/Home.scss";

import sustainable from '../assets/images/sustainable.gif';
// import sustainable from '../assets/images/sustainable.png';
import future from '../assets/images/future.gif';
// import future from '../assets/images/future.png';

import obj2 from '../assets/images/obj2.png'; // PHARMA OBJECT (PNG - OTHERS SVG)
import mySvg from '../assets/svg/bg.svg'; // PATTERN ON BG


const Home = (props) => {
   const { scrollDir } = useDetectScroll();

   let stepsDesktop = [
     [[3,8,0],[7,6,0],[3,8,20],[7,6,20],[3,8,40],[7,6,40],[3,8,60],[7,6,60]], // 0
     [[1,6,0],[3,5,0],[1,6,20],[3,5,20],[1,6,40],[3,5,40],[1,6,60],[3,5,60]], // 1
     [[1,6,0],[3,5,0],[1,6,20],[3,5,20],[1,6,40],[3,5,40],[1,6,60],[3,5,60]], // 2  *** CHANGE
     [[1,3,0],[3,5,0],[1,6,20],[3,5,20],[1,6,40],[3,5,40],[1,6,60],[3,5,60]], // 3
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[1,6,40],[3,8,40],[1,6,60],[3,8,60]], // 4
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[1,6,40],[3,8,40],[1,6,60],[3,8,60]], // 5  *** CHANGE
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[3,8,40],[5,6,60],[3,8,60]], // 6
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[3,8,40],[5,6,60],[3,8,60]], // 7  *** CHANGE
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[5,6,60],[7,8,60]], // 8
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[5,6,60],[7,8,60]], // 9  *** CHANGE
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[7,4,60],[7,8,60]], // 10
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[7,4,60],[7,8,60]], // 11 *** CHANGE
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[7,4,60],[9,6,60]], // 12
     [[1,3,0],[3,5,0],[1,6,20],[3,8,20],[5,6,40],[7,8,40],[7,4,60],[9,6,60]]  // 13 *** LAST POS DUPLICATA PER ELIMINARE LA TRANSITION A ULTIMA PIATTAFORMA
   ];

   let stepsSmart = [
      [[2.5,3,0],[7.5,2.3,0],[2.5,3,20],[7.5,2.3,20],[2.5,3,40],[7.5,2.3,40],[2.5,3,60],[7.5,2.3,60]], // 0
      [[3,4,0],[7,3,0],[3,4,20],[7,3,20],[3,4,40],[7,3,40],[3,4,60],[7,3,60]], // 1
      [[3,4,0],[7,3,0],[3,4,20],[7,3,20],[3,4,40],[7,3,40],[3,4,60],[7,3,60]], // 2  *** CHANGE
      [[3,2,0],[7,3,0],[3,4,20],[7,3,20],[3,4,40],[7,3,40],[3,4,60],[7,3,60]], // 3
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,4,40],[7,5,40],[3,4,60],[7,5,60]], // 4
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,4,40],[7,5,40],[3,4,60],[7,5,60]], // 5  *** CHANGE
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,5,40],[3,6,40],[7,5,60]], // 6
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,5,40],[3,6,40],[7,5,60]], // 7  *** CHANGE
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,6,40],[7,7,60]], // 8
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,6,40],[7,7,60]], // 9  *** CHANGE
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,8,40],[7,7,60]], // 10
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,8,40],[7,7,60]], // 11 *** CHANGE
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,8,40],[7,9,60]], // 12
      [[3,2,0],[7,3,0],[3,4,20],[7,5,20],[3,6,20],[7,7,40],[3,8,40],[7,9,60]]  // 13 *** LAST POS DUPLICATA PER ELIMINARE LA TRANSITION A ULTIMA PIATTAFORMA
   ];

   // let steps = stepsSmart;
   let [visible, setVisible] = React.useState("hide");
   let [steps, setSteps] = React.useState(null);
   let [step, setStep] = React.useState(0);
   let [coordinate, setCoordinate] = React.useState(false);
   let [isSmartphone, setIsSmartphone] = React.useState();


   let [styleR, setStyleR] = React.useState([{},{},{},{},{},{},{}]) // rectagles styles
   let [styleP, setStyleP] = React.useState([{},{},{},{},{},{},{},{}]) // platforms styles
   let [classP, setClassP] = React.useState([{},{},{},{},{},{},{},{}]) // platforms classes - usato per determinare quando l'oggetto diventa visibile

   const screen = React.useRef(null);

   const easeMovRect = 'ease-out';
   const easeMovPlat = 'ease-in-out';
   const timeAnimOpacity = '.9'; // 1
   const timeTransPlat = '.7';
   const timeTransSmall = '.7'; // .4
   const timeTransBig = '.7';  // .5 // + grande per far vedere l'animazione dopo che l'opacità è tornata 1
   const milltimeOut = 600;  // 300

   function handleTimeOut() {
      if (step < 13) {
         setStep(step + 1); // 13 max
      }
      else { props.onAnimated() }
   }

   React.useEffect(() => {
      if(steps){
         changeCoordinate();
      }
   }, [step])

   const changeCoordinate = () => {
      console.log("props.animated ", props.animated)
      if(props.animated){
         setStep(13);
         setCoordinate(steps[13]);
      }
      else if(step === 0){
         setCoordinate(steps[step]);
      }
      else if(step !== 0) {
         setCoordinate(steps[step]);

         let timeoutId;
         let tl;
         /* SE MI TROVO IN UNO STEP DI CAMBIO PASSO VELOCEMENTE ALLO STEP SUCCESSIVO */
         if (step === 2 || step === 5 || step === 7 || step === 9 || step === 11) tl = 10;
         else if (step >= 1) tl = milltimeOut;

         timeoutId = setTimeout(() => handleTimeOut(), tl);
         return () => {
            clearTimeout(timeoutId);
         }
      }
   }

   React.useEffect(() => {
      if(steps){
         setCoordinate(steps[step]);
         changeCoordinate();
      }
   }, [steps])

   React.useEffect(() => {
      window.addEventListener("resize", handleResize);
      const timer = setTimeout(() => {
        handleResize();
      }, 1000);
      return () => {
         clearTimeout(timer);
         window.removeEventListener("resize", handleResize);
      }
   })

   const handleResize = (e) => {
      // setIsSmartphone(getWindowSize());

      let smar = getWindowSize();

      console.log(smar, " - ", isSmartphone);
      if (isSmartphone !== smar){
         setIsSmartphone(smar);
         if(smar) {setSteps(stepsSmart);}
         else {setSteps(stepsDesktop);}
         setVisible("hide");
         setTimeout(() => {
          setVisible("show");
       }, 1000);
      }

      // let ns = step === 0 ? step : step - 1;
      // setStep(step);
      setTimeout(() => {
       refreshPositions();
      }, 200);

   }


   function getWindowSize() {
     const {innerWidth} = window;
     let isSmart = (innerWidth <= 767) ? true : false;
     // isSmart ? steps = stepsSmart : steps = stepsDesktop;
     return isSmart;
   }

   const handleClickScroll = (e) => {
      setStep(1);
      window.scrollBy({top:100, behavior: "smooth"});
   }

   React.useEffect(() => {
      if(scrollDir === "down" && step === 0){
         setStep(1);
      }
   }, [scrollDir])

   const refreshPositions = () => {
      if (!coordinate || screen.current === null) return;
      let disX = [];
      let disY = [];
      let ipo = [];
      let ang = [];
      let startY = [];
      let startX = [];
      let updstyleR = [];
      let updstyleP = [];
      let updateclassP = [];

      let rectT = 30; // 30

      let timeTrans;
      let rotTrans;
      let animTrans;
      let opacTrans;
      let widthTrans;

      for (let i = 0; i < 7; i++){
         timeTrans = timeTransSmall;
         disX[i] = screen.current.clientWidth/10 * (coordinate[i][0] - coordinate[i+1][0]);
         disY[i] = (screen.current.clientHeight/10 * coordinate[i][1] + coordinate[i][2]) -
                   (screen.current.clientHeight/10 * coordinate[i+1][1] + coordinate[i+1][2]);
         ipo[i] = Math.sqrt((disX[i] * disX[i]) + (disY[i] * disY[i]));
         ang[i] = Math.atan(disY[i] / disX[i]) * 180 / Math.PI;

         if (i % 2 === 0) startY[i] = coordinate[i][1]*10;
         else startY[i] = coordinate[i+1][1]*10;

         startX[i] = Math.min(coordinate[i][0]*10, coordinate[i+1][0]*10);

         if(step === 0){
            updstyleR[i] = {
            transform: 'rotate(' + ang[i] + 'deg)',
            width: ipo[i],
            left: 'calc('+startX[i]+'%',
            top: 'calc('+startY[i]+'% + ' + Math.max(coordinate[i][2], coordinate[i+1][2]) + 'px - '+ rectT+'px)',
            };
         }

         else if(step === 1){
            timeTrans = step === 1 ? timeTransSmall : '0';
            opacTrans = i === 1 ? 1 : 0;

            updstyleR[i] = {
            transform: 'rotate('+ang[i]+'deg)',
            width: ipo[i],
            left: startX[i]+'%',
            top: 'calc('+startY[i]+'% + ' + Math.max(coordinate[i][2], coordinate[i+1][2]) + 'px - ' + rectT + 'px)',
            transition:'all ' + timeTrans + 's ' + easeMovPlat,
            opacity: opacTrans,
            };
         }

         else if (step >= 2 && i === 0){
            startY[i] = step < 2 ? coordinate[i][1]*10 : coordinate[i+1][1]*10;
            startX[i] = step < 2 ? coordinate[i][0]*10 : coordinate[i+1][0]*10;

            timeTrans = step === 3 ? timeTransSmall : '0';  // timeTransSmall
            rotTrans = step < 2 ? ang[i] : ang[i]-180;
            animTrans = step === 3 ? 'opop' : 'none';
            opacTrans = step === 2 ? 0 : 1;

            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity/3 + 's',
               transition: 'all ' + timeTrans + 's ' + easeMovRect,
               transform: 'rotate('+rotTrans+'deg)',
               width: ipo[i],
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
         }

         else if(step >= 1 && i === 1){
            timeTrans = step === 1 ? timeTransSmall : '0';

            updstyleR[i] = {
               transform: 'rotate('+ang[i]+'deg)',
               width: ipo[i],
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + Math.max(coordinate[i][2], coordinate[i+1][2]) + 'px - ' + rectT + 'px)',
               transition:'all ' + timeTrans + 's ' + easeMovPlat,
            };
         }

         else if(step >= 3 && i === 2){
            timeTrans = step === 4 ? timeTransSmall : '0';
            rotTrans = ang[i];
            animTrans = step === 4 ? 'opop' : 'none';
            opacTrans = step === 3 ? 0 : 1;


            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity/3 + 's',
               transition: 'all ' + timeTrans + 's ' + easeMovRect,
               transform: 'rotate('+rotTrans+'deg)',
               width: ipo[i],
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
         }

         else if (step >= 5 && i === 3){
            if(isSmartphone){
               startY[i] = coordinate[i][1]*10;
               startX[i] = coordinate[i][0]*10;

               timeTrans = step === 6 ? timeTransBig : '0';
               rotTrans = ang[i]-180;
               animTrans = step === 6 ? 'opop' : 'none';
               opacTrans = step === 5 ? 0 : 1;
               widthTrans = step === 5 ? disY[i] : ipo[i];
            }
            else{
               startY[i] = coordinate[i][1]*10;
               startX[i] = coordinate[i][0]*10;

               timeTrans = step === 6 ? timeTransBig : '0';
               rotTrans = step === 5 ? -90 : ang[i];
               animTrans = step === 6 ? 'opop' : 'none';
               opacTrans = step === 5 ? 1 : 1;
               widthTrans = step === 5 ? 0 : ipo[i];
            }

            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity + 's',
               transition: 'transform ' + timeTrans + 's ' + easeMovRect + ', width ' + timeTrans/3*5 + 's',
               transform: 'rotate('+rotTrans+'deg)',
               width: widthTrans,
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
         }

         else if (step >= 7 && i === 4) {
            if(isSmartphone){
               timeTrans = step === 8 ? timeTransSmall : '0';
               rotTrans = ang[i];
               animTrans = step === 8 ? 'opop' : 'none';
               opacTrans = step === 7 ? 0 : 1;
            }
            else{
               startY[i] = coordinate[i][1]*10;
               startX[i] = coordinate[i][0]*10;

               timeTrans = step === 8 ? timeTransBig : '0';

               rotTrans = step === 7 ? ang[i]+90 : ang[i];
               animTrans = step === 8 ? 'opop' : 'none';
               opacTrans = step === 7 ? 0 : 1;
               widthTrans = step === 7 ? 0 : ipo[i];
            }

            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity + 's',
               transition: 'all ' + timeTrans + 's ' + easeMovRect,
               transform: 'rotate('+rotTrans+'deg)',
               width: widthTrans,
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
         }

         else if (step >= 9 && i === 5) {
            startY[i] = coordinate[i][1]*10;
            startX[i] = coordinate[i][0]*10;

            timeTrans = step === 10 ? timeTransBig : '0';
            rotTrans = ang[i]-180;
            animTrans = step === 10 ? 'opop' : 'none';
            opacTrans = step === 9 ? 0 : 1;
            widthTrans = step === 9 ? disY[i] : ipo[i];

            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity + 's',
               transition: 'all ' + timeTrans + 's ' + easeMovRect,
               transform: 'rotate('+rotTrans+'deg)',
               width: widthTrans,
               // height: '78px',
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
         }

         else if (step >= 11 && i === 6) {
            if(isSmartphone){
               timeTrans = step === 12 ? timeTransSmall : '0';
               rotTrans = ang[i];
               animTrans = step === 12 ? 'opop' : 'none';
               opacTrans = step === 11 ? 0 : 1;
            }
            else{
               startY[i] = coordinate[i][1]*10;
               startX[i] = coordinate[i][0]*10;

               timeTrans = step === 12 ? timeTransBig : '0';
               rotTrans = step === 11 ? 90 : ang[i];
               animTrans = step === 12 ? 'opop' : 'none';
               opacTrans = step === 11 ? 0 : 1;
               widthTrans = step === 11 ? 0 : ipo[i];
            }

            updstyleR[i] = {
               opacity: opacTrans,
               animationName: animTrans,
               animationDuration: timeAnimOpacity + 's',
               transition: 'all ' + timeTrans + 's ' + easeMovRect,
               transform: 'rotate('+rotTrans+'deg)',
               width: widthTrans,
               left: startX[i]+'%',
               top: 'calc('+startY[i]+'% + ' + coordinate[i][2] + 'px - ' + rectT + 'px)',
            };
        }

      }

      for (let l = 0; l < 8; l++){
         let easeMov = step === 1 ? easeMovPlat : easeMovRect;
         updstyleP[l] = {
            transition: 'all ' + timeTransPlat + 's ' + easeMov,
            left: coordinate[l][0]*10+"%",
            top: 'calc('+coordinate[l][1]*10+'% + ' + coordinate[l][2] + 'px)',
         }
      }

      if (step >= 2) updateclassP[0] = 'objVisible';
      if (step >= 3) updateclassP[1] = 'objVisible';
      if (step >= 3) updateclassP[2] = 'objVisible';
      if (step >= 4) updateclassP[3] = 'objVisible';
      if (step >= 6) updateclassP[4] = 'objVisible';
      if (step >= 8) updateclassP[5] = 'objVisible';
      if (step >= 10) updateclassP[6] = 'objVisible';
      if (step >= 12) updateclassP[7] = 'objVisible';

      setStyleR(styleR.map((m, k) => {
         if (k === 6) return updstyleR[6];
         if (k === 5) return updstyleR[5];
         if (k === 4) return updstyleR[4];
         if (k === 3) return updstyleR[3];
         if (k === 2) return updstyleR[2];
         if (k === 1) return updstyleR[1];
         if (k === 0) return updstyleR[0];
         else return m
      }))

      setStyleP(styleP.map((m, k) => {
         if (k === 7) return updstyleP[7];
         if (k === 6) return updstyleP[6];
         if (k === 5) return updstyleP[5];
         if (k === 4) return updstyleP[4];
         if (k === 3) return updstyleP[3];
         if (k === 2) return updstyleP[2];
         if (k === 1) return updstyleP[1];
         if (k === 0) return updstyleP[0];
         else return m
      }))

      setClassP(styleP.map((m, k) => {
         if (k === 7) return updateclassP[7];
         if (k === 6) return updateclassP[6];
         if (k === 5) return updateclassP[5];
         if (k === 4) return updateclassP[4];
         if (k === 3) return updateclassP[3];
         if (k === 2) return updateclassP[2];
         if (k === 1) return updateclassP[1];
         if (k === 0) return updateclassP[0];
         else return m
      }))
   };

   React.useEffect(() => {
      if (coordinate) {
         // console.log(step);
         // console.log(coordinate);
         refreshPositions();
      }
   }, [coordinate])

  return (
    <>
      <div ref={screen} className={`screen step_${step} ${visible}`}>
        {/*<DebugGriglia />*/}

        {(!isSmartphone &&
           <>
              <div className="ball" id="ball1"></div>
              <div className="ball" id="ball2"></div>
              <div className="ball" id="ball3"></div>
              <div className="ball" id="ball4"></div>
           </>
        )}

        <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>

        <div className="heading">
          <h5>VALUE REPORT 2023</h5>
          <SvgContent stringToRet="susfut" />
          <HomeContent stringToRet="subtitle" lang={props.lang} />
        </div>

        <div className="preheading">
          <h5>VALUE REPORT 2023</h5>
          <HomeContent stringToRet="intro" lang={props.lang} />
        </div>

        <div className="anim">

           <div style={styleR[6]} className="rect rect7"></div>

           <button style={styleP[7]} className={`plat plat8 ${classP[7]}`}
           onClick={(e) => props.onClickMenu(e,'zoe')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj8" />
             <HomeContent stringToRet="zoe" lang={props.lang} />
           </button>

           <div style={styleR[5]} className="rect rect6"></div>

           <button style={styleP[6]} className={`plat plat7 ${classP[6]}`}
           onClick={(e) => props.onClickMenu(e,'italiassistenza')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj7" />
             <HomeContent stringToRet="italiassistenza" lang={props.lang} />
           </button>

           <div style={styleR[4]} className="rect rect5"></div>

           <button style={styleP[5]} className={`plat plat6 ${classP[5]}`} onClick={(e) => props.onClickMenu(e,'zcube')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj6" />
             <HomeContent stringToRet="zcube" lang={props.lang} />
           </button>

           <div style={styleR[3]} className="rect rect4"></div>

           <button style={styleP[4]} className={`plat plat5 ${classP[4]}`} onClick={(e) => props.onClickMenu(e,'openzone')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj5" />
             <HomeContent stringToRet="openzone" lang={props.lang} />
           </button>

           <div style={styleR[2]} className="rect rect3"></div>

           <button style={styleP[3]} className={`plat plat4 ${classP[3]}`} onClick={(e) => props.onClickMenu(e,'zach')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj4" />
             <HomeContent stringToRet="zach" lang={props.lang} />
           </button>

           <div style={styleR[1]} className="rect rect2"></div>

           <button style={styleP[2]} className={`plat plat3 ${classP[2]}`} onClick={(e) => props.onClickMenu(e,'biotech')}>
             <SvgContent stringToRet="piattaforma" />
             <SvgContent stringToRet="obj3" />
             <HomeContent stringToRet="biotech" lang={props.lang} />
           </button>

           <div style={styleR[0]} className="rect rect1"><p>For Our Health</p></div>

           <button style={styleP[1]} className={`plat plat2 ${classP[1]}`} onClick={(e) => props.onClickMenu(e,'pharma')}>
             <SvgContent stringToRet="piattaforma" />
             <img className="future" src={future} alt="future" />
             <img className="obj obj2" src={obj2} alt="obj2" />
             <HomeContent stringToRet="pharma" lang={props.lang} />
           </button>

           <button style={styleP[0]} className={`plat plat1 ${classP[0]}`} onClick={(e) => props.onClickMenu(e,'group')}>
             <SvgContent stringToRet="piattaforma" />

             <SvgContent stringToRet="obj1" />
             <img className="sustainable" src={sustainable} alt="sustainable" />
             <HomeContent stringToRet="group" lang={props.lang} />
           </button>

        </div>

        <button className='scroll' onClick={handleClickScroll}>
          <SvgContent stringToRet="down" />
        </button>


      </div>
    </>
  );
}

export default Home;


// const DebugGriglia = () => {
//   return (
//     <div className="griglia">
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//       <div className="or"></div>
//
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//       <div className="ver"></div>
//     </div>
//   )
// }
