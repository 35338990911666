import React from 'react';

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { ZachContent } from "./content/ZachContent"
import { LogosContent } from "./content/LogosContent"

import mySvg from '../assets/svg/bg.svg';
import grafici1 from '../assets/images/dato-zach-1.png';
import grafici2 from '../assets/images/dato-zach-2.png';
import grafici3 from '../assets/images/dato-zach-3.png';
import grafici1EN from '../assets/images/dato-zach-1-eng.png';
import grafici2EN from '../assets/images/dato-zach-2-eng.png';
import grafici3EN from '../assets/images/dato-zach-3-eng.png';


import investimenti from '../assets/images/investimentiZach.png';
import investimentiEN from '../assets/images/investimentiZach-eng.png';

const Zach = (props) => {
   return (
      <div className="internalPage zachPage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
         <SvgContent stringToRet="tube" />

         <div className="pagerow pagerow_head">
            <div className="row">
                <div className="col-6">
                   <div className="plat">
                      <div className="ball"></div>
                      <SvgContent stringToRet="obj4" />
                      <SvgContent stringToRet="piattaforma" />
                   </div>
                </div>
               <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                     <div className="arrow">
                        <SvgContent stringToRet="arrow" />
                     </div>
                     <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                     <div className="logo-wrapper">
                        <LogosContent stringToRet="zach" />
                     </div>
                     <h4 className="d-none d-md-block"><ZachContent lang={props.lang} stringToRet="weShapeYour" /></h4>
                  </div>
               </div>
            </div>
         </div>


         <div className="pagerow d-md-none">
            <div className="row">
               <h4 className="tac"><ZachContent lang={props.lang} stringToRet="weShapeYour" /></h4>
            </div>
         </div>

         <div className="pagerow pagerow_lettera">
           <SvgContent stringToRet="doc" />

           <div className="row">
             <div className="d-none d-lg-block col-1"></div>

             <div className="col-12 col-sm-8 col-lg-7 col-lettertext">
               <div className="quoteblock">
                 <p className="quote"><ZachContent lang={props.lang} stringToRet="ulterioreCrescita" /></p>
                 <p className="quoteAuthor"><ZachContent lang={props.lang} stringToRet="lucioLavacchielli" /></p>
                 <p className="quoteRole"><ZachContent lang={props.lang} stringToRet="ceoZach" /></p>
               </div>
             </div>

             <div className="col-12 col-sm-4">
               <button className="cta ctaLetter" onClick={(e) => props.onToggleExtra(e,'lucio')}>
                 <SvgContent stringToRet="lettera" />
                 <span><ZachContent lang={props.lang} stringToRet="leggiMessaggio" /></span>
                 <div className="arrow"><SvgContent stringToRet="arrow" /></div>
               </button>
             </div>

           </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="pro" />

            <div className="row jcc">
             <div className="col-12">
                 <h2><ZachContent lang={props.lang} stringToRet="zach2023" /></h2>
             </div>



                <div className="col-6 col-sm-4">
               <div className="icon_num">
                  <SvgContent stringToRet="fatturato" />
                  <div>
                     <h5><strong>59</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                     <h6><CommonContent lang={props.lang} stringToRet="totaleFatturato" /></h6>
                  </div>
               </div>
             </div>

             <div className="col-6 col-sm-4">
                <div className="icon_num">
                  <SvgContent stringToRet="ebitda" />
                  <div>
                     <h5><strong>9,8</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                     <h6><CommonContent lang={props.lang} stringToRet="ebitda" /></h6>
                  </div>
               </div>
             </div>

             <div className="col-6 col-sm-4">
                <div className="icon_num">
                  <SvgContent stringToRet="utile" />
                  <div>
                  <h5><strong>5,8</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                  <h6><CommonContent lang={props.lang} stringToRet="utileNetto" /></h6>
                  </div>
               </div>
             </div>
            </div>
         </div>

         <div className="pagerow group_persone">
         <SvgContent stringToRet="lente" />

         <div className="row aic">
             <div className="col-12 col-md-6 text-center">
               {(props.lang === 'ita'? <img className="totDip" src={grafici1} alt="" /> : <img className="totDip" src={grafici1EN} alt="" />)}
             </div>
             <div className="col-12 col-md-6 mt-5 mt-0-md text-center">
             {(props.lang === 'ita'? <img src={grafici2} alt="" /> : <img src={grafici2EN} alt="" />)}
             </div>
          </div>
          <div className="row mt-5">
             <div className="d-none d-md-block col-3"></div>
             <div className="col-12 col-md-6 text-center">
             {(props.lang === 'ita'? <img src={grafici3} alt="" /> : <img src={grafici3EN} alt="" />)}

             </div>

         </div>
         </div>

         <div className="pagerow">
         <SvgContent stringToRet="nuv" />

         <div className="row">
            <div className="d-none d-lg-block col-1"></div>
           <div className="col-12 col-lg-11">
            <h2><ZachContent lang={props.lang} stringToRet="investimenti" /></h2>
          </div>
          <div className="d-none d-lg-block col-1"></div>
          <div className="col-12 col-lg-8 col-benvPeopleContent">
            <p className="bgrounded"><ZachContent lang={props.lang} stringToRet="nel2023Zach" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'investimenti')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>
          </div>

             <div className="d-none d-lg-block col-lg-2">
               {(props.lang === 'ita'? <img src={investimenti} alt="" /> : <img src={investimentiEN} alt="" />)}
             </div>
          </div>

         </div>


      </div>
   );
}
export default Zach;
