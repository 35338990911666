import React from 'react'

export const BiotechContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'mission') && (
        <>Our mission is to create <span>a long term pipeline</span> aligned
        with Zambon deep purpose to make <span>patients’ lives better</span></>
      )}

      {(string === 'zambonBiotechVuoleDiventare' && lang === 'ita') && (
        <>Zambon Biotech vuole diventare il motore della crescita futura
        del gruppo, principalmente attraverso lo scouting e lo sviluppo di progetti di
        biotecnologia e cura delle malattie rare.</>
      )}

      {(string === 'elenaZambon') && (
        <>Elena Zambon</>
      )}
      {(string === 'caricaZambon' && lang === 'ita') && (
        <>Presidente Zambon SpA</>
      )}

      {(string === 'productInnovation' && lang === 'ita') && (
        <>Product Innovation</>
      )}

      {(string === 'rizuloFilmOrale' && lang === 'ita') && (
        <>Il 2023 ha segnato l'inizio della commercializzazione di Emylif, una nuova formula di Riluzolo, una terapia standard per i pazienti affetti da Sclerosi Laterale Amiotrofica. Il prodotto è stato lanciato con successo in Germania, Regno Unito, Spagna, Francia, Belgio e Paesi Bassi. L'azienda si è impegnata a lanciare Emylif in Italia, Svizzera, Portogallo e Lussemburgo entro il secondo trimestre del 2024.</>
      )}


      {(string === 'valutazioneNuoviProdotti' && lang === 'ita') && (
        <>Valutazione di nuovi prodotti</>
      )}

      {(string === 'veroCentroEccellenza' && lang === 'ita') && (
        <>Nel 2023 Zambon Biotech ha proseguito l'attività di scouting e di valutazione di nuovi asset nei settori del Sistema Nervoso Centrale, respiratorio e delle malattie orfane. Nel corso del 2023 Zambon Biotech ha presentato diverse offerte non vincolanti, principalmente per asset rari Sistema Nervoso Centrale. Per quanto riguarda la malattia di Parkinson, l'azienda ha avviato trattative avanzate per un asset di Fase III. Dall'inizio del suo impegno nello sviluppo commerciale, Zambon Biotech ha valutato oltre 100 asset e avviato colloqui riservati con più di 20 aziende nelle sue principali aree di interesse. Zambon Biotech ha aumentato la propria visibilità come partner di riferimento presentandosi a conferenze internazionali come la BIO International Convention e la Jefferies London Healthcare Conference.</>
      )}

      {(string === 'coinvolgimentoEsperti' && lang === 'ita') && (
        <>Coinvolgimento di esperti</>
      )}

      {(string === 'centroDiEccellenza' && lang === 'ita') && (
        <>In qualità di centro di eccellenza per l'innovazione esterna del gruppo Zambon, nel 2023 Zambon Biotech ha continuato a rafforzare le proprie competenze chiave attraverso il coinvolgimento di esperti nella sua missione di supporto ai bisogni dei pazienti.</>
      )}



      {(string === 'zambonBiotechVuoleDiventare' && lang === 'eng') && (
        <>Zambon Biotech is the affirmation of our strong interest in the biotech sector, which has only grown since the acquisition of Breath Therapeutics in 2019. With Zambon Biotech, we continue to look beyond the horizon and expand our gaze globally.</>
      )}

      {(string === 'caricaZambon' && lang === 'eng') && (
        <>Zambon SpA President</>
      )}

      {(string === 'productInnovation' && lang === 'eng') && (
        <>Product Innovation</>
      )}

      {(string === 'rizuloFilmOrale' && lang === 'eng') && (
        <>2023 has marked the start of the commercialization of Emylif, a new formulation of Riluzole, a standard therapy for patients with Amyotrophic Lateral Sclerosis. The product has been successfully launched in Germany, UK, Spain, France, Belgium, Netherlands. The company is committed to launch Emylif in Italy, Switzerland, Portugal and Luxembourg by Q2 2024.</>
      )}


      {(string === 'valutazioneNuoviProdotti' && lang === 'eng') && (
        <>Scouting for new assets</>
      )}

      {(string === 'veroCentroEccellenza' && lang === 'eng') && (
        <>In 2023 Zambon Biotech continued scouting and evaluation for new assets in CNS, Respiratory and Orphan Diseases. During 2023 Zambon Biotech successfully submitted several non-binding offers mainly for rare CNS assets. In Parkinson’s disease, the company has progressed into advanced negotiations for a Phase III assets. Since the beginning of its business development effort, Zambon Biotech has evaluated over 100 assets and entered into confidential discussions with more than 20 companies across its main areas of focus. Zambon Biotech increased its visibility as a partner of choice by presenting at international conferences such as BIO International Convention and Jefferies London Healthcare Conference.</>
      )}

      {(string === 'coinvolgimentoEsperti' && lang === 'eng') && (
        <>Engagement of experts</>
      )}

      {(string === 'centroDiEccellenza' && lang === 'eng') && (
        <>As Zambon Group’s center of Excellence for External Innovation, in 2023 Zambon Biotech has continued to strengthen its core competencies through the engagement of experts in its mission to support patient needs. </>
      )}

    </>
  )
}
