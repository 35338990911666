import React from 'react'

export const PharmaContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>

       {(string === 'pharma2023' && lang === 'ita') && (
        <>Pharma nel 2023</>
       )}

       {(string === 'collaboratoriMondo' && lang === 'ita') && (
        <>Persone</>
       )}

       {(string === 'estCountries' && lang === 'ita') && (
          <><strong>*</strong> Russia, Bosnia, Bulgaria, Polonia, Ungheria, Montenegro, Romania,
          Serbia, Slovenia, Kazakistan, Lituania, Moldavia, Ucraina</>
       )}

       {(string === 'venditeMondo' && lang === 'ita') && (
        <>Vendite nel mondo</>
       )}

       {(string === 'areeTerapeutiche' && lang === 'ita') && (
         <>AREE TERAPEUTICHE</>
       )}




       {(string === 'dolore' && lang === 'ita') && (
         <>Dolore</>
       )}
       {(string === 'disordiniNeurologici' && lang === 'ita') && (
         <>Disordini neurologici</>
       )}
      {(string === 'malattieApparatoRespiratorio' && lang === 'ita') && (
        <>Malattie apparato respiratorio</>
      )}
      {(string === 'malattieRespiratorieGravi' && lang === 'ita') && (
        <>Malattie respiratorie gravi</>
      )}
      {(string === 'disturbiUrologici' && lang === 'ita') && (
        <>Sistema urologico</>
      )}
      {(string === 'gastro' && lang === 'ita') && (
        <>Gastro</>
      )}


      {(string === 'ricaviAreaTerapeutica' && lang === 'ita') && (
        <>Ricavi per area terapeutica</>
      )}

      {(string === 'goodScience') && (
        <>Good Science</>
      )}
      {(string === 'attivitaRicercaSviluppo' && lang === 'ita') && (
        <>Nel 2023 le attività di Ricerca e Sviluppo si sono focalizzate principalmente in ambito respiratorio.</>
      )}

      {(string === 'industrialBusinessOperation') && (
        <>INDUSTRIAL BUSINESS OPERATION</>
      )}
      {(string === 'produzioneFarmaceutica' && lang === 'ita') && (
        <>Il 2023 è stato un anno significativo per gli stabilimenti che hanno affrontato e superato una doppia sfida: soddisfare le crescenti richieste dei mercati e gestire le difficoltà legate alle forniture.</>
      )}

      {(string === 'pipeline') && (
        <>Pipeline</>
      )}

      {(string === 'businessDev') && (
        <>Business development</>
      )}


      {(string === 'sviluppoNostroBusiness' && lang === 'ita') && (
        <>Nel 2023 le attività di Business Development sono state intense e hanno interessato più ambiti.<br />
        <br />
        In particolare, abbiamo rinforzato la nostra struttura global e affinato ruoli e procedure per snellire e accelerare i processi.  Inoltre, abbiamo concluso importanti partnership come quelle siglate con <strong>Vectans</strong> in Francia e <strong>Wakix</strong> nei Nordics.<br />
        <br />
        Infine, grazie alla partecipazione ad una serie di importanti eventi internazionali abbiamo continuato lo scouting di interessanti opportunità per espandere e rinnovare il portafoglio attuale delle filiali Zambon nel mondo.</>
      )}






      {(string === 'pharma2023' && lang === 'eng') && (
         <>Pharma in 2023</>
      )}

      {(string === 'collaboratoriMondo' && lang === 'eng') && (
         <>People</>
      )}

      {(string === 'estCountries' && lang === 'eng') && (
         <><strong>*</strong> Russia, Bosnia, Bulgaria, Poland, Hungary, Montenegro, Romania,
         Serbia, Slovenia, Kazakhstan, Lithuania, Moldova, Ukraine</>
      )}

      {(string === 'venditeMondo' && lang === 'eng') && (
         <>Worldwide sales</>
      )}

      {(string === 'areeTerapeutiche' && lang === 'eng') && (
        <>Therapeutic areas</>
      )}

      {(string === 'dolore' && lang === 'eng') && (
        <>Pain</>
      )}
      {(string === 'disordiniNeurologici' && lang === 'eng') && (
        <>The central nervous system</>
      )}
     {(string === 'malattieApparatoRespiratorio' && lang === 'eng') && (
        <>Respiratory diseases</>
     )}
     {(string === 'malattieRespiratorieGravi' && lang === 'eng') && (
        <>Severe respiratory diseases</>
     )}
     {(string === 'disturbiUrologici' && lang === 'eng') && (
        <>Urological system</>
     )}
     {(string === 'gastro' && lang === 'eng') && (
        <>Gastrointestinal</>
     )}

     {(string === 'ricaviAreaTerapeutica' && lang === 'eng') && (
        <>Revenues by therapeutic area</>
     )}

     {(string === 'attivitaRicercaSviluppo' && lang === 'eng') && (
        <>In 2023, R&D activities focused mainly on the respiratory field.</>
     )}


     {(string === 'produzioneFarmaceutica' && lang === 'eng') && (
        <>The year 2023 was a significant one for the facilities, which faced and overcame a dual challenge: meeting growing market demand and managing supply bottlenecks.</>
     )}


     {(string === 'sviluppoNostroBusiness' && lang === 'eng') && (
        <>In 2023, intense business development activities were carried out in several areas.<br />
        <br />
        We strengthened our global structure and refined roles and procedures to streamline and accelerate processes. In addition, we entered important partnerships, such as those signed with <strong>Vectans</strong> in France and <strong>Wakix</strong> in the Nordics.<br />
        <br />
        Finally, thanks to our participation in a series of important international events, we continued to scout appealing opportunities to expand and renew the current portfolio of Zambon branches worldwide.</>
     )}


    </>
  )
}
