import React from 'react';

import { YoutubeEmbed } from "./Utility"

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { ZoeContent } from "./content/ZoeContent"
import { LogosContent } from "./content/LogosContent"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';


import mySvg from '../assets/svg/bg.svg';

import oxy1 from '../assets/images/gallery/oxygen/oxy-1.png';
import oxy2 from '../assets/images/gallery/oxygen/oxy-2.png';
import oxy3 from '../assets/images/gallery/oxygen/oxy-3.png';
import oxy4 from '../assets/images/gallery/oxygen/oxy-4.png';
import oxy5 from '../assets/images/gallery/oxygen/oxy-5.png';
import oxy6 from '../assets/images/gallery/oxygen/oxy-6.png';
import oxy7 from '../assets/images/gallery/oxygen/oxy-7.png';

import park1 from '../assets/images/gallery/parkinson/par-1.png';
import park2 from '../assets/images/gallery/parkinson/par-2.png';
import park3 from '../assets/images/gallery/parkinson/par-3.png';
import park4 from '../assets/images/gallery/parkinson/par-4.png';


const Group = (props) => {

   return (
      <div className="internalPage zoePage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
         <SvgContent stringToRet="tube" />

         <div className="pagerow pagerow_head">
            <div className="row">
               <div className="col-6">
                  <div className="plat">
                     <div className="ball"></div>
                     <SvgContent stringToRet="obj8" />
                     <SvgContent stringToRet="piattaforma" />
                  </div>
               </div>

               <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                     <div className="arrow">
                        <SvgContent stringToRet="arrow" />
                     </div>
                     <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                     <div className="logo-wrapper">
                        <LogosContent stringToRet="zoe" />
                     </div>
                     <h4 className="d-none d-md-block"><ZoeContent lang={props.lang} stringToRet="contribuiamoAlloSviluppo" /></h4>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow d-md-none">
            <div className="row">
               <h4 className="tac"><ZoeContent lang={props.lang} stringToRet="contribuiamoAlloSviluppo" /></h4>
            </div>
         </div>

         <div className="pagerow pagerow_lettera">
           <SvgContent stringToRet="doc" />

           <div className="row jcc">
             <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-lettertext">
               <div className="quoteblock">
                 <p className="quote"><ZoeContent lang={props.lang} stringToRet="soloAllargamento" /></p>
                 <p className="quoteAuthor"><ZoeContent lang={props.lang} stringToRet="gaetano" /></p>
                 <p className="quoteRole"><ZoeContent lang={props.lang} stringToRet="luglio38" /></p>
               </div>
             </div>



           </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="cuo" />
           <div className="row jcc">
             <div className="col-12">
               <h2><ZoeContent lang={props.lang} stringToRet="nel2023" /></h2>
             </div>
             <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-benvPeopleContent">
               <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="nel2023Content" /></p>
             </div>
           </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="nuv" />
            <div className="row aic">
               <div className="col-12 col-md-6 pr-md-15">
                  <h3><ZoeContent lang={props.lang} stringToRet="radiciFuturo" /></h3>
                  <div className="col-12 col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="radiciFuturoContent" /></p>
                  </div>

               </div>
               <div className="col-12 col-md-6 pl-md-15 mt-0-md mt-3">
                  <h3><ZoeContent lang={props.lang} stringToRet="appuntamentiSalute" /></h3>
                  <div className="col-12 col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="appuntamentiSaluteContent" /></p>
                  </div>
                  <div className="col-12 mt-3">
                     <div className="video">
                      {(props.video === 'rembLnDBOTc') && (
                          <YoutubeEmbed embedId="rembLnDBOTc" />
                      )}
                      {(props.video !== 'rembLnDBOTc') && (
                        <>
                          <img src={require('../assets/images/video/zoe.png')} alt="Io sono" />
                          <div className="over">
                            <button className="cta play2" onClick={(e) => props.onClickVideo(e,'rembLnDBOTc')} >
                              <SvgContent stringToRet="play" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
           <SvgContent stringToRet="pro" />

           <div className="row jcc">
            <div className="col-12 col-md-10 col-lg-8">
               <h3><ZoeContent lang={props.lang} stringToRet="orizzontiSalute" /></h3>
             </div>
           </div>
           <div className="row jcc">
             <div className="col-12 col-md-10 col-lg-8 col-benvPeopleContent">
               <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="orizzontiSaluteContent" /></p>
             </div>
         </div>

         <div className="row jcc">
            <div className="col-12 col-md-10 col-lg-8">


              <div className="video">
                {(props.video === 'NbO4oDNgbK0') && (
                    <YoutubeEmbed embedId="NbO4oDNgbK0" />
                )}
                {(props.video !== 'NbO4oDNgbK0') && (
                  <>
                    <img src={require('../assets/images/video/zoe2.png')} alt="Io sono" />
                    <div className="over">
                      <button className="cta play2" onClick={(e) => props.onClickVideo(e,'NbO4oDNgbK0')} >
                        <SvgContent stringToRet="play" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
           </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="lente" />

            <div className="row aic">
               <div className="col-12 col-md-6 pr-md-15">


                  <h3><ZoeContent lang={props.lang} stringToRet="settimanaCervello" /></h3>
                  <div className="col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="settimanaCervelloContent" /></p>
                  </div>
                  <div className="video mt-3">
                   {(props.video === 'wmnjSZaA2to') && (
                       <YoutubeEmbed embedId="wmnjSZaA2to" />
                   )}
                   {(props.video !== 'wmnjSZaA2to') && (
                     <>
                       <img src={require('../assets/images/video/zoe3.png')} alt="Io sono" />
                       <div className="over">
                         <button className="cta play2" onClick={(e) => props.onClickVideo(e,'wmnjSZaA2to')} >
                           <SvgContent stringToRet="play" />
                         </button>
                       </div>
                     </>
                   )}
                 </div>
               </div>
               <div className="col-12 col-md-6 pl-md-15 mt-0-md mt-3">
                  <h3><ZoeContent lang={props.lang} stringToRet="oxygen" /></h3>
                  <div className="col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="oxygenContent" /></p>
                  </div>
                  <div className="mt-3">
                     <Swiper
                        pagination={true}
                        autoplay={{
                           delay: 2500,
                           disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}>

                         <SwiperSlide><img src={oxy1} alt="Oxygen 1" /></SwiperSlide>
                         <SwiperSlide><img src={oxy2} alt="Oxygen 2" /></SwiperSlide>
                         <SwiperSlide><img src={oxy3} alt="Oxygen 3" /></SwiperSlide>
                         <SwiperSlide><img src={oxy4} alt="Oxygen 4" /></SwiperSlide>

                         <SwiperSlide><img src={oxy5} alt="Oxygen 1" /></SwiperSlide>
                         <SwiperSlide><img src={oxy6} alt="Oxygen 2" /></SwiperSlide>
                         <SwiperSlide><img src={oxy7} alt="Oxygen 3" /></SwiperSlide>

                    </Swiper>


                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="pins" />
            <div className="row aic jcc">
               <div className="col-12 col-md-10 col-lg-8">
                  <h3><ZoeContent lang={props.lang} stringToRet="hcdfps" /></h3>
               </div>
            </div>
            <div className="row jcc">
               <div className="col-12 col-md-10 col-lg-8 col-benvPeopleContent">
                  <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="hcdfpsContent" /></p>

                  <a className="cta ctaEye" href={props.lang === 'ita' ? "https://francoangeli.it/Libro/Home-Care-Design-for-Parkinson%27s-Disease-Il-Design-dell%27ambiente-domestico-per-Persone-con-malattia?Id=27819" : "https://www.francoangeli.it/Libro/Home-Care-Design-for-Parkinson%27s-Disease-Designing-the-Home-Environment-for-People-with-Parkinson%27s-Disease?Id=28199 "} target="_blank" rel="noreferrer">

                    <SvgContent stringToRet="eye" />
                    <div className="content">
                      <span><ZoeContent lang={props.lang} stringToRet="scaricaLibro" /></span>
                      <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                    </div>
                  </a>
               </div>
            </div>
            <div className="row jcc">
               <div className="col-12 col-md-10 col-lg-8 mt-4">
                  <Swiper
                     pagination={true}
                     autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                     }}
                     loop={true}
                     modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}>

                      <SwiperSlide><img src={park1} alt="Parkinson 1" /></SwiperSlide>
                      <SwiperSlide><img src={park2} alt="Parkinson 2" /></SwiperSlide>
                      <SwiperSlide><img src={park3} alt="Parkinson 3" /></SwiperSlide>
                      <SwiperSlide><img src={park4} alt="Parkinson 4" /></SwiperSlide>

                 </Swiper>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="dna" />
            <div className="row">
               <div className="d-none d-md-block col-1"></div>
               <div className="col-12 col-sm-10 col-lg-8">
                  <h3><ZoeContent lang={props.lang} stringToRet="hta" /></h3>
                  <div className="col-12 col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="htaContent" /></p>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <div className="row jcc">
               <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                  <h3><ZoeContent lang={props.lang} stringToRet="resilio" /></h3>
                  <div className="col-12 col-benvPeopleContent">
                     <p className="bgrounded"><ZoeContent lang={props.lang} stringToRet="resilioContent" /></p>
                  </div>
               </div>
            </div>
         </div>

    </div>
  );
}
export default Group;
