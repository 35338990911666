import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import "./assets/scss/App.scss";
import "./assets/scss/Pages.scss";

import Home from "./components/Home"
import { Menu } from "./components/Menu";
import { Extra } from "./components/Extra";
import { Footer } from "./components/Footer";

import Group from "./components/Group"
import Pharma from "./components/Pharma"
import Biotech from "./components/Biotech"
import Zach from "./components/Zach"
import Openzone from "./components/Openzone"
import Zcube from "./components/Zcube"
import Italiassistenza from "./components/Italiassistenza"
import Zoe from "./components/Zoe"


import CookieConsent from "react-cookie-consent";

import { ScrollToTop } from "./components/Utility";

const body = document.body;

const AppWrapper = () => {
  return (
    <Router basename="/">
      <App />
    </Router>
  );
}
export default AppWrapper;

const App = () => {

  let [state, setState] = React.useState({ homeanimated: false, menuopen: false, menusticky: false, lang: 'eng', disabled: false, extraopen: false, extracontent: '', overlay: false, video: false, page: 'home'});
  let lang = (new URLSearchParams(window.location.search)).get("lang");

  let history = useHistory();

  let timerid;

  React.useEffect(() => {
    if(lang){
      if (lang === 'eng'){
        setState(prevState => ({
          ...prevState,
          lang: 'eng'
        }));
      }
      else if (lang === 'ita'){
        if (state.lang === 'eng') {
          setState(prevState => ({
            ...prevState,
            lang: 'ita'
          }));
        }
      }
    }
  }, []);

  const handleAnimated = () => {
     console.log("animated")
     setState(prevState => ({
     ...prevState,
     homeanimated: true
     }));
  }

  const handleChangeLang = () => {
    if (state.lang === 'ita') {
      setState(prevState => ({
        ...prevState,
        lang: 'eng'
      }));
    }
    else{
      setState(prevState => ({
        ...prevState,
        lang: 'ita'
      }));
    }
  }

  const handleClickVideo = (event, videoid) => {
    setState(prevState => ({
      ...prevState,
      video: videoid
    }));
  }

  const handleToggleMenu = () => {
    if (state.disabled === false) {
      disableMenu();

      if (state.menuopen === false) {
        setState(prevState => ({
          ...prevState,
          menuopen: true,
          overlay: true
        }));

        body.classList.add("noscroll");

        if (state.extraopen !== false) {
          setState(prevState => ({
            ...prevState,
            extraopen: false
          }));
        }
      } else if (state.menuopen === true) {
        setState(prevState => ({
          ...prevState,
          menuopen: false,
          overlay: false
        }));
        body.classList.remove("noscroll");

      }
    }
  }

  const handleToggleExtra = (event, content) => {
    if (state.video !== false) { // stoppo il video se ne sta andando uno
      setState(prevState => ({
        ...prevState,
        video: false
      }));
    }
    if (state.disabled === false) {
      //disableMenu();

      if (state.extraopen === false) {
        setState(prevState => ({
          ...prevState,
          extraopen: true,
          overlay: true
        }));

        body.classList.add("noscroll");

        setState(prevState => ({
          ...prevState,
          extracontent: content
        }));

        if (state.menuopen === true) {
          setState(prevState => ({
            ...prevState,
            menuopen: false
          }));
        }

      }
      else if (content !== undefined) {

        setState(prevState => ({
          ...prevState,
          extraopen: false,
        }));


        setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            extraopen: true,
            extracontent: content
          }));
        }, 350);
      }

      else  {
        setState(prevState => ({
          ...prevState,
          extraopen: false,
          overlay: false
        }));

        body.classList.remove("noscroll");

      }
    }
  }

  const handleClickOverlay = () => {
    if (state.menuopen === true) {
      handleToggleMenu();
    }
    else if (state.extraopen === true) {
      handleToggleExtra();
    }
  }

  const handleClickMenu = (e, newpage) => {
    console.log(e,newpage);
    if (state.menuopen === true) {
      handleToggleMenu();
    }

    if (state.extraopen === true) {
      handleToggleExtra();
    }

    // se sono nella home e non clicco di nuovo per tornare alla home
    if(state.page === 'home' && newpage !== 'home'){
      setState(prevState => ({
        ...prevState,
        overlay: true
      }));
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          overlay: false
        }));
      }, 0);

      if (timerid) {
        clearTimeout(timerid);
      }

      timerid = setTimeout(() => {
        history.push(newpage);
      }, 0);
    }

    else{
      if (newpage === 'home'){
        history.push('/', { back: true });
      }
      else{
        history.push(newpage, { back: true });
      }
    }

    setState(prevState => ({
      ...prevState,
      page: newpage
    }));

  }


  const disableMenu = () => {
    setState(prevState => ({
      ...prevState,
      disabled: true
    }));
    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        disabled: false
      }));
    }, 500);
  };

  return (
    <div className={`App ${state.page}`}>

      <div className={`overlay ${state.overlay ? "open" : ""}`} onClick={handleClickOverlay}></div>

      <Menu lang={state.lang} menuvisible={state.menuvisible} menuopen={state.menuopen} onToggleMenu={handleToggleMenu} onChangeLang={handleChangeLang} onClickMenu={handleClickMenu} page={state.page} />
      <Extra lang={state.lang} open={state.extraopen} page={state.page} content={state.extracontent} onClose={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} />

      {(state.lang === 'ita') && (
        <CookieConsent
          enableDeclineButton
          declineButtonText="x"
          location="bottom"
          flipButtons={true}
          acceptOnScroll={false}
          buttonText="Accetto"
          buttonWrapperClasses="buttonsCookie"
          declineButtonClasses="declineBtnCookie"
          buttonClasses="acceptBtnCookie"
          contentClasses="contentCookie"
          acceptOnScrollPercentage={1}>
          Per offrirti una migliore esperienza di navigazione e per avere statistiche sull&rsquo;uso dei nostri servizi da parte dell&rsquo;utenza, questo sito utilizza cookie anche di terze parti. Chiudendo questo banner o proseguendo nella navigazione acconsenti all'uso dei cookie. Per saperne di pi&ugrave; e per modificare le tue preferenze sui cookie consulta la nostra <a href="https://www.zambon.com/it/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
        </CookieConsent>
      )}
      {(state.lang === 'eng') && (
        <CookieConsent
          enableDeclineButton
          declineButtonText="x"
          location="bottom"
          flipButtons={true}
          acceptOnScroll={false}
          buttonText="I agree"
          buttonWrapperClasses="buttonsCookie"
          declineButtonClasses="declineBtnCookie"
          buttonClasses="acceptBtnCookie"
          contentClasses="contentCookie"
          acceptOnScrollPercentage={1}>
          To offer you a better browsing experience and to get statistics on the use of our services by users, this website uses first-part cookies and also third-party cookies. By closing this banner or continuing to browse, you consent to the use of cookies. To learn more and to change your cookie preferences click <a href="https://www.zambon.com/en/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
        </CookieConsent>
      )}
      <Switch>

        <Route path="/" exact>
          <ScrollToTop />
          <Home lang={state.lang} animated={state.homeanimated} onClickMenu={handleClickMenu} onAnimated={handleAnimated} />
        </Route>

        <Route path="/group">
          <ScrollToTop />
          <Group lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/pharma">
          <ScrollToTop />
          <Pharma lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/biotech">
          <ScrollToTop />
          <Biotech lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/zach">
          <ScrollToTop />
          <Zach lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/openzone">
          <ScrollToTop />
          <Openzone lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/zcube">
          <ScrollToTop />
          <Zcube lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/italiassistenza">
          <ScrollToTop />
          <Italiassistenza lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

        <Route path="/zoe">
          <ScrollToTop />
          <Zoe lang={state.lang} onToggleExtra={handleToggleExtra} video={state.video} onClickVideo={handleClickVideo} onClickMenu={handleClickMenu} />
        </Route>

      </Switch>

      <Footer lang={state.lang} />

    </div>
  );
}
