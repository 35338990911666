import React from 'react';

import { YoutubeEmbed } from "./Utility"

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { GroupContent } from "./content/GroupContent"

import mySvg from '../assets/svg/bg.svg';

import grafici1 from '../assets/images/dati-gruppo-1.png';
import grafici2 from '../assets/images/dati-gruppo-2.png';
import grafici3 from '../assets/images/dati-gruppo-3.png';

import grafici1EN from '../assets/images/dati-gruppo-1-eng.png';
import grafici2EN from '../assets/images/dati-gruppo-2-eng.png';
import grafici3EN from '../assets/images/dati-gruppo-3-eng.png';

import logomuseo from '../assets/images/logomuseo.jpg';
import twwa from '../assets/images/thewayweare.png';



const Group = (props) => {

   return (
      <div className="internalPage groupPage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
         <SvgContent stringToRet="tube" />

         <div className="pagerow pagerow_head headrow">
            <div className="row aic">
             <div className="col-12 col-sm-6">
               <div className="plat">
                  <div className="ball"></div>

                  <SvgContent stringToRet="obj1" />
                  <SvgContent stringToRet="piattaforma" />
               </div>
             </div>
             <div className="col-6">
               <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                 <div className="arrow">
                   <SvgContent stringToRet="arrow" />
                 </div>
                 <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
               </button>
               <div className="title d-none d-md-block">
                 <h1><GroupContent lang={props.lang} stringToRet="ilgruppo" /></h1>
                 <h4><GroupContent lang={props.lang} stringToRet="innovatingCureCare" /></h4>
               </div>
             </div>
            </div>
         </div>

         <div className="pagerow d-md-none">
            <div className="row">
               <h1 className="tac mb-3"><GroupContent lang={props.lang} stringToRet="ilgruppo" /></h1>
               <h4 className="tac"><GroupContent lang={props.lang} stringToRet="innovatingCureCare" /></h4>
            </div>
         </div>

         <div className="pagerow pagerow_lettera">
           <SvgContent stringToRet="doc" />

           <div className="row jcc">
             <div className="d-none d-lg-block col-1"></div>

             <div className="col-12 col-sm-8 col-lg-7 col-lettertext">
               <div className="quoteblock">
                 <p className="quote">“<GroupContent lang={props.lang} stringToRet="letteraZambon" />”</p>
                 <p className="quoteAuthor"><CommonContent lang={props.lang} stringToRet="elenaZambon" /></p>
               </div>
             </div>

             <div className="col-12 col-sm-4 dfl">
               <button className="cta ctaLetter" onClick={(e) => props.onToggleExtra(e,'elena')}>
                 <SvgContent stringToRet="lettera" />
                 <span><GroupContent lang={props.lang} stringToRet="readPresMessage" /></span>
                 <div className="arrow"><SvgContent stringToRet="arrow" /></div>
               </button>
             </div>

           </div>
         </div>

         <div className="pagerow pagerow_centered">
           <SvgContent stringToRet="pins" />
           <div className="row">
              <SvgContent stringToRet="pallogramma" />
           </div>
         </div>

         <div className="pagerow">
           <SvgContent stringToRet="pro" />

           <div className="row">
             <div className="col-12">
                 <h2><GroupContent lang={props.lang} stringToRet="groupin2023" /></h2>
             </div>

             <div className="col-12 col-sm-4">
               <div className="icon_num">
                  <SvgContent stringToRet="fatturato" />
                  <div>
                     <h5><strong>899</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                     <h6><CommonContent lang={props.lang} stringToRet="totaleFatturato" /></h6>
                  </div>
               </div>
             </div>

             <div className="col-12 col-sm-4">
                <div className="icon_num">
                  <SvgContent stringToRet="ebitda" />
                  <div>
                     <h5><strong>168</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                     <h6><CommonContent lang={props.lang} stringToRet="ebitda" /></h6>
                  </div>
               </div>
             </div>

             <div className="col-12 col-sm-4">
                <div className="icon_num">
                  <SvgContent stringToRet="utile" />
                  <div>
                  <h5><strong>85</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                  <h6><CommonContent lang={props.lang} stringToRet="utileNetto" /></h6>
                  </div>
               </div>
             </div>
           </div>
         </div>

         <div className="pagerow group_persone">
           <SvgContent stringToRet="lente" />

           <div className="row">
             <div className="col-12">
               <h2><GroupContent lang={props.lang} stringToRet="worldCollaborators" /></h2>
             </div>
             <div className="col-12 col-md-6 tar-md tac mt-3 mt-0-md pr_15">
               {(props.lang === 'ita'? <img className="imgWorld" src={grafici1} alt="" /> : <img src={grafici1EN} alt="" />)}

             </div>
             <div className="col-12 col-md-6 text-center pl_15 mt-6 mt-0-md">
               {(props.lang === 'ita'? <img src={grafici2} alt="" /> : <img src={grafici2EN} alt="" />)}
               {(props.lang === 'ita'? <img className="imgSchool mt-6" src={grafici3} alt="" /> : <img className="imgSchool mt-6" src={grafici3EN} alt="" />)}
             </div>
           </div>
         </div>

         <div className="pagerow group_thewayweare">
           <SvgContent stringToRet="dnaw" />


           <div className="row jcc">
             <div className="col-12">
                 <h2><GroupContent lang={props.lang} stringToRet="twwa" /></h2>
             </div>

             <div className="d-none d-md-block col-1"></div>
             <div className="col-12 col-md-7">
               <img className="twwa" src={twwa} alt="twwa" />
             </div>
             <div className="col-12 col-sm-6 col-md-4 mt-5 mt-0-md">
               <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'thewayweare')}>
                 <SvgContent stringToRet="eye" />
                 <div className="content">
                   <span className="wsnw"><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                   <span className="light"><GroupContent lang={props.lang} stringToRet="twwa" /></span>
                   <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                 </div>
               </button>
             </div>
           </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="cuo" />
           <div className="row">
             <div className="col-9">
               <h3><GroupContent lang={props.lang} stringToRet="benvivere" /></h3>
             </div>
             <div className="col-12 col-lg-9 col-benvPeopleContent">
               <p className="bgrounded"><GroupContent lang={props.lang} stringToRet="conBenvivere" /></p>

               <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'benvivere')}>
                 <SvgContent stringToRet="eye" />
                 <div className="content">
                   <span className="wsnw"><CommonContent lang={props.lang} stringToRet="scopriTutte" /></span>
                   <span className="light"><GroupContent lang={props.lang} stringToRet="iniziative" /></span>
                   <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                 </div>
               </button>

             </div>
           </div>
         </div>

         <div className="pagerow">
           <SvgContent stringToRet="nuv" />

           <div className="row">
             <div className="d-none d-lg-block col-1"></div>
             <div className="col-12 col-lg-9">
               <h3><GroupContent lang={props.lang} stringToRet="peopleAndCulture" /></h3>
             </div>
            </div>
            <div className="row">
             <div className="d-none d-lg-block col-1"></div>
             <div className="col-12 col-lg-9 col-benvPeopleContent">
               <p className="bgrounded"><GroupContent lang={props.lang} stringToRet="peopleCare" /></p>

               <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'people')}>
                 <SvgContent stringToRet="eye" />
                 <div className="content">
                   <span className="wsnw"><CommonContent lang={props.lang} stringToRet="scopriTutte" /></span>
                   <span className="light"><GroupContent lang={props.lang} stringToRet="iniziative" /></span>
                   <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                 </div>
               </button>
             </div>
           </div>
         </div>

         <div className="pagerow pagerow_video">
           <SvgContent stringToRet="dna" />


           <div className="row">
           <div className="col-12">
            <h2><GroupContent lang={props.lang} stringToRet="museoZambon" /></h2>
         </div>
             <div className="d-none d-lg-block col-1"></div>


             <div className="d-none d-sm-block col-3 pr_15">
               <img className="logomuseo" src={logomuseo} alt="logomuseo" />
             </div>


             <div className="col-12 col-sm-9 col-lg-7 pl_15">


               <div className="video">
                 {(props.video === 'Rsu757PFwzc') && (
                     <YoutubeEmbed embedId="Rsu757PFwzc" />
                 )}
                 {(props.video !== 'Rsu757PFwzc') && (
                   <>
                     <img src={require('../assets/images/video/museo.png')} alt="Io sono" />
                     <div className="over">
                       <button className="cta play2" onClick={(e) => props.onClickVideo(e,'Rsu757PFwzc')} >
                         <SvgContent stringToRet="play" />
                       </button>
                     </div>
                   </>
                 )}
               </div>
             </div>
           </div>
         </div>

      </div>
   );
}
export default Group;
