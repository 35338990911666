import React from 'react';

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"
import { LogosContent } from "./content/LogosContent"

import { PharmaContent } from "./content/PharmaContent"

import mySvg from '../assets/svg/bg.svg';

import areeTer from '../assets/images/areeterapeutiche.png';
import areeTerEN from '../assets/images/areeterapeutiche-eng.png';

import pipelineImg from '../assets/images/pipeline.png';
import pipelineImgEN from '../assets/images/pipeline-eng.png';

import ibo from '../assets/images/Ibo-pharma.png';
import iboEN from '../assets/images/Ibo-pharma-eng.png';

import ibomappa from '../assets/images/Ibo-pharma-mappa.png';
import ibomappaEN from '../assets/images/Ibo-pharma-mappa-eng.png';

import pharmamondo from '../assets/images/pharma_mondo.png';
import pharmamondoEN from '../assets/images/pharma_mondo-eng.png';

import pharmapersone from '../assets/images/pharma_persone.png';
import pharmapersoneEN from '../assets/images/pharma_persone-eng.png';

import obj2 from '../assets/images/obj2.png';

import area1 from '../assets/images/area1.png';
import area2 from '../assets/images/area2.png';
import area3 from '../assets/images/area3.png';
import area4 from '../assets/images/area5.png';
import area5 from '../assets/images/area4.png';

const Pharma = (props) => {
   return (
      <div className="internalPage pharmaPage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
            <SvgContent stringToRet="tube" />

            <div className="pagerow pagerow_head">

               <div className="row">

                <div className="col-6">
                   <div className="plat">
                      <div className="ball"></div>

                      <img className="obj obj2" src={obj2} alt="obj2" />
                      <SvgContent stringToRet="piattaforma" />
                   </div>
                </div>

                <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                    <div className="arrow">
                      <SvgContent stringToRet="arrow" />
                    </div>
                    <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                    <div className="logo-wrapper">
                       <LogosContent stringToRet="pharma" />
                    </div>
                  </div>
                </div>
               </div>
            </div>

      <div className="pagerow">
        <SvgContent stringToRet="pro" />

        <div className="row jcc">

           <div className="col-6 col-sm-4">
             <div className="icon_num">
              <SvgContent stringToRet="fatturato" />
              <div>
                   <h5><strong>843</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                   <h6><CommonContent lang={props.lang} stringToRet="totaleFatturato" /></h6>
              </div>
             </div>
           </div>

           <div className="col-6 col-sm-4">
             <div className="icon_num">
              <SvgContent stringToRet="ebitda" />
              <div>
                   <h5><strong>170</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
                   <h6><CommonContent lang={props.lang} stringToRet="ebitda" /></h6>
              </div>
             </div>
           </div>

           <div className="col-6 col-sm-4">
             <div className="icon_num">
              <SvgContent stringToRet="utile" />
              <div>
              <h5><strong>92</strong> <CommonContent lang={props.lang} stringToRet="mioeuro" /></h5>
              <h6><CommonContent lang={props.lang} stringToRet="utileNetto" /></h6>
              </div>
             </div>
           </div>

        </div>
      </div>

      <div className="pagerow group_persone">
        <SvgContent stringToRet="lente" />
        <div className="row">
            <div className="d-none d-lg-block col-1"></div>

             <div className="col-12 col-md-6 col-lg-5 text-center pr-md-15">
                <h3 className="text-center mt-3"><PharmaContent lang={props.lang} stringToRet="collaboratoriMondo" /></h3>
                {(props.lang === 'ita'? <img src={pharmapersone} alt="" /> : <img src={pharmapersoneEN} alt="" />)}

                {/*<div className="graph_e_nums">
                  <GraphsContent stringToRet="pharma1" />
                   <div className="num">
                     <CountUp separator={props.lang === 'ita' ? `.` : `,`} end={2540} delay={.6} />
                   </div>
                 </div>
                 */}

             </div>


            <div className="col-12 col-md-6 col-lg-5 text-center mt-5 mt-md-0 pl-md-15">
               <h3 className="text-center"><PharmaContent lang={props.lang} stringToRet="venditeMondo" /></h3>
               {(props.lang === 'ita'? <img src={pharmamondo} alt="" /> : <img src={pharmamondoEN} alt="" />)}
               <p className="estCountries"><PharmaContent lang={props.lang} stringToRet="estCountries" /></p>
               </div>

               <div className="d-none d-lg-block col-1"></div>

           </div>
      </div>

      <div className="pagerow group_thewayweare">
        <SvgContent stringToRet="dnaw" />


        <div className="row">
          <div className="col-12">
            <h2 className="text-center"><PharmaContent lang={props.lang} stringToRet="areeTerapeutiche" /></h2>
          </div>
        </div>

        <div className="row">
           <div className="col-12 aree">
             <button className="area" onClick={(e) => props.onToggleExtra(e,'at1')}>
               <img className="areeTer" src={area1} alt="area 1" />
               <h4><PharmaContent lang={props.lang} stringToRet="dolore" /></h4>
             </button>
             <button className="area" onClick={(e) => props.onToggleExtra(e,'at2')}>
               <img className="areeTer" src={area2} alt="area 2" />
               <h4><PharmaContent lang={props.lang} stringToRet="disordiniNeurologici" /></h4>
             </button>
             <button className="area" onClick={(e) => props.onToggleExtra(e,'at3')}>
               <img className="areeTer" src={area3} alt="area 3" />
               <h4><PharmaContent lang={props.lang} stringToRet="malattieApparatoRespiratorio" /></h4>
             </button>
             <button className="area" onClick={(e) => props.onToggleExtra(e,'at4')}>
               <img className="areeTer" src={area4} alt="area 4" />
               <h4><PharmaContent lang={props.lang} stringToRet="malattieRespiratorieGravi" /></h4>
             </button>
             <button className="area" onClick={(e) => props.onToggleExtra(e,'at5')}>
               <img className="areeTer" src={area5} alt="area 5" />
               <h4><PharmaContent lang={props.lang} stringToRet="disturbiUrologici" /></h4>
             </button>
           </div>
        </div>
      </div>

      <div className="pagerow group_thewayweare">
        <SvgContent stringToRet="dnaw" />


        <div className="row">
          <div className="col-12">
            <h3 className="text-center"><PharmaContent lang={props.lang} stringToRet="ricaviAreaTerapeutica" /></h3>
          </div>

          <div className="col-12 text-center">
            {(props.lang === 'ita'? <img className="areeTer" src={areeTer} alt="aree" /> : <img className="areeTer" src={areeTerEN} alt="aree" />)}
          </div>
        </div>
      </div>

      <div className="pagerow">
         <SvgContent stringToRet="cuo" />
        <div className="row">
          <div className="d-none d-lg-block col-2"></div>
          <div className="col-12 col-lg-8">
            <h3><PharmaContent lang={props.lang} stringToRet="goodScience" /></h3>
          </div>
       </div>
       <div className="row">
          <div className="d-none d-lg-block col-2"></div>

          <div className="col-12 col-lg-8 col-benvPeopleContent">
            <p className="bgrounded"><PharmaContent lang={props.lang} stringToRet="attivitaRicercaSviluppo" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'goodscience')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>

          </div>
        </div>
      </div>

      <div className="pagerow">
        <SvgContent stringToRet="nuv" />

        <div className="row">
        <div className="d-none d-md-block col-md-1 col-lg-2"></div>

          <div className="col-12 col-md-8">
            <h3><PharmaContent lang={props.lang} stringToRet="industrialBusinessOperation" /></h3>
          </div>
         </div>
         <div className="row">
         <div className="d-none d-md-block col-md-1 col-lg-2"></div>

          <div className="col-3 col-lg-2 pr_15">
            {(props.lang === 'ita'? <img src={ibo} alt="aree" /> : <img src={iboEN} alt="aree" />)}
         </div>
          <div className="col-9 col-md-8 col-lg-6 pl_15 col-benvPeopleContent fdc jcs">

            <p className="bgrounded"><PharmaContent lang={props.lang} stringToRet="produzioneFarmaceutica" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'ibo')}>
            <SvgContent stringToRet="eye" />
            <div className="content">
            <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
            <div className="arrow"><SvgContent stringToRet="arrow" /></div>
            </div>
            </button>
          </div>
      </div>
      <div className="row mt-5">
         <div className="d-none d-lg-block col-1"></div>

          <div className="col-12 col-lg-10  text-center">
            {(props.lang === 'ita'? <img src={ibomappa} alt="aree" /> : <img src={ibomappaEN} alt="aree" />)}
           </div>
        </div>
      </div>

      <div className="pagerow group_museo">
        <SvgContent stringToRet="dnaw" />

        <div className="row">
          <div className="col-12">
            <h2><PharmaContent lang={props.lang} stringToRet="pipeline" /></h2>
         </div>
         <div className="col-12  text-center">
            {(props.lang === 'ita'? <img src={pipelineImg} alt="aree" /> : <img src={pipelineImgEN} alt="aree" />)}

          </div>
        </div>
      </div>

      <div className="pagerow">
         <SvgContent stringToRet="cuo" />
        <div className="row">
           <div className="col-3"></div>
           <div className="col-6">
            <h3 className="text-center"><PharmaContent lang={props.lang} stringToRet="businessDev" /></h3>
          </div>
          <div className="col-3"></div>
         </div>
         <div className="row">
         <div className="d-none d-md-block col-2"></div>
          <div className="col-12 col-md-8 col-benvPeopleContent">
            <p className="bgrounded bgralone"><PharmaContent lang={props.lang} stringToRet="sviluppoNostroBusiness" /></p>

          </div>
        </div>
      </div>



    </div>
  );
}
export default Pharma;
