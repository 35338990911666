import React from 'react'

export const ItaliassistenzaContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'italiassistenza') && (
        <>ItaliAssistenza</>
      )}
      {(string === 'hcc' && lang === 'ita') && (
        <>ItaliAssistenza è la Home Care Company leader da 30 anni nell’assistenza domiciliare in Italia. L’azienda opera su tutto il territorio nazionale tramite i suoi due brand, Human Assist Care, un ecosistema di servizi a 360° per migliorare l’aderenza terapeutica, e PrivatAssistenza, il primo network di Centri di assistenza domiciliare per anziani, malati e disabili. La società fornisce cure a domicilio di qualità anche nel territorio ticinese tramite lo spitex Privatassistenza SA.</>
      )}

      {(string === 'il2023' && lang === 'ita') && (
        <>IL 2023</>
      )}

      {(string === 'diItaliassistenza' && lang === 'ita') && (
        <>Di Italiassistanza</>
      )}

      {(string === 'hac' && lang === 'ita') && (
        <>Human Assist Care è il brand con cui ItaliAssistenza si occupa in Italia di programmi a sostegno dell'aderenza alla terapia in un’ottica win-win, che permette di non gravare sul SSN e di migliorare sensibilmente la qualità di vita dei Pazienti e delle loro famiglie.</>
      )}

      {(string === 'pa' && lang === 'ita') && (
        <>PrivatAssistenza è il marchio che, da oltre 30 anni, è sinonimo di assistenza domiciliare di qualità in Italia, oggi punto di riferimento per oltre 50.000 famiglie, che ogni anno si affidano ai circa 180 centri presenti su tutto il territorio nazionale per servizi personalizzati e di qualità di assistenza socio-sanitaria per anziani, malati e disabili. PrivatAssistenza è la prima Rete di Assistenza Domiciliare per longevità, capillarità, copertura ed esperienza.</>
      )}

      {(string === 'ca') && (
        <>Careapt</>
      )}

      {(string === 'caContent' && lang === 'ita') && (
        <>Careapt continua il suo percorso di crescita e amplia la gamma di piattaforme di telemedicina e teleassistenza a disposizione dei pazienti con malattie croniche e dei loro caregiver.</>
      )}

      {(string === 'webinar' && lang === 'ita') && (
        <>I webinar di ParkinsonCare</>
      )}

      {(string === 'webinarContent' && lang === 'ita') && (
        <>I webinar di ParkinsonCare sono incontri mensili online, che hanno l’obiettivo di completare il lavoro di educazione terapeutica svolto dai care manager. Nei webinar, le diverse figure dell’Equipe multidisciplinare di ParkinsonCare offrono approfondimenti su sintomi, terapia farmacologica e sulle diverse strategie non farmacologiche che si possono adottare per migliorare il controllo della malattia e la qualità di vita. I temi affrontati spaziano dalle strategie per fronteggiare il burnout del caregiver agli alimenti che possono influenzare l’assorbimento della terapia farmacologica, ai disturbi urinari, a disfagia e disartria. Persone con Parkinson e Caregiver sempre più competenti e sempre più al centro delle cure.</>
      )}

      {(string === 'youtube' && lang === 'ita') && (
        <>scopri<br />i webinar di careapt</>
      )}

      {(string === 'itaBlock1' && lang === 'ita') && (
        <>
           <h5><strong>18</strong></h5>
           <h6>years of experience<br />
           nella gestione diretta<br />
           di programmi di supporto<br />
           all’aderenza terapeutica</h6>
        </>
      )}
      {(string === 'itaBlock2' && lang === 'ita') && (
        <>
           <h5>oltre <strong>180</strong></h5>
           <h6><strong>Professionisti sanitari</strong><br />
           attivi <em>day-by-day</em><br />
           in tutta Italia</h6>
        </>
      )}
      {(string === 'itaBlock3' && lang === 'ita') && (
        <>
          <h5>oltre <strong>95.000</strong></h5>
          <h6><strong>pazienti</strong> assistiti<br />
          in <strong>25 differenti<br />
          aree terapeutiche</strong></h6>
        </>
      )}
      {(string === 'itaBlock4' && lang === 'ita') && (
        <>
           <h5><strong>> 96%</strong></h5>
           <h6><strong>aderenza terapeutica</strong><br />
           sui programmi<br />
           attivi</h6>
        </>
      )}
      {(string === 'itaBlock5' && lang === 'ita') && (
        <>
           <h5><strong>30</strong></h5>
           <h6>anni di esperienza<br />
           nel settore</h6>
        </>
      )}
      {(string === 'itaBlock6' && lang === 'ita') && (
        <>
           <h5><strong>180</strong></h5>
           <h6><strong>Centri operativi</strong><br />
           in 2.000 comuni italiani</h6>
        </>
      )}
      {(string === 'itaBlock7' && lang === 'ita') && (
        <>
           <h5><strong>25</strong> milioni</h5>
           <h6>di <strong>abitanti raggiunti</strong><br />
           dai servizi del network</h6>
        </>
      )}
      {(string === 'itaBlock8' && lang === 'ita') && (
        <>
           <h5>oltre <strong>50.000</strong></h5>
           <h6><strong>famiglie assistite<br />
           ogni anno</strong></h6>
        </>
      )}





      {(string === 'hcc' && lang === 'eng') && (
        <>ItaliAssistenza has been the leading Home Care Company in Italy for 30 years. The company operates nationwide through its two brands, Human Assist Care, an ecosystem of 360° services to improve therapeutic adherence, and PrivatAssistenza, the first network of home care centres for the elderly, sick and disabled. The company also provides quality home care in the Ticino region through the Privatassistenza SA Spitex home care service provider.</>
      )}

      {(string === 'il2023' && lang === 'eng') && (
        <>Italiassistanza</>
      )}

      {(string === 'diItaliassistenza' && lang === 'eng') && (
        <>in 2023</>
      )}

      {(string === 'hac' && lang === 'eng') && (
        <>Human Assist Care is ItaliAssistenza’s brand in Italy for programmes to support therapeutic adherence from a win-win perspective, i.e., without burdening the National Health Service while also significantly improving the quality of life of patients and their families.</>
      )}

      {(string === 'pa' && lang === 'eng') && (
        <>PrivatAssistenza is the brand that has been synonymous with quality home care in Italy for more than 30 years. Today, it is a trusted name for more than 50,000 families who rely every year on the approximately 180 Centres throughout the country for customised, quality social and health care services for the elderly, sick and disabled. </>
      )}

      {(string === 'caContent' && lang === 'eng') && (
        <>Careapt continues its growth path and widens the range of remote medicine and remote care platforms available to patients with chronic diseases and to their caregivers.</>
      )}

      {(string === 'webinar' && lang === 'eng') && (
        <>ParkinsonCare webinars</>
      )}

      {(string === 'webinarContent' && lang === 'eng') && (
        <>ParkinsonCare webinars are monthly online meetings that aim to complement the therapeutic education work carried out by care managers. In the webinars, the various figures of the ParkinsonCare multidisciplinary team offer insights into symptoms, drug therapies and the various non-pharmacological strategies that can be adopted to improve disease control as well as quality of life. The topics covered ranged from strategies for coping with caregiver burnout to foods that may influence the absorption of drug therapies, urinary disorders, dysphagia and dysarthria. People with Parkinson's and their caregivers increasingly skilled and increasingly at the heart of care.</>
      )}

      {(string === 'youtube' && lang === 'eng') && (
        <>discover<br />careapt webinars</>
      )}


      {(string === 'itaBlock1' && lang === 'eng') && (
        <>
           <h5><strong>18</strong></h5>
           <h6>years of experience<br />
           in the direct management<br />
           of therapeutic adherence<br />
           support programmes </h6>
        </>
      )}
      {(string === 'itaBlock2' && lang === 'eng') && (
        <>
           <h5>over <strong>180</strong></h5>
           <h6><strong>healthcare professionals<br />
           working every day<br /></strong>
           <em>across Italy</em></h6>
        </>
      )}
      {(string === 'itaBlock3' && lang === 'eng') && (
        <>
          <h5>more then <strong>95,000</strong></h5>
          <h6><strong>patients</strong> served<br />
          in <strong>25 different<br />
          therapeutic areas </strong></h6>
        </>
      )}
      {(string === 'itaBlock4' && lang === 'eng') && (
        <>
           <h5><strong>> 96%</strong></h5>
           <h6>Therapeutic adherence<br />
           on active programmes</h6>
        </>
      )}
      {(string === 'itaBlock5' && lang === 'eng') && (
        <>
           <h5>over <strong>30</strong></h5>
           <h6>years of experience<br />
           in the industry</h6>
        </>
      )}
      {(string === 'itaBlock6' && lang === 'eng') && (
        <>
           <h5><strong>180</strong></h5>
           <h6><strong>Operating Centres</strong><br />
           in 2,000 Italian municipalities</h6>
        </>
      )}
      {(string === 'itaBlock7' && lang === 'eng') && (
        <>
           <h5><strong>25</strong> million</h5>
           <h6><strong>inhabitants reached</strong><br />
           by network services</h6>
        </>
      )}
      {(string === 'itaBlock8' && lang === 'eng') && (
        <>
           <h5>over <strong>50,000</strong></h5>
           <h6><strong>families assisted<br />
           every year</strong></h6>
        </>
      )}


    </>
  )
}
