import React from 'react'

export const ZoeContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'contribuiamoAlloSviluppo' && lang === 'ita') && (
        <>CONTRIBUIAMO ALLO SVILUPPO
       DELLA CONOSCENZA,
       ALLO SCAMBIO DI INFORMAZIONI,
       AL MIGLIORAMENTO
       DELLA COMUNICAZIONE
       NEL CAMPO DELLA SALUTE</>
      )}
      {(string === 'soloAllargamento' && lang === 'ita') && (
        <><span>“</span>Solo l'allargamento delle proprie cognizioni scientiﬁche e lo studio indefesso di tutti i problemi sociali e organizzativi permettono di spaziare sopra la mediocrità e rendersi veramente utili e quasi indispensabili.<span className="last">”</span></>
      )}
      {(string === 'gaetano') && (
        <>GAETANO ZAMBON</>
      )}

      {(string === 'luglio38' && lang === 'ita') && (
        <>7 LUGLIO 1938</>
      )}

      {(string === 'nel2023' && lang === 'ita') && (
        <>NEL 2023</>
      )}

      {(string === 'nel2023Content' && lang === 'ita') && (
        <>Nel 2023 Fondazione Zoé ha radicato la propria attività nei territori, potenziando le attività in presenza e inaugurando un nuovo format di eventi. Allo stesso tempo, la presenza online ha permesso di coltivare una community in crescita e di estendere la divulgazione sui temi della salute oltre i propri confini fisici. La proposta formativa di Human Touch Academy è stata consolidata e il progetto Home Care Design for Parkinson’s Disease ha ricevuto un importante riconoscimento.</>
      )}

      {(string === 'radiciFuturo' && lang === 'ita') && (
        <>LE RADICI DEL NOSTRO FUTURO</>
      )}

      {(string === 'radiciFuturoContent' && lang === 'ita') && (
        <>A luglio è stato avviato un percorso per rafforzare il ruolo di Fondazione Zoé quale catalizzatore della Responsabilità Sociale di Zambon. Attraverso un workshop che ha coinvolto 50 colleghi, under 40, provenienti da tutte le funzioni aziendali, ci si è interrogati sul futuro della Fondazione tra spunti progettuali, temi strategici e proposte comunicative dall’alto valore sinergico.</>
      )}
      {(string === 'appuntamentiSalute' && lang === 'ita') && (
        <>APPUNTAMENTI DELLA SALUTE</>
      )}
      {(string === 'appuntamentiSaluteContent' && lang === 'ita') && (
        <>Con <strong>Gli Appuntamenti della Salute</strong> Zoé ha aperto i propri spazi a un nuovo ciclo di incontri mensili in presenza per prenderci cura della nostra salute. Dai disturbi dell’alimentazione, alla musicoterapia, fino all’intestino come secondo cervello esperti del territorio e divulgatori scientifici si sono susseguiti per capire, riflettere, prevenire.</>
      )}

      {(string === 'orizzontiSalute' && lang === 'ita') && (
        <>Gli Orizzonti della Salute.<br />One Health. La salute circolare</>
      )}
      {(string === 'orizzontiSaluteContent' && lang === 'ita') && (
        <>La salute globale, One Health, è il grande tema che si è imposto alla riflessione di Fondazione Zoé e che caratterizza il ciclo triennale de Gli Orizzonti della Salute. L’edizione 2023 ha esplorato il significato di una sola salute per l’essere umano, gli animali e l’ambiente, adottando un approccio multidisciplinare, in un’ottica di open education, per capire le numerose sfaccettature che la parola salute ha assunto nel mondo globalizzato.</>
      )}

      {(string === 'settimanaCervello' && lang === 'ita') && (
        <>Settimana del Cervello</>
      )}
      {(string === 'settimanaCervelloContent') && (
        <>Zoé ha aderito per il sesto anno alla <strong>“Settimana del Cervello – Brain Awareness Week”</strong>, campagna mondiale di sensibilizzazione dell’opinione pubblica nata con lo scopo di diffondere la conoscenza sui progressi e i benefici della ricerca scientifica sul cervello, proponendo laboratori, conferenze online e in presenza e un palinsesto di contenuti digitali di approfondimento per la <strong>Settimana del Cervello online</strong>.</>
      )}

      {(string === 'oxygen' && lang === 'ita') && (
        <>Il Respiro di Oxy.gen</>
      )}
      {(string === 'oxygenContent' && lang === 'ita') && (
        <>La quinta stagione de Il Respiro di Oxy.gen, rassegna realizzata in collaborazione con Parco Nord, si è sviluppata nell’arco di tutto l’anno proponendo eventi pensati per contribuire allo sviluppo dell’educazione alla salute e all’ambiente.</>
      )}

      {(string === 'playlistVideo' && lang === 'ita') && (
        <>PLAYLIST VIDEO</>
      )}

      {(string === 'hcdfps' && lang === 'ita') && (
        <>Home Care Design for Parkinson’s Disease</>
      )}
      {(string === 'hcdfpsContent' && lang === 'ita') && (
        <>Le linee guida <strong>“Home Care Design For Parkinson’s Disease”</strong> sono state inserite nell’<strong>ADI Design Index 2023</strong>, una prestigiosa selezione realizzata dall'Osservatorio permanente del Design che comprende progetti editoriali, ricerche, prodotti o sistemi di prodotti che si sono distinti per innovazione e qualità del design. <br />
        <br />
        I progetti selezionati sono stati raccontati nel corso di un’esposizione allestita a Milano e a Roma nei mesi di ottobre e novembre e concorrono al Premio per l’Innovazione – ADI Design Index e al Premio Compasso d’Oro, uno dei premi di design più prestigiosi.</>
      )}

      {(string === 'scaricaLibro' && lang === 'ita') && (
        <>SCARICA IL LIBRO</>
      )}

      {(string === 'hta') && (
        <>Human Touch Academy</>
      )}
      {(string === 'htaContent' && lang === 'ita') && (
        <>Nel 2023 è stata realizzata a Vicenza la seconda edizione del corso di formazione con accreditamento <strong>ECM</strong> rivolto ai professionisti di tutte le aree sanitarie dal titolo <strong>"Aver cura dell’altro e aver cura di sé”</strong> con l’obiettivo di tornare a riflettere sull’essenza della cura attraverso un percorso di <em>Medical Humanities</em>.</>
      )}

      {(string === 'resilio' && lang === 'ita') && (
        <>Altri Progetti:<br />Resilio</>
      )}
      {(string === 'resilioContent' && lang === 'ita') && (
        <>Il 2023 ha visto l’avvio della terza annualità del progetto Resilio, un’esperienza che ha coinvolto sei classi delle scuole elementari di Bresso, affiancando bambini e anziani, in attività artistiche volte a migliorarne la salute mentale e il benessere psicologico.</>
      )}




      {(string === 'contribuiamoAlloSviluppo' && lang === 'eng') && (
         <>We contribute to the development of knowledge and to the exchange
         of information in the field of health, enhancing a proper communication.</>
      )}
      {(string === 'soloAllargamento' && lang === 'eng') && (
        <><span>“</span>Only the enlargement of our scientific cognitions and the tireless studying of all social and organisational issues enable us to rise above the mediocrity and become really useful and almost indispensable.<span className="last">”</span></>
      )}


      {(string === 'luglio38' && lang === 'eng') && (
        <>7 July 1938</>
      )}

      {(string === 'nel2023' && lang === 'eng') && (
        <>IN 2023</>
      )}

      {(string === 'nel2023Content' && lang === 'eng') && (
        <>In 2023, Fondazione Zoé took root, strengthening its local in-person activities and inaugurating a new event format. At the same time, its online presence has made it possible to cultivate a growing community and extend outreach on health issues beyond physical confines. The Human Touch Academy training offer has been consolidated, and the Home Care Design for Parkinson's Disease project has received an important recognition.</>
      )}

      {(string === 'radiciFuturo' && lang === 'eng') && (
        <>The roots of our future</>
      )}

      {(string === 'radiciFuturoContent' && lang === 'eng') && (
        <>In July, a process was started to strengthen the role of Fondazione Zoé as a catalyst for Zambon's Social Responsibility. Through a workshop involving 50 colleagues under 40 from all company functions, the future of the Foundation was discussed amidst project ideas, strategic topics and highly synergistic communication proposals.</>
      )}
      {(string === 'appuntamentiSalute' && lang === 'eng') && (
        <>Gli Appuntamenti della Salute (Health Appointments)</>
      )}
      {(string === 'appuntamentiSaluteContent' && lang === 'eng') && (
        <>With <strong>Gli Appuntamenti della Salute</strong>, Zoé has opened its spaces up to a new series of monthly in-person meetings about taking care of our health. From eating disorders to music therapy to the gut as the second brain, local experts and science communicators all contributed their own perspectives to understand, reflect and prevent.</>
      )}

      {(string === 'orizzontiSalute' && lang === 'eng') && (
        <>Gli Orizzonti della Salute (Health Horizons).<br />One Health. Circular health </>
      )}
      {(string === 'orizzontiSaluteContent' && lang === 'eng') && (
        <>“One Health” is the major theme that has come to the forefront of Fondazione Zoé's thinking and which characterises the three-year series of Gli Orizzonti della Salute. The 2023 edition explored the meaning of one health for humans, animals and the environment, taking a multidisciplinary approach, from an open education perspective, to understand the many facets that the word health has taken on in the globalised world.</>
      )}

      {(string === 'settimanaCervello' && lang === 'eng') && (
        <>Brain Awareness Week</>
      )}
      {(string === 'settimanaCervelloContent' && lang === 'eng') && (
        <>For the sixth year, Zoé joined the <strong>“Brain Awareness Week”</strong>, a global public awareness campaign developed with the aim of spreading knowledge about the progress and benefits of scientific brain research, offering workshops, online and in-person conferences and a programme of in-depth digital content for <strong>Online Brain Awareness Week</strong>.</>
      )}

      {(string === 'oxygen' && lang === 'eng') && (
        <>Il Respiro di Oxy.gen</>
      )}
      {(string === 'oxygenContent' && lang === 'eng') && (
        <>The fifth season of Il Respiro di Oxy.gen, a programme organised in cooperation with Parco Nord, took place throughout the year, offering events designed to contribute to the development of health and environmental education.</>
      )}

      {(string === 'playlistVideo' && lang === 'eng') && (
        <>VIDEO PLAYLIST</>
      )}
      {(string === 'hcdfpsContent' && lang === 'eng') && (
        <>The <strong>“Home Care Design for Parkinson's Disease”</strong> guidelines have been included in the <strong>ADI Design Index 2023</strong>, a prestigious selection made by the permanent Design Observatory that includes editorial projects, research, products or product systems that stand out due to their innovation and design quality.<br />
        <br />
        The projects selected were showcased at an exhibition taking place in Milan and Rome in October and November and compete for the Innovation Award - ADI Design Index and the Compasso d'Oro Award, one of the most prestigious design prizes.</>
      )}

      {(string === 'scaricaLibro' && lang === 'eng') && (
        <>Download the book</>
      )}

      {(string === 'htaContent' && lang === 'eng') && (
        <>In 2023, the second edition of the <strong>ECM</strong>-accredited training course took place, aimed at professionals from all healthcare areas, entitled <strong>“Taking care of others and taking care of yourself”</strong>, with the aim of once again reflecting on the essence of care through a Medical Humanities course.</>
      )}

      {(string === 'resilio' && lang === 'eng') && (
        <>Other projects:<br />Resilio</>
      )}
      {(string === 'resilioContent' && lang === 'eng') && (
        <>The year 2023 saw the start of the third year of the Resilio project, an experience that involved six primary school classes in Bresso, working alongside children and the elderly, in artistic activities aimed at improving their mental health and psychological well-being.</>
      )}

    </>
  )
}
