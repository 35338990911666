import React from 'react';

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { ZcubeContent } from "./content/ZcubeContent"
import { LogosContent } from "./content/LogosContent"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import mySvg from '../assets/svg/bg.svg';

import ottomarzo1 from '../assets/images/gallery/ottomarzo/ottomarzo-1.png';
import ottomarzo2 from '../assets/images/gallery/ottomarzo/ottomarzo-2.png';
import ottomarzo3 from '../assets/images/gallery/ottomarzo/ottomarzo-3.png';
import ottomarzo4 from '../assets/images/gallery/ottomarzo/ottomarzo-4.png';
import ottomarzo5 from '../assets/images/gallery/ottomarzo/ottomarzo-5.png';
import ottomarzo6 from '../assets/images/gallery/ottomarzo/ottomarzo-6.png';
import ottomarzo7 from '../assets/images/gallery/ottomarzo/ottomarzo-7.png';


const Group = (props) => {
   return (
      <div className="internalPage zcubePage">
         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
            <SvgContent stringToRet="tube" />

            <div className="pagerow pagerow_head">

               <div className="row">
                <div className="col-6">
                   <div className="plat">
                      <div className="ball"></div>
                      <SvgContent stringToRet="obj6" />
                      <SvgContent stringToRet="piattaforma" />
                   </div>
                </div>
                <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                    <div className="arrow">
                      <SvgContent stringToRet="arrow" />
                    </div>
                    <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                     <div className="logo-wrapper">
                        <LogosContent stringToRet="zcube" />
                     </div>
                    <h4 className="d-none d-md-block"><ZcubeContent lang={props.lang} stringToRet="modernHealthSolutions" /></h4>

                  </div>
                </div>
               </div>
            </div>




            <div className="pagerow d-md-none">
               <div className="row">
                  <h4 className="tac"><ZcubeContent lang={props.lang} stringToRet="modernHealthSolutions" /></h4>
               </div>
            </div>


            <div className="pagerow pagerow_lettera">
              <SvgContent stringToRet="doc" />

              <div className="row">
                 <div className="d-none d-lg-block col-1"></div>
                 <div className="col-12 col-sm-8 col-lg-7 col-lettertext">
                     <div className="quoteblock">
                       <p className="quote"><ZcubeContent lang={props.lang} stringToRet="zcubeProsegue" /></p>
                     </div>
                 </div>

                 <div className="col-12 col-sm-4">
                  <button className="cta ctaLetter" onClick={(e) => props.onToggleExtra(e,'zcube')}>

                  <SvgContent stringToRet="eye" />


                    <span><strong><CommonContent lang={props.lang} stringToRet="scopri" /></strong></span>
                    <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                  </button>
                </div>

              </div>
            </div>




      <div className="pagerow">
         <SvgContent stringToRet="dnaw" />

         <div className="row">
           <div className="d-none d-lg-block col-1"></div>
           <div className="col-12 col-sm-10 col-md-11 col-lg-8">
             <h3><ZcubeContent lang={props.lang} stringToRet="corporateVenture" /></h3>
           </div>
         </div>

         <div className="row">
         <div className="d-none d-lg-block col-1"></div>
           <div className="col-12 col-sm-10 col-md-11 col-lg-8 col-benvPeopleContent">
              <p className="bgrounded"><ZcubeContent lang={props.lang} stringToRet="cvnel2023" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'corp')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>

          </div>
        </div>
      </div>

      <div className="pagerow">
        <SvgContent stringToRet="cuo" />

        <div className="row">
          <div className="d-none d-sm-block col-sm-1 col-lg-2"></div>
          <div className="col-12 col-sm-11 col-lg-10 col-xl-8">
            <h2><ZcubeContent lang={props.lang} stringToRet="openAccelerator" /></h2>
          </div>
      </div>
      <div className="row">
          <div className="d-none d-sm-block col-sm-1 col-lg-2"></div>
          <div className="col-12 col-sm-11 col-lg-10 col-xl-8 col-benvPeopleContent">
            <p className="bgrounded"><ZcubeContent lang={props.lang} stringToRet="openAcceleratorProgramma" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'openacc')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <span className="light"><ZcubeContent lang={props.lang} stringToRet="iniziative" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="pagerow">
        <SvgContent stringToRet="nuv" />

        <div className="row">
          <div className="col-12 col-sm-11 col-lg-8">
            <h3><ZcubeContent lang={props.lang} stringToRet="callForWomen" /></h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-11 col-lg-8 col-benvPeopleContent">
            <p className="bgrounded"><ZcubeContent lang={props.lang} stringToRet="callForWomenTesto" /></p>
            <div className="buttons">
               <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'call')}>
                 <SvgContent stringToRet="eye" />
                 <div className="content">
                   <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                   <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                 </div>
                </button>

            <a className="cta ctaEye" href="https://www.openaccelerator.it/" target="_blank" rel="noreferrer">
              <SvgContent stringToRet="web" />
              <div className="content">
                <span><ZcubeContent lang={props.lang} stringToRet="" /><CommonContent lang={props.lang} stringToRet="visita" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </a>
            </div>
          </div>
        </div>
        <div className="row mt-4 jcc">
           <div className="col-12 col-md-6 col-xl-4 pr-md-15">

           <Swiper
              pagination={true}
              autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}
            >

              <SwiperSlide><img src={ottomarzo1} alt="Otto marzo 1" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo2} alt="Otto marzo 2" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo3} alt="Otto marzo 3" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo4} alt="Otto marzo 4" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo5} alt="Otto marzo 5" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo6} alt="Otto marzo 6" /></SwiperSlide>
              <SwiperSlide><img src={ottomarzo7} alt="Otto marzo 7" /></SwiperSlide>

           </Swiper>
           </div>
           <div className="col-12 col-md-6 col-xl-4 pl-md-15 mt-0-md mt-3">
              <img className="borderdImg" src={require('../assets/images/callfor.jpg')} alt="Call for Women's Health Innovations" />
            </div>
        </div>

      </div>

      <div className="pagerow">
        <SvgContent stringToRet="lente" />

        <div className="row">
          <div className="d-none d-md-block col-1"></div>
           <div className="col-12 col-md-10 col-xl-8">
            <h3><ZcubeContent lang={props.lang} stringToRet="vita" /></h3>
          </div>
        </div>
        <div className="row">
          <div className="d-none d-md-block col-1"></div>
          <div className="col-12 col-md-10 col-xl-8 col-benvPeopleContent">
            <p className="bgrounded"><ZcubeContent lang={props.lang} stringToRet="innovationPartner" /></p>

            <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'vita')}>
              <SvgContent stringToRet="eye" />
              <div className="content">
                <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                <div className="arrow"><SvgContent stringToRet="arrow" /></div>
              </div>
            </button>
          </div>


        </div>



        <div className="row mt-5 jcc">
           <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <img src={require('../assets/images/vita.png')} alt="Io sono" />
          </div>
        </div>
      </div>

    </div>
  );
}
export default Group;
