import React from 'react'

export const HomeContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'group' && lang === 'ita') && (
        <h2>Il <strong>Gruppo</strong></h2>
      )}
      {(string === 'group' && lang === 'eng') && (
        <h2>The <strong>Group</strong></h2>
      )}
      {(string === 'pharma' && lang === 'ita') && (
        <h2>Zambon<br /><strong>Pharma</strong></h2>
      )}
      {(string === 'pharma' && lang === 'eng') && (
        <h2>Zambon<br /><strong>Pharma</strong></h2>
      )}
      {(string === 'biotech' && lang === 'ita') && (
        <h2>Zambon<br /><strong>Biotech</strong></h2>
      )}
      {(string === 'biotech' && lang === 'eng') && (
        <h2>Zambon<br /><strong>Biotech</strong></h2>
      )}
      {(string === 'zach' && lang === 'ita') && (
        <h2><strong>Zach</strong></h2>
      )}
      {(string === 'zach' && lang === 'eng') && (
        <h2><strong>Zach</strong></h2>
      )}
      {(string === 'openzone' && lang === 'ita') && (
        <h2><strong>Openzone</strong></h2>
      )}
      {(string === 'openzone' && lang === 'eng') && (
        <h2><strong>Openzone</strong></h2>
      )}
      {(string === 'zcube' && lang === 'ita') && (
        <h2><strong>Zcube</strong></h2>
      )}
      {(string === 'zcube' && lang === 'eng') && (
        <h2><strong>Zcube</strong></h2>
      )}
      {(string === 'italiassistenza' && lang === 'ita') && (
        <h2><strong><span>ItaliAssistenza</span><br />Careapt</strong></h2>
      )}
      {(string === 'italiassistenza' && lang === 'eng') && (
        <h2><strong><span>ItaliAssistenza</span><br />Careapt</strong></h2>
      )}
      {(string === 'zoe' && lang === 'ita') && (
        <h2>Fondazione<br /><strong>Zoé</strong></h2>
      )}
      {(string === 'zoe' && lang === 'eng') && (
        <h2><strong>Zoé</strong><br />Foundation</h2>
      )}


      {(string === 'subtitle') && (
        <h2><span>For Our Health</span></h2>
      )}

      {(string === 'intro') && (
        <h3>INNOVATING<br />
        <strong>CURE</strong> AND <strong>CARE</strong><br />
        TO MAKE PATIENTS’<br />
        LIVES BETTER.</h3>
      )}




      {(string === 'scopri' && lang === 'ita') && (
        <>Scopri<br />il nostro mondo</>
      )}
      {(string === 'scopri' && lang === 'eng') && (
        <>Discover<br />our world</>
      )}

    </>
  )
}
