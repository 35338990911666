import React from 'react'

export const ZachContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'weShapeYour') && (
        <>We shape your molecules<br />
       through <strong>innovative</strong><br />
       <strong>solutions</strong>, supporting your<br />
       drug development programs</>
      )}


      {(string === 'ulterioreCrescita' && lang === 'ita') && (
        <><span>“</span>La crescita costante nel 2023 è la prova tangibile del nostro costante impegno verso l'innovazione e il progresso.<span>”</span></>
      )}
      {(string === 'lucioLavacchielli') && (
        <>Lucio Lavacchielli</>
      )}
      {(string === 'ceoZach' && lang === 'ita') && (
        <>CEO di Zach - Zambon Chemicals</>
      )}

      {(string === 'leggiMessaggio' && lang === 'ita') && (
        <>Leggi tutto il messaggio</>
      )}

      {(string === 'zach2023' && lang === 'ita') && (
        <>Zach nel 2023</>
      )}

      {(string === 'investimenti' && lang === 'ita') && (
        <>INVESTIMENTI</>
      )}
      {(string === 'nel2023Zach' && lang === 'ita') && (
        <>Anche nel corso del 2023, Zach ha dimostrato il suo impegno costante nell'investire nel proprio sviluppo e futuro. Con un investimento di 4,9 milioni di euro nell'ultimo anno, Zach ha ampliato le proprie strutture e infrastrutture, migliorando così la capacità di offrire prodotti e servizi all'avanguardia. Grazie a questo impegno continuo, Zach si sta posizionando per affrontare le sfide future con determinazione e successo.</>
      )}




      {(string === 'ulterioreCrescita' && lang === 'eng') && (
        <><span>“</span>Our steady growth in 2023 is tangible proof of our ongoing commitment to innovation and progress.<span>”</span></>
      )}

      {(string === 'ceoZach' && lang === 'eng') && (
        <>CEO of Zach - Zambon Chemicals</>
      )}

      {(string === 'leggiMessaggio' && lang === 'eng') && (
        <>Read all the message</>
      )}

      {(string === 'zach2023' && lang === 'eng') && (
        <>Zach in 2023</>
      )}

      {(string === 'investimenti' && lang === 'eng') && (
        <>Investments</>
      )}
      {(string === 'nel2023Zach' && lang === 'eng') && (
        <>Again in 2023, Zach demonstrated its continued commitment to investing in its own development and future. With an investment of €4.9 million in the last year, Zach has expanded its facilities and infrastructure, thus improving its ability to offer state-of-the-art products and services. Thanks to this ongoing commitment, Zach is positioning himself to meet future challenges with determination and success.</>
      )}





    </>
  )
}
