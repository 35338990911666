import React from "react";

import { UseIsInViewport } from "./Utility"
import { SvgContent } from "./content/SvgContent"

import "./../assets/scss/Footer.scss";

export const Footer = React.forwardRef((props, reff) => {
  // const [archiveState, setArchiveState] = React.useState(0);
  // const [show, setShow] = React.useState(true);
  // const [lastScrollY, setLastScrollY] = React.useState(0);

  const ref = React.useRef();
  let visible = UseIsInViewport(ref);

  // const toggleArchiveContent = () => {
  //   if (archiveState === 0) setArchiveState(1);
  //   else setArchiveState(0);
  // }

   return (

      <div className="footer" id="footer" >
         <div className={`smallfooter ${visible ? 'absolute' : 'fixed'}`}>
            <div className="logo">
              <SvgContent stringToRet="zambon" />
            </div>
         </div>

         <div ref={ref} className="bigfooter">
            <div className="row">
               <div className="col-6 col-sm-4 footleft">
                  <p>
                     <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.zambon.com` : `https://www.zambon.com/en`}>Zambon website</a><br />
                     <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.zambon.com/it/contatti` : `https://www.zambon.com/en/contact`}>Contatti</a>
                  </p>
               </div>

               <div className="col-6 col-sm-4 footcenter">
                  <p>
                     <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.zambon.com/it/legal` : `https://www.zambon.com/en/legal`}>Legal Notice</a><br />
                     <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.zambon.com/it/privacy-policy` : `https://www.zambon.com/en/privacy-policy`}>Privacy Policy</a><br />
                     <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.zambon.com/it/cookie-policy` : `https://www.zambon.com/en/cookie-policy`}>Cookie Policy</a>
                  </p>
               </div>

               <div className="col-12 col-sm-4 socials footright">
                  <div className="d-none d-sm-block">
                     <a target="_blank" rel="noreferrer" href="https://www.instagram.com/zambon1906/"><SvgContent stringToRet="instagram" /></a>
                     <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/zambon"><SvgContent stringToRet="linkedin" /></a>
                  </div>
                  <a rel="noreferrer" target="_blank" href="https://www.visualmade.it/">Site by <span>Visualmade</span></a>
               </div>

               <div className="col-12 footbottom">
                     <p className="copyright">ZAMBON COMPANY S.P.A. ©2024, ALL RIGHTS RESERVED</p>
               </div>
            </div>
         </div>
      </div>
   );
});
