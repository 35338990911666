import React, { useRef } from "react";

import { useLocation } from 'react-router-dom'
import { MenuContent } from "./content/CommonContent"

import { SvgContent } from "./content/SvgContent"


import "./../assets/scss/Menu.scss";

export const Menu = React.forwardRef((props, ref) => {
  const [pageName, setPageName] = React.useState("");
  const [show, setShow] = React.useState(true);
  const [lastScrollY, setLastScrollY] = React.useState(0);


  let menuContainer = useRef(null);
  const location = useLocation();

  const controlNavbar = () => {
    if(lastScrollY <= 5){
      setShow(true);
    }
    else if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
      setShow(false);
    } else { // if scroll up show the navbar
      setShow(true);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    // cleanup function
    return () => {
       window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  React.useEffect(() => {
    if (location.pathname === "/") {
      setPageName("");
    }
    else if (location.pathname === "/group") {
      if(props.lang === 'ita') setPageName(" - Il Gruppo");
      else setPageName(" - The Group");
    }
    else if (location.pathname === "/pharma") {
      setPageName(" - Zambon Pharma");
    }
    else if (location.pathname === "/zach") {
      setPageName(" - Zach");
    }
    else if (location.pathname === "/openzone") {
      setPageName(" - OpenZone");
    }
    else if (location.pathname === "/zcube") {
      setPageName(" - Zcube");
    }
    else if (location.pathname === "/italiassistenza") {
      if(props.lang === 'ita') setPageName(" - ItaliAssistenza e Careapt");
      else setPageName(" - ItaliAssistenza and Careapt");

    }
    else if (location.pathname === "/biotech") {
      setPageName(" - Zambon Biotech");
    }
    else if (location.pathname === "/zoe" && props.lang === 'ita') {
      setPageName(" - Fondazione Zoé");
    }
    else if (location.pathname === "/zoe" && props.lang === 'eng') {
      setPageName(" - Zoé Foundation");
    }
  },[location, props.lang])



  return (
    <div ref={ref} id="menu" className={`menu ${props.menusticky ? "sticky" : ""} ${props.page} ${props.menuopen ? "open" : ""} ${show? 'show' : 'hidden'}`}>
      <div className="horizontal-menu">

      {/*
        <div className="logo">
          {props.home && (
            <SvgContent stringToRet="zambon" />
          )}
          {!props.home && (
            <a href="/">
              <SvgContent stringToRet="zambon" />
            </a>
          )}
        </div>
       */}
        <button className="backBlock" onClick={(e) => props.onClickMenu(e,'home')}>


          <h3 className="edition">
            {((location.pathname !== "/") && <SvgContent stringToRet="down" />)}
            <MenuContent stringToRet="edition" /> {pageName}
          </h3>
        </button>

        <div className="buttons">
         <div className="lang">
           <span className="label">ITA</span>
           <label className="switch">
             <input type="checkbox" defaultChecked={props.lang === 'ita' ? false : true} onChange={props.onChangeLang} />
             <span className="slider round"></span>
           </label>
           <span className="label">ENG</span>
          </div>
          <button className="btn burger" onClick={props.onToggleMenu}>
            <svg className="burgersvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74" width="60">
              <rect className="cls-1" width="74" height="74" x1="18.87" y1="30.25"/>
              <line className="cls-2" x1="18.87" y1="30.25" x2="56.87" y2="30.25"/>
              <line className="cls-3" x1="18.87" y1="38.04" x2="56.87" y2="38.04"/>
              <line className="cls-4" x1="18.87" y1="45.84" x2="56.87" y2="45.84"/>
            </svg>
          </button>
        </div>
      </div>



      <div ref={menuContainer} className="menuContainer">
        <div className="menuContainerInside">
          <div className="lang">
           <span className="label">ITA</span>
           <label className="switch">
             <input type="checkbox" defaultChecked={props.lang === 'ita' ? false : true} onChange={props.onChangeLang} />
             <span className="slider round"></span>
           </label>
           <span className="label">ENG</span>
          </div>
          <ul>
            <li className="menu_idea">
              <button className="btn" onClick={(e) => props.onClickMenu(e,'group')}><MenuContent stringToRet="group" lang={props.lang} /></button>
            </li>
            <li className="menu_campus">
            <button className="btn" onClick={(e) => props.onClickMenu(e,'pharma')}><MenuContent stringToRet="pharma" lang={props.lang} /></button>
            </li>
            <li className="menu_zoner">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'biotech')}><MenuContent stringToRet="biotech" lang={props.lang} /></button>
            </li>
            <li className="menu_talk">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'zach')}><MenuContent stringToRet="zach" lang={props.lang} /></button>
            </li>
            <li className="menu_projects">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'openzone')}><MenuContent stringToRet="openzone" lang={props.lang} /></button>
            </li>
            <li className="menu_zoner">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'zcube')}><MenuContent stringToRet="zcube" lang={props.lang} /></button>
            </li>
            <li className="menu_zoner">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'italiassistenza')}><MenuContent stringToRet="italiassistenza" lang={props.lang} /></button>
            </li>
            <li className="menu_zoner">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'zoe')}><MenuContent stringToRet="zoe" lang={props.lang} /></button>
            </li>
          </ul>

          <div className="social">
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/zambon1906/"><SvgContent stringToRet="instagram" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/zambon"><SvgContent stringToRet="linkedin" /></a>
          </div>
        </div>
      </div>
    </div>
  );
});
