import React from 'react';

import { YoutubeEmbed } from "./Utility"

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"

import { OpenzoneContent } from "./content/OpenzoneContent"
import { LogosContent } from "./content/LogosContent"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import mySvg from '../assets/svg/bg.svg';

import torri1 from '../assets/images/torri/torri-1.png';
import torri2 from '../assets/images/torri/torri-2.png';
import torri3 from '../assets/images/torri/torri-3.png';
import torri4 from '../assets/images/torri/torri-4.png';

import ocafe1 from '../assets/images/opencafe/opencafe-1.png';
import ocafe2 from '../assets/images/opencafe/opencafe-2.png';
import ocafe3 from '../assets/images/opencafe/opencafe-3.png';
import ocafe4 from '../assets/images/opencafe/opencafe-4.png';

import avis1 from '../assets/images/avis/avis-1.png';
import avis2 from '../assets/images/avis/avis-2.png';

import zoner from '../assets/images/zoner.png';

const Openzone = (props) => {

   return (
      <div className="internalPage openzonePage">

         <div className="ball" id="ball1"></div>
         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball4"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>
         <div className="ball" id="ball7"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
         <SvgContent stringToRet="tube" />

         <div className="pagerow pagerow_head">
            <div className="row">
               <div className="col-6">
                  <div className="plat">
                     <div className="ball"></div>
                     <SvgContent stringToRet="obj5" />
                     <SvgContent stringToRet="piattaforma" />
                  </div>
               </div>
               <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                     <div className="arrow">
                        <SvgContent stringToRet="arrow" />
                     </div>
                     <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                     <div className="logo-wrapper">
                        <LogosContent stringToRet="oz" />
                     </div>
                     <h4 className="d-none d-md-block"><OpenzoneContent lang={props.lang} stringToRet="creiamoValore" /></h4>
                  </div>
               </div>
            </div>
         </div>



         <div className="pagerow d-md-none">
            <div className="row">
               <h4 className="tac"><OpenzoneContent lang={props.lang} stringToRet="creiamoValore" /></h4>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="cuo" />

            <div className="row jcc">
               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon1" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText1" />
                  </div>
               </div>
               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon2" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText2" />
                  </div>
               </div>
               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon3" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText3" />
                  </div>
               </div>
               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon4" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText4" />
                  </div>
               </div>

               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon5" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText5" />
                  </div>
               </div>
               <div className="col-6 col-sm-4 col-md-3">
                  <div className="icon_num2">
                     <div>
                        <SvgContent stringToRet="ozIcon6" />
                     </div>
                     <OpenzoneContent lang={props.lang} stringToRet="openIconText6" />
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow pagerow_lettera">
            <SvgContent stringToRet="doc" />
            <div className="row">
               <div className="d-none d-md-block col-md-2"></div>
               <div className="col-12 col-md-8 col-lettertextnf">
                  <div className="quoteblock">
                     <p className="quote"><OpenzoneContent lang={props.lang} stringToRet="openzoneNasce" /></p>
                     <p className="quoteAuthor"><OpenzoneContent lang={props.lang} stringToRet="andrea" /></p>
                     <p className="quoteRole"><OpenzoneContent lang={props.lang} stringToRet="presidente" /></p>
                     <p className="quoteAuthor mt-3"><OpenzoneContent lang={props.lang} stringToRet="alessio" /></p>
                     <p className="quoteRole"><OpenzoneContent lang={props.lang} stringToRet="generalManager" /></p>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="pins" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="leTorri" /></h2>
               </div>
               <div className="d-none d-lg-block col-1"></div>
               <div className="col-12 col-lg-10 col-benvPeopleContent">
                  <p className="bgrounded"><OpenzoneContent lang={props.lang} stringToRet="torriTesto" /></p>

                  <button className="cta ctaEye" onClick={(e) => props.onToggleExtra(e,'torri')}>
                  <SvgContent stringToRet="eye" />
                  <div className="content">
                  <span><CommonContent lang={props.lang} stringToRet="scopri" /></span>
                  <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                  </div>
                  </button>

               </div>
            </div>

            <div className="row mt-3">

            <div className="d-none d-sm-block col-sm-1 col-md-2 col-lg-3"></div>

            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
               <Swiper
                  pagination={true}
                  autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}>

                  <SwiperSlide><img src={torri1} alt="Torri OpenZone 1" /></SwiperSlide>
                  <SwiperSlide><img src={torri2} alt="Torri OpenZone 2" /></SwiperSlide>
                  <SwiperSlide><img src={torri3} alt="Torri OpenZone 3" /></SwiperSlide>
                  <SwiperSlide><img src={torri4} alt="Torri OpenZone 4" /></SwiperSlide>

               </Swiper>
               <p className="credits text-end mt-1"><OpenzoneContent lang={props.lang} stringToRet="creditsFoto" /></p>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="lente" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="openCafe" /></h2>
               </div>
               <div className="d-none d-md-block col-md-2"></div>


               <div className="col-12 col-md-8 col-benvPeopleContent">
                  <p className="bgrounded bgralone"><OpenzoneContent lang={props.lang} stringToRet="openCafeTesto" /></p>
               </div>
            </div>

            <div className="row mt-3">
               <div className="d-none d-sm-block col-sm-1 col-md-2 col-lg-3"></div>

               <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                  <Swiper
                     pagination={true}
                     autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                     }}
                     loop={true}
                     modules={[Autoplay, Navigation, Pagination]}
                     className="internaSwiper"
                     slidesPerView={1}>
                     <SwiperSlide><img src={ocafe1} alt="Caffé OpenZone 1" /></SwiperSlide>
                     <SwiperSlide><img src={ocafe2} alt="Caffé OpenZone 2" /></SwiperSlide>
                     <SwiperSlide><img src={ocafe3} alt="Caffé OpenZone 3" /></SwiperSlide>
                     <SwiperSlide><img src={ocafe4} alt="Caffé OpenZone 4" /></SwiperSlide>
                  </Swiper>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="dnaw" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="zoner" /></h2>
               </div>
               <div className="d-none d-md-block col-md-1"></div>

               <div className="col-12 col-md-10 text-center">
                  <img src={zoner} alt="Zoner" />
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="nuv" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="certificazioneBcorp" /></h2>
               </div>
               <div className="d-none d-md-block col-lg-1"></div>

               <div className="col-12 col-lg-10 col-benvPeopleContent">
                  <p className="bgrounded"><OpenzoneContent lang={props.lang} stringToRet="certificazioneBcorpTesto" /></p>
                  <a className="cta ctaEye" href="https://www.openzone.it/assets/2024_OPENZONE_ImpactReport_light.pdf" target="_blank" rel="noreferrer">
                     <SvgContent stringToRet="eye" />
                     <div className="content">
                        <span><OpenzoneContent lang={props.lang} stringToRet="leggiRelazione" /></span>
                        <div className="arrow"><SvgContent stringToRet="arrow" /></div>
                     </div>
                  </a>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="pro" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="community" /></h2>
               </div>
               <div className="d-none d-md-block col-2"></div>
               <div className="col-12 col-md-8 col-benvPeopleContent">
                  <p className="bgrounded bgralone"><OpenzoneContent lang={props.lang} stringToRet="communityTesto" /></p>
               </div>
            </div>

            <div className="row mt-5">
               <div className="col-12 col-md-7">
                  <h3><OpenzoneContent lang={props.lang} stringToRet="progettoMyVoice" /></h3>
               </div>
            </div>

            <div className="row">
               <div className="col-12 col-md-7 col-benvPeopleContent">
                  <p className="bgrounded bgralone"><OpenzoneContent lang={props.lang} stringToRet="progettoMyVoiceTesto" /></p>
               </div>
            </div>

            <div className="row mt-3">
              <div className="d-none d-sm-block col-sm-1 col-md-2 col-lg-3"></div>

               <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                  <div className="video">
                     {(props.video === '7xtvD1wR4Z4') && (
                        <YoutubeEmbed embedId="7xtvD1wR4Z4" />
                     )}
                     {(props.video !== '7xtvD1wR4Z4') && (
                        <>
                           <img src={require('../assets/images/video/openzone.png')} alt="Io sono" />
                           <div className="over">
                              <button className="cta play2" onClick={(e) => props.onClickVideo(e,'7xtvD1wR4Z4')} >
                                 <SvgContent stringToRet="play" />
                              </button>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="dna" />

            <div className="row">
               <div className="col-12">
                  <h2><OpenzoneContent lang={props.lang} stringToRet="donazioneSangue" /></h2>
               </div>
               <div className="d-none d-md-block col-2"></div>
               <div className="col-12 col-md-8 col-benvPeopleContent">
                  <p className="bgrounded bgralone"><OpenzoneContent lang={props.lang} stringToRet="donazioneSangueTesto" /></p>
               </div>
            </div>

            <div className="row mt-4">
               <div className="d-none d-sm-block col-sm-1 col-md-2 col-lg-3"></div>

               <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                  <Swiper
                     pagination={true}
                     autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                     }}
                     loop={true}
                     modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}>
                     <SwiperSlide><img src={avis1} alt="Avis OpenZone 1" /></SwiperSlide>
                     <SwiperSlide><img src={avis2} alt="Avis OpenZone 2" /></SwiperSlide>
                  </Swiper>
               </div>
            </div>
         </div>
      </div>
   );
}
export default Openzone;
