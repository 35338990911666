import React from 'react'

export const MenuContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'group' && lang === 'eng') && (
        <>The Group</>
      )}
      {(string === 'group' && lang === 'ita') && (
        <>Il Gruppo</>
      )}
      {(string === 'pharma') && (
        <>Zambon Pharma</>
      )}
      {(string === 'biotech') && (
        <>Zambon Biotech</>
      )}
      {(string === 'zach') && (
        <>Zach</>
      )}
      {(string === 'openzone') && (
        <>Openzone</>
      )}
      {(string === 'zcube') && (
        <>Zcube</>
      )}
      {(string === 'italiassistenza') && (
        <>ItaliAssistenza<br />Careapt</>
      )}

      {(string === 'zoe' && lang === 'ita') && (
        <>Fondazione Zoé</>
      )}
      {(string === 'zoe' && lang === 'eng') && (
        <>Zoé Foundation</>
      )}


      {(string === 'edition') && (
        <>VALUE REPORT 2023</>
      )}
      {(string === 'ita') && (
        <>ITA</>
      )}
      {(string === 'eng') && (
        <>ENG</>
      )}

    </>
  )
}

export const CommonContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
       {(string === 'torna' && lang === 'ita') && (
        <>Torna</>
       )}
      {(string === 'scopri' && lang === 'ita') && (
        <>Scopri di più</>
      )}
      {(string === 'scopriTutte' && lang === 'ita') && (
        <>Scopri tutte</>
      )}
      {(string === 'leggi' && lang === 'ita') && (
        <>Leggi tutto</>
      )}
      {(string === 'visita' && lang === 'ita') && (
        <>Visita il sito</>
      )}

      {(string === 'totaleFatturato' && lang === 'ita') && (
        <>Totale fatturato</>
      )}
      {(string === 'ebitda' && lang === 'ita') && (
        <>Ebitda</>
      )}
      {(string === 'utileNetto' && lang === 'ita') && (
        <>Utile netto</>
      )}

      {(string === 'mioeuro') && (
        <span className="wsnw">Mio €</span>
      )}

      {(string === 'elenaZambon') && (
        <>Elena Zambon</>
      )}



      {(string === 'totalePersone' && lang === 'ita') && (
        <>Totale persone</>
      )}




      {(string === 'torna' && lang === 'eng') && (
      <>Back</>
      )}

      {(string === 'scopri' && lang === 'eng') && (
        <>Learn more</>
      )}
      {(string === 'scopriTutte' && lang === 'eng') && (
        <>Discover all</>
      )}
      {(string === 'leggi' && lang === 'eng') && (
        <>Read all</>
      )}
      {(string === 'visita' && lang === 'eng') && (
        <>Visit the website</>
      )}
      {(string === 'totaleFatturato' && lang === 'eng') && (
        <>Total revenues</>
      )}
      {(string === 'totalePersone' && lang === 'eng') && (
        <>Totale persone</>
      )}
      {(string === 'ebitda' && lang === 'eng') && (
        <>Ebitda</>
      )}
      {(string === 'utileNetto' && lang === 'eng') && (
        <>Net profits</>
      )}
      {(string === 'caricaZambon' && lang === 'eng') && (
        <>Zambon SpA President</>
      )}




    </>
  )
}
