import React from 'react';

import { SvgContent } from "./content/SvgContent"
import { CommonContent } from "./content/CommonContent"
import { LogosContent } from "./content/LogosContent"

import { BiotechContent } from "./content/BiotechContent"

import mySvg from '../assets/svg/bg.svg';

const Biotech = (props) => {
   return (
      <div className="internalPage biotechPage">

         <div className="ball" id="ball2"></div>
         <div className="ball" id="ball3"></div>
         <div className="ball" id="ball5"></div>
         <div className="ball" id="ball6"></div>

         <div className="bgRep" style={{ backgroundImage: `url(${mySvg})` }}></div>
         <SvgContent stringToRet="tube" />

         <div className="pagerow pagerow_head">
            <div className="row">
               <div className="col-6">
                  <div className="plat">
                     <div className="ball"></div>
                     <SvgContent stringToRet="obj3" />
                     <SvgContent stringToRet="piattaforma" />
                  </div>
               </div>
               <div className="col-6">
                  <button className="cta ctaBack" onClick={(e) => props.onClickMenu(e,'home')}>
                     <div className="arrow">
                        <SvgContent stringToRet="arrow" />
                     </div>
                     <span><CommonContent lang={props.lang} stringToRet="torna" /></span>
                  </button>
                  <div className="title">
                     <div className="logo-wrapper">
                        <LogosContent stringToRet="biotech" />
                     </div>
                     <h4 className="d-none d-md-block"><BiotechContent lang={props.lang} stringToRet="mission" /></h4>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow d-md-none">
            <div className="row">
               <h4><BiotechContent lang={props.lang} stringToRet="mission" /></h4>
            </div>
         </div>

         <div className="pagerow pagerow_lettera">
            <SvgContent stringToRet="dna" />
            <div className="row">
               <div className="d-none d-md-block col-md-2 col-lg-3"></div>
               <div className="col-12 col-md-8 col-lg-6 col-lettertext">
                  <div className="quoteblock">
                     <p className="quote">“<BiotechContent lang={props.lang} stringToRet="zambonBiotechVuoleDiventare" />”</p>
                     <p className="quoteAuthor"><BiotechContent lang={props.lang} stringToRet="elenaZambon" /></p>
                  </div>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="nuv" />
            <div className="row">
               <div className="col-12 col-md-9 col-lg-7">
                  <h3><BiotechContent lang={props.lang} stringToRet="productInnovation" /></h3>
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-md-9 col-lg-7 col-benvPeopleContent">
                  <p className="bgrounded"><BiotechContent lang={props.lang} stringToRet="rizuloFilmOrale" /></p>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="pins" />

            <div className="row">
               <div className="d-none d-md-block col-md-2"></div>
               <div className="col-12 col-md-9">
                  <h3><BiotechContent lang={props.lang} stringToRet="valutazioneNuoviProdotti" /></h3>
               </div>
               <div className="d-none d-md-block col-md-2"></div>
               <div className="col-12 col-md-9 col-benvPeopleContent">
                  <p className="bgrounded"><BiotechContent lang={props.lang} stringToRet="veroCentroEccellenza" /></p>
               </div>
            </div>
         </div>

         <div className="pagerow">
            <SvgContent stringToRet="cuo" />

            <div className="row">
               <div className="d-none d-md-block col-md-4"></div>
               <div className="col-12 col-md-8">
                  <h3><BiotechContent lang={props.lang} stringToRet="coinvolgimentoEsperti" /></h3>
               </div>
               <div className="d-none d-md-block col-md-4"></div>
               <div className="col-12 col-md-8 col-benvPeopleContent">
                  <p className="bgrounded"><BiotechContent lang={props.lang} stringToRet="centroDiEccellenza" /></p>
               </div>
            </div>
         </div>
      </div>
   );
}
export default Biotech;
