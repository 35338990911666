import React from "react";
import SimpleBarReact from 'simplebar-react';
import { YoutubeEmbed } from "./Utility"

import { ExtraContent } from "./content/ExtraContent"
import { SvgContent } from "./content/SvgContent"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import "./../assets/scss/Extra.scss";
import 'simplebar/dist/simplebar.min.css';


// import ibomappa from '../assets/images/Ibo-pharma-mappa.png';

import benIcon1 from '../assets/images/benvivere/act1.png';
import benIcon2 from '../assets/images/benvivere/act2.png';
import benIcon3 from '../assets/images/benvivere/act3.png';
import benIcon4 from '../assets/images/benvivere/act4.png';
import benIcon5 from '../assets/images/benvivere/act5.png';

import benvivere1 from '../assets/images/benvivere/benvivere1.png';
import benvivere2 from '../assets/images/benvivere/benvivere2.png';
import benvivere3 from '../assets/images/benvivere/benvivere3.png';
import benvivere4 from '../assets/images/benvivere/benvivere4.png';

import workplace1 from '../assets/images/workplace/workplace1.png';
import workplace2 from '../assets/images/workplace/workplace2.png';
import workplace3 from '../assets/images/workplace/workplace3.png';
import workplace4 from '../assets/images/workplace/workplace4.png';
import workplace5 from '../assets/images/workplace/workplace5.png';
import workplace6 from '../assets/images/workplace/workplace6.png';
import workplace7 from '../assets/images/workplace/workplace7.png';
import workplace8 from '../assets/images/workplace/workplace8.png';

import pa1 from '../assets/images/gallery/privatassistenza/pa-1.png';
import pa2 from '../assets/images/gallery/privatassistenza/pa-2.png';
import pa3 from '../assets/images/gallery/privatassistenza/pa-3.png';
import pa4 from '../assets/images/gallery/privatassistenza/pa-4.png';
import pa5 from '../assets/images/gallery/privatassistenza/pa-5.png';
import pa6 from '../assets/images/gallery/privatassistenza/pa-6.png';
import pa7 from '../assets/images/gallery/privatassistenza/pa-7.png';
import pa8 from '../assets/images/gallery/privatassistenza/pa-8.png';
import pa9 from '../assets/images/gallery/privatassistenza/pa-9.png';
import pa10 from '../assets/images/gallery/privatassistenza/pa-10.png';

import pa11 from '../assets/images/gallery/privatassistenza/pa-11.png';
import pa12 from '../assets/images/gallery/privatassistenza/pa-12.png';
import pa13 from '../assets/images/gallery/privatassistenza/pa-13.png';
import pa14 from '../assets/images/gallery/privatassistenza/pa-14.png';
import pa15 from '../assets/images/gallery/privatassistenza/pa-15.png';
import pa16 from '../assets/images/gallery/privatassistenza/pa-16.png';
import pa17 from '../assets/images/gallery/privatassistenza/pa-17.png';
import pa18 from '../assets/images/gallery/privatassistenza/pa-18.png';
import pa19 from '../assets/images/gallery/privatassistenza/pa-19.png';
import pa20 from '../assets/images/gallery/privatassistenza/pa-20.png';

export const Extra = (props) => {

  return (
    <div className={`extra ${props.content} ${props.open ? "open" : ""} ${props.page}`}>

      <div className='extraContent'>
        {props.content === 'gogreen' && <Gogreen lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />}
        {props.content === 'people' && <People lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />}
        {props.content === 'benvivere' && <Benvivere lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'goodscience' && <Goodscience lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'pipeline' && <Pipeline lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'ibo' && <Ibo lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />}
        {props.content === 'quotezac' && <Quotezac lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'investimenti' && <Investimenti lang={props.lang} onToggleExtra={props.onToggleExtra} onClickMenu={props.onClickMenu} />}
        {props.content === 'psp' && <Psp lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'ilaria' && <Ilaria lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'elena' && <Elena lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'lucio' && <Lucio lang={props.lang} onToggleExtra={props.onToggleExtra} />}

        {props.content === 'torri' && <Torri lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'zcube' && <Zcube lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'corp' && <Corp lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'openacc' && <Openacc lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'call' && <Call lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'vita' && <Vita lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'italia' && <Italia lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'hac' && <Hac lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'private' && <Private lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} />}
        {props.content === 'careapt' && <Careapt lang={props.lang} onToggleExtra={props.onToggleExtra} />}

        {props.content === 'zoner' && <Zoner lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'thewayweare' && <Thewayweare lang={props.lang} onToggleExtra={props.onToggleExtra} />}
        {props.content === 'businessdev' && <Businessdev lang={props.lang} onToggleExtra={props.onToggleExtra} />}

        {props.content === 'at1' && <At1 lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} onClickMenu={props.onClickMenu} />}
        {props.content === 'at2' && <At2 lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} onClickMenu={props.onClickMenu} />}
        {props.content === 'at3' && <At3 lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} onClickMenu={props.onClickMenu} />}
        {props.content === 'at4' && <At4 lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} onClickMenu={props.onClickMenu} />}
        {props.content === 'at5' && <At5 lang={props.lang} onToggleExtra={props.onToggleExtra} video={props.video} onClickVideo={props.onClickVideo} onClickMenu={props.onClickMenu} />}


      </div>

      <button className="cta close" onClick={props.onClose}>
      <svg className="icsSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.97 34.96"><polygon points="31.97 3.13 29.5 0 15.99 14.83 2.47 0 0 3.13 13.09 17.48 0 31.83 2.47 34.96 15.99 20.13 29.5 34.96 31.97 31.83 18.88 17.48 31.97 3.13" fill="#ffffff"></polygon></svg>
      </button>
    </div>

  );
};

const Gogreen = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }} forceVisible={true} autoHide={false}>
      <div className="row">
      <div className="col-12 col-xl-2">
      </div>
          <div className="col-12 col-xl-8">
            <ExtraContent lang={props.lang} stringToRet="" />
            <h2>Go Green</h2>
            <hr />
          </div>
          <div className="col-12 col-xl-2">
          </div>
          <div className="col-12 col-xl-2">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="goGreenTitle" />


            <div className="sustainabilityBlock mb-4" onClick={(e) => props.onClickMenu(e,'benessere')}>
              <SvgContent stringToRet="sustainability" />
              <div><ExtraContent lang={props.lang} stringToRet="goGreenSust" /></div>
            </div>

            <ExtraContent lang={props.lang} stringToRet="goGreenCol1" />


          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="goGreenCol2" />
          </div>
          <div className="col-12 col-xl-2">
          </div>

          {(props.lang === 'ita') && (
          <>
            <div className="col-12 col-xl-2">
            </div>
            <div className="col-12 col-xl-8">
              <img className="mt-5 acenter" src={require('../assets/images/mobilita.png').default} alt="Go green" />
            </div>
          </>
          )}
        </div>
        </SimpleBarReact>



    </div>
    </>
  )
}
const Ilaria = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>


        <div className="row">
        <div className="col-12 col-xl-2">
        </div>
          <div className="col-12 col-xl-8 tac">
            <ExtraContent lang={props.lang} stringToRet="ilariaTitle" />
            <hr />
          </div>
          <div className="col-12 col-xl-2">
          </div>
          <div className="col-12 col-xl-2">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="ilariaCol1" />
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="ilariaCol2" />
          </div>
          <div className="col-12 col-xl-2">
          </div>

        </div>

      </SimpleBarReact>
    </div>
    </>
  )
}
const Elena = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: 'calc(100%)' }}>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="elenaCol1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="elenaCol2" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}

const Torri = (props) => {
   return (
      <div className="col-12 barWrapper">


         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="torriTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="torriCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const Lucio = (props) => {
   return (
      <div className="col-12 barWrapper">



         <SimpleBarReact style={{ maxHeight: '100%' }}>
         <div className="row">
            <div className="col-12 mt-5"></div>
         </div>
            <div className="row aic jcc">

               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="lucioCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const People = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">


        <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-12">
            <ExtraContent lang={props.lang} stringToRet="peopleTitle" />
            <hr />
          </div>
        </div>

          <div className="row">

          <div className="col-12 col-xl-4 mb-5">
            <ExtraContent lang={props.lang} stringToRet="peopleCol1" />

            <Swiper
              modules={[Autoplay, Pagination]}
              className="internaSwiper"
              slidesPerView={1}
              loop = {true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={true}
            >
              <SwiperSlide><img src={workplace1} alt="Workplace 1" /></SwiperSlide>
              <SwiperSlide><img src={workplace2} alt="Workplace 2" /></SwiperSlide>
              <SwiperSlide><img src={workplace3} alt="Workplace 3" /></SwiperSlide>
              <SwiperSlide><img src={workplace4} alt="Workplace 4" /></SwiperSlide>
              <SwiperSlide><img src={workplace5} alt="Workplace 5" /></SwiperSlide>
              <SwiperSlide><img src={workplace6} alt="Workplace 6" /></SwiperSlide>
              <SwiperSlide><img src={workplace7} alt="Workplace 7" /></SwiperSlide>
              <SwiperSlide><img src={workplace8} alt="Workplace 8" /></SwiperSlide>
            </Swiper>
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="peopleCol2" />
          </div>

          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="peopleCol3" />
          </div>
        </div>


      </SimpleBarReact>
    </div>
    </>
  )
}
const Pipeline = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>


        <div className="row">
          <div className="col-12 col-xl-2">
          </div>
          <div className="col-12 col-xl-8">
            <img src={require('../assets/images/pipeline.png').default} alt="Pipeline" />
          </div>
          <div className="col-12 col-xl-2">
          </div>
        </div>


      </SimpleBarReact>
    </div>
    </>
  )
}
const Quotezac = (props) => {
  return (
    <>
    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-4">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="quotezacCol1" />
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}
const Zcube = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="zcubeTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="zcubeCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const Openacc = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="openTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="openCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const Corp = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="corpTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="corpCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const Call = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="callTitle" />
                  <hr />
               </div>
            </div>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="callCol1" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}
const Vita = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="vitaTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="vitaCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}
const Italia = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="itaTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="itaCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}

const Hac = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="hacTitle" />
                  <hr />
               </div>
            </div>

            <div className="row jcc">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="hacCol1" />
               </div>
            </div>
         </SimpleBarReact>

      </div>
   )
}

const Private = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>

         <div className="row jcc">
            <div className="col-12">
               <ExtraContent lang={props.lang} stringToRet="paTitle" />
               <hr />
            </div>
         </div>

         <div className="row jcc">
            <div className="col-12 col-xl-6">
               <ExtraContent lang={props.lang} stringToRet="paCol1" />

               <Swiper
                  pagination={true}
                  autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Autoplay, Navigation, Pagination]} className="internaSwiper" slidesPerView={1}>

                  <SwiperSlide><img src={pa1} alt="Private Assistenza 2" /></SwiperSlide>
                  <SwiperSlide><img src={pa2} alt="Private Assistenza 2" /></SwiperSlide>
                  <SwiperSlide><img src={pa3} alt="Private Assistenza 3" /></SwiperSlide>
                  <SwiperSlide><img src={pa4} alt="Private Assistenza 4" /></SwiperSlide>
                  <SwiperSlide><img src={pa5} alt="Private Assistenza 5" /></SwiperSlide>

                  <SwiperSlide><img src={pa6} alt="Private Assistenza 6" /></SwiperSlide>
                  <SwiperSlide><img src={pa7} alt="Private Assistenza 7" /></SwiperSlide>
                  <SwiperSlide><img src={pa8} alt="Private Assistenza 8" /></SwiperSlide>
                  <SwiperSlide><img src={pa9} alt="Private Assistenza 9" /></SwiperSlide>
                  <SwiperSlide><img src={pa10} alt="Private Assistenza 10" /></SwiperSlide>

                  <SwiperSlide><img src={pa11} alt="Private Assistenza 11" /></SwiperSlide>
                  <SwiperSlide><img src={pa12} alt="Private Assistenza 12" /></SwiperSlide>
                  <SwiperSlide><img src={pa13} alt="Private Assistenza 13" /></SwiperSlide>
                  <SwiperSlide><img src={pa14} alt="Private Assistenza 14" /></SwiperSlide>
                  <SwiperSlide><img src={pa15} alt="Private Assistenza 15" /></SwiperSlide>

                  <SwiperSlide><img src={pa16} alt="Private Assistenza 16" /></SwiperSlide>
                  <SwiperSlide><img src={pa17} alt="Private Assistenza 17" /></SwiperSlide>
                  <SwiperSlide><img src={pa18} alt="Private Assistenza 18" /></SwiperSlide>
                  <SwiperSlide><img src={pa19} alt="Private Assistenza 19" /></SwiperSlide>
                  <SwiperSlide><img src={pa20} alt="Private Assistenza 20" /></SwiperSlide>

               </Swiper>
               </div>
               <div className="col-12 col-xl-6">
               <div className="video mt-4">
                 {(props.video === 'TWkww26nIxk') && (
                     <YoutubeEmbed embedId="TWkww26nIxk" />
                 )}
                 {(props.video !== 'TWkww26nIxk') && (
                   <>
                     <img src={require('../assets/images/video/privatassistenza.png')} alt="Io sono" />
                     <div className="over">
                       <button className="cta play2" onClick={(e) => props.onClickVideo(e,'TWkww26nIxk')} >
                         <SvgContent stringToRet="play" />
                       </button>
                     </div>
                   </>
                 )}
               </div>
            </div>
         </div>
         </SimpleBarReact>

      </div>
   )
}
const Zoner = (props) => {
  return (
    <>
      <div className="col-12 barWrapper">
        <SimpleBarReact style={{ maxHeight: '100%' }}>
          <div className="row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-6">
              <img src={require('../assets/images/zoner.png').default} alt="Zoner" />
            </div>
          </div>
        </SimpleBarReact>
      </div>
    </>
  )
}
const Businessdev = (props) => {
  return (
    <>
    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-4">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="businessdevTitle" />
            <hr />
          </div>
          <div className="col-12 col-xl-4">
          </div>
          <div className="col-12 col-xl-4">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="businessdevCol1" />
          </div>
          <div className="col-12 col-xl-4">
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}
const Thewayweare = (props) => {
  return (
    <>
    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row jcc">
          <div className="col-12 col-xl-6">
            <ExtraContent lang={props.lang} stringToRet="thewayweareTitle" />
            <hr />
          </div>
        </div>
        <div className="row jcc">
          <div className="col-12 col-xl-6">
            <ExtraContent lang={props.lang} stringToRet="thewayweareCol1" />
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}
const Investimenti = (props) => {
   return (
      <div className="col-12 barWrapper">


         <SimpleBarReact style={{ maxHeight: '100%' }}>

         <div className="row">
            <div className="col-12">
               <ExtraContent lang={props.lang} stringToRet="investimentiTitle" />
               <hr />
            </div>
         </div>

         <div className="row">
            <div className="col-12 col-xl-6">
               <ExtraContent lang={props.lang} stringToRet="investimentiCol1" />
            </div>
            <div className="col-12 col-xl-6">
               <ExtraContent lang={props.lang} stringToRet="investimentiCol2" />
            </div>
         </div>
         </SimpleBarReact>

      </div>
   )
}
const Goodscience = (props) => {
   return (
      <div className="col-12 barWrapper">


         <SimpleBarReact style={{ maxHeight: '100%' }}>

         <div className="row">
            <div className="col-12">
               <ExtraContent lang={props.lang} stringToRet="goodscienceTitle" />
               <hr />
            </div>
         </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="goodscienceCol1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="goodscienceCol2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}
const Ibo = (props) => {
   return (
      <div className="col-12 barWrapper">


         <SimpleBarReact style={{ maxHeight: '100%' }}>

         <div className="row">
            <div className="col-12">
               <ExtraContent lang={props.lang} stringToRet="iboTitle" />
               <hr />
            </div>
         </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="iboCol1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="iboCol2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}
const Benvivere = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">
     <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12">
            <h2>Benvivere</h2>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6 mb-5">
            <ExtraContent lang={props.lang} stringToRet="benvivereCol1" />
            <Swiper
              modules={[Autoplay, Pagination]}
              className="internaSwiper"
              slidesPerView={1}
              loop = {true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={true}
            >

              <SwiperSlide>
                <img src={benvivere1} alt="Benvivere 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={benvivere2} alt="Benvivere 2" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={benvivere3} alt="Benvivere 3" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={benvivere4} alt="Benvivere 4" />
              </SwiperSlide>

            </Swiper>
          </div>

          <div className="col-12 col-xl-6 listSvg">
              <ExtraContent lang={props.lang} stringToRet="benvivereActIntro" />
              <div><img src={benIcon1} alt="Benvivere 1" /><p className="small"><ExtraContent lang={props.lang} stringToRet="benvivereAct1" /></p></div>
              <div><img src={benIcon2} alt="Benvivere 2" /><p className="small"><ExtraContent lang={props.lang} stringToRet="benvivereAct2" /></p></div>
              <div><img src={benIcon3} alt="Benvivere 3" /><p className="small"><ExtraContent lang={props.lang} stringToRet="benvivereAct3" /></p></div>
              <div><img src={benIcon4} alt="Benvivere 4" /><p className="small"><ExtraContent lang={props.lang} stringToRet="benvivereAct4" /></p></div>
              <div><img src={benIcon5} alt="Benvivere 5" /><p className="small"><ExtraContent lang={props.lang} stringToRet="benvivereAct5" /></p></div>
              <ExtraContent lang={props.lang} stringToRet="benvivereCol2" />

          </div>

        </div>

   </SimpleBarReact>
    </div>
    </>
  )
}
const Psp = (props) => {
  return (
    <>

    <div className="col-12 barWrapper">
      <SimpleBarReact style={{ maxHeight: '100%' }}>


        <div className="row">
          <div className="col-12 col-xl-4">
          </div>
          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="pspTitle" />
            <hr />
          </div>

          <div className="col-12 col-xl-4">
          </div>

          <div className="col-12 col-xl-4">
          </div>

          <div className="col-12 col-xl-4">
            <ExtraContent lang={props.lang} stringToRet="pspCol1" />
          </div>

        </div>

      </SimpleBarReact>
    </div>
    </>
  )
}

const Careapt = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12">
                  <ExtraContent lang={props.lang} stringToRet="careaptTitle" />
                  <hr />
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="careaptCol1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="careaptCol2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
   )
}

const At1 = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12">
                  <ExtraContent lang={props.lang} stringToRet="at1Title" />
                  <hr />
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at1Col1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at1Col2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
  )
}
const At2 = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12">
                  <ExtraContent lang={props.lang} stringToRet="at2Title" />
                  <hr />
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at2Col1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at2Col2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
  )
}
const At3 = (props) => {
   return (
      <div className="col-12 barWrapper">
            <SimpleBarReact style={{ maxHeight: '100%' }}>
               <div className="row">
                  <div className="col-12">
                     <ExtraContent lang={props.lang} stringToRet="at3Title" />
                     <hr />
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 col-xl-6">
                     <ExtraContent lang={props.lang} stringToRet="at3Col1" />
                  </div>
                  <div className="col-12 col-xl-6">
                     <ExtraContent lang={props.lang} stringToRet="at3Col2" />
                  </div>
               </div>
            </SimpleBarReact>
      </div>
  )
}

const At4 = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12">
                  <ExtraContent lang={props.lang} stringToRet="at4Title" />
                  <hr />
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at4Col1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at4Col2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
  )
}

const At5 = (props) => {
   return (
      <div className="col-12 barWrapper">
         <SimpleBarReact style={{ maxHeight: '100%' }}>
            <div className="row">
               <div className="col-12">
                  <ExtraContent lang={props.lang} stringToRet="at5Title" />
                  <hr />
               </div>
            </div>
            <div className="row">
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at5Col1" />
               </div>
               <div className="col-12 col-xl-6">
                  <ExtraContent lang={props.lang} stringToRet="at5Col2" />
               </div>
            </div>
         </SimpleBarReact>
      </div>
  )
}
